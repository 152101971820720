import React, { memo } from "react";
import { useSelector } from 'react-redux';

import Verse from '../Verse/Verse';

const ViewerEditor = () => {
	const viewerData = useSelector((state) => state.viewer.data);

	const mobilePanels = useSelector((state) => state.app.viewer.mobilePanels);
	const verses = mobilePanels === 'student' ? viewerData.studentVerses : viewerData.verses;
	
	return (
		<div className='viewer__verses'>
			<ul className={ `viewer__verses--list ${verses && verses.length > 5 ? 'viewer__verses--list-overflow' : ''}` }>
                
				{verses && verses.map(verse => 
					<Verse 
						key={ verse.id } 
						verse={ verse } 
					/>,
				)}
			</ul>
		</div>
	);
};

export default memo(ViewerEditor);