import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";

import Button from "views/elements/Button/Button";

import url from "router/urls";

import styles from './Errors.module.sass';

const Error404 = () => {
	const dict = useSelector((state) => state.app.dict);
	const history = useHistory();
	const location = new URL(window.location.href);

	const prevPage = () => {
		history.goBack();
	};

	return (
		<main>
			<Container>
				<div className='block'>
					<div className='block__header'>
						<h1 className='text--title'>{dict.error.error404.title}</h1>
					</div>
					<div className={ styles["nopage--content"] }>
						<p className='text--heading'>{dict.error.error404.description}</p>
					</div>
					<div className={ styles['nopage--links'] }>
						<p className='nopage--paragraph'>{dict.error.error404.backText}</p>
						<Button
							variant='link'
							type='button'
							onClick={ () => prevPage() }
						>{dict.error.error404.backLinkText}
						</Button>
						<br />
						<p className='nopage--paragraph'>{dict.error.error404.homeText}</p>
						<Button
							variant='link'
							type='button'
							href={ url.home }
						>{dict.error.error404.homeLinkText} {location.hostname}
						</Button>
					</div>
				</div>
			</Container>
		</main>
	);
};

export default Error404;
