import api from "services/api";

const getTranscriptionStatusOCR = (transcriptionId, setTranscriptionStatusOCR,setTranscriptionStatusOCRRequest ) => {
	setTranscriptionStatusOCRRequest(false);
	api.get(`/transcriptions/${transcriptionId}/ocr-status`).then(({data}) => {
		setTranscriptionStatusOCR(data.status);
		setTranscriptionStatusOCRRequest(true);
	}).catch((error) => {
		console.log(error);
	});
};

export default getTranscriptionStatusOCR;