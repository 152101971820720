import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";

import styles from "../TranscriptionStats.module.sass";

const TranscriptionVerificationItem = ({statsData}) => {
	
	const dict = useSelector((state) => state.app.dict);
	
	return (
		<li className={ styles["stats__table--item"] }>
			<div className={ styles["stats__table--item-name"] }>
				<span className={ styles["stats__table--item-label"] }>
					{dict.transcription.stats.userName}
				</span>
				<div className={ styles["stats__table--item-name-container"] }>
					<span className={ styles["stats__table--item-name-email"] }>
						{statsData.email}
					</span>
					<a 
						className={ styles["stats__table--item-name-contact"] }
						href={ `mailto:${statsData.email}` }
					>
						<i className='icon-mail' />
						{dict.transcription.stats.contact}
					</a>
				</div>
			</div>
			<div className={ styles["stats__table--item-default"] }>
				<span className={ styles["stats__table--item-label"] }>
					{dict.transcription.stats.charactersEntered}
				</span>
				{statsData.numberOfInsertedChars}
			</div>
			<div className={ styles["stats__table--item-default"] }>
				<span className={ styles["stats__table--item-label"] }>
					{dict.transcription.stats.transcribedVerses}
				</span>
				{statsData.numberOfChangedVerseStatuses}
			</div>
			<div className={ styles["stats__table--item-default"] }>
				<span className={ styles["stats__table--item-label"] }>
					{dict.transcription.stats.correctlyVerifiedVerses}
				</span>
				{statsData.numberOfVerifiedVerses}
			</div>
		</li>
	);
};

TranscriptionVerificationItem.propTypes = {
	statsData: PropTypes.object,
};

export default TranscriptionVerificationItem;
