import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import { Provider } from "react-redux";

import * as serviceWorker from "serviceWorker";
import { history } from "utils/history";

import store from 'store/store';

import "normalize.css";
import "draft-js/dist/Draft.css";
import main from "styles/main.sass"; // eslint-disable-line

import App from "views/components/App/App";
import ModalWrapper from "views/elements/Modal/ModalWrapper";

ReactDOM.render(

	<Provider store={ store }>
		<Router history={ history }>
			<App />
			<ModalWrapper />
		</Router>
	</Provider>
	,
	document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
