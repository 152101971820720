import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router';
import { reverse } from 'named-urls';

import api from "services/api";
import url from "router/urls";
import { appActions } from "store/actions/app.actions";

import { Container } from 'react-bootstrap';

import Error403 from "views/pages/Error/Error403";
import Alert from "views/elements/Alert/Alert";
import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";
import TranscriptionDetailsPages from "./parts/TranscriptionDetailsPages";
import TranscriptionDetailsMetadata from "./parts/TranscriptionDetailsMetadata";
import TranscriptionDetailsNav from "./parts/TranscriptionDetailsNav";
import TranscriptionDetailsBackLink from "./parts/TranscriptionDetailsBackLink";

import getTranscriptionStatusOCR from 'utils/transcriptionStatusOCR';

import styles from "./TranscriptionDetails.module.sass";

const TranscriptionDetails = () => {
	const dispatch = useDispatch();
	const dict = useSelector((state) => state.app.dict);
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	const { transcriptionId } = useParams();

	const location = useLocation();
	const history = useHistory();

	const [ metadata, setMetadata ] = useState(null);
	const [ currentPageNum, setCurrentPageNum ] = useState(0);
	const [ pages, setPages ] = useState({});
	const [ viewMode, setViewMode ] = useState(null);
	const [ creationMode, setCreationMode ] = useState(null);
	const [ hidden, setHidden ] = useState(false);

	const [ percentOfTranscribed, setPercentOfTranscribed ] = useState(null);
	const [ percentOfVerified, setPercentOfVerified ] = useState(null);
	const [ lastModificationDate, setLastModificationDate ] = useState("");
	const [ creationDate, setCreationDate ] = useState("");
	const [ receiverId, setReceiverId ] = useState(null);
	const [ creatorEmail, setCreatorEmail ] = useState("");

	const [ title, setTitle ] = useState('');
	const [ noAccess, setNoAccess ] = useState(false);
	const [ continuation, setContinuation ] = useState(null);

	const [ transcriptionStatusOCR, setTranscriptionStatusOCR ] = useState('LOADING');
	const [ transcriptionStatusOCRPrevious, setTranscriptionStatusOCRPrevious ] = useState('');
	const [ transcriptionStatusOCRRequest, setTranscriptionStatusOCRRequest ] = useState(false);
	const [ transcriptionStatusOCRDisabledButtons, setTranscriptionStatusOCRDisabledButtons ] = useState(true);
	const [ transcriptionStatusOCRProcessingInfo, setTranscriptionStatusOCRProcessingInfo ] = useState(false);

	const goPage = (pageNum) => {
		const linkToTranscriptionDetails = reverse(url.transcription.details, { transcriptionId: parseInt(transcriptionId) });
		history.push(linkToTranscriptionDetails + '?page=' + pageNum);
	};

	useEffect(() => {
		const pageNum = new URLSearchParams(location.search).get("page");
		if (!pageNum || pageNum < 1) {
			const linkToTranscriptionDetails = reverse(url.transcription.details, { transcriptionId: parseInt(transcriptionId) });
			history.replace(linkToTranscriptionDetails + '?page=1');
		} else {
			setCurrentPageNum(parseInt(pageNum));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ location ]);

	useEffect(() => {
		if (currentPageNum === 0) return;
		dispatch(appActions.setLoading(true));
		api.get(`/transcriptions/${transcriptionId}`, { params: { page: currentPageNum } })
			.then((response) => {
				dispatch(appActions.setLoading(false));
				if (currentPageNum > response.data.pages.pages) {
					goPage(response.data.pages.pages);
				}
				setTitle(response.data.titleLabel);
				setPages(response.data.pages);
				setViewMode(response.data.viewMode.toLowerCase());
				setCreationMode(response.data.creationMode.toLowerCase());
				setMetadata(response.data);
				setPercentOfTranscribed(response.data.percentOfTranscribed);
				setPercentOfVerified(response.data.percentOfVerified);
				setLastModificationDate(formatDate(response.data.lastModificationDate));
				setCreationDate(formatDate(response.data.dateCreated));
				setContinuation(response.data.continuation);
				setReceiverId(response.data.creator.id);
				setCreatorEmail(response.data.creator.email);
				setHidden(response.data.hidden);
			}).catch(() => {
				setNoAccess(true);
			});
	}, [ currentPageNum, dispatch, isLoggedIn ]);

	useEffect(() => {
		setNoAccess(hidden && !isLoggedIn);
	}, [ isLoggedIn ]);

	useEffect(() => {
		getTranscriptionStatusOCR(transcriptionId, setTranscriptionStatusOCR, setTranscriptionStatusOCRRequest);
	}, []);

	useEffect(() => {	
		
		let mounted = true;

		if (transcriptionStatusOCRRequest) {
			switch(transcriptionStatusOCR) {
				case "PROCESSING":
					setTranscriptionStatusOCRDisabledButtons(true);
					setTranscriptionStatusOCRProcessingInfo(true);
	
					setTimeout(() => { 
						if (mounted) getTranscriptionStatusOCR(transcriptionId, setTranscriptionStatusOCR, setTranscriptionStatusOCRRequest);
					}, 5 * 1000);

					break;
	
				default:
					setTranscriptionStatusOCRDisabledButtons(false);
					setTranscriptionStatusOCRProcessingInfo(false);
					break;
			}

		}

		return () => {
			mounted = false;
		};

	}, [ transcriptionStatusOCRRequest ]);

	useEffect(() => {	
		if (transcriptionStatusOCRPrevious === 'PROCESSING' && transcriptionStatusOCR !== 'PROCESSING') {
			dispatch(appActions.setLoading(true));
			api.get(`/transcriptions/${transcriptionId}`, { params: { page: currentPageNum } })
				.then((response) => {
					dispatch(appActions.setLoading(false));
					setPages(response.data.pages);
					setPercentOfTranscribed(response.data.percentOfTranscribed);
					setPercentOfVerified(response.data.percentOfVerified);
				}).catch(() => {
					setNoAccess(true);
				});
		}

		setTranscriptionStatusOCRPrevious(transcriptionStatusOCR);
		
	}, [ transcriptionStatusOCR ]);

	const formatDate = (date) => date.split(" ")[0].split("-").reverse().join('.');

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: dict.breadcrumbs.transcriptions, link: url.transcriptions },
		{ id: 3, label: title },
	];

	return (!noAccess ? (
		<main>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<TranscriptionDetailsBackLink />
				<Alert className={ styles["transcription__alert--margin-bottom"] } />
				<TranscriptionDetailsNav
					viewMode={ viewMode }
					creationMode={ creationMode }
					creationDate={ creationDate }
					transcriptionId={ parseInt(transcriptionId) }
					continuation={ continuation }
					receiverId={ parseInt(receiverId) }
					creatorEmail={ creatorEmail }
				/>
				<TranscriptionDetailsMetadata
					metadata={ metadata }
					viewMode={ viewMode }
				/>
			</Container>
			<TranscriptionDetailsPages
				percentOfTranscribed={ percentOfTranscribed }
				percentOfVerified={ percentOfVerified }
				lastModificationDate={ lastModificationDate }
				pages={ pages }
				onGoToPage={ goPage }
				viewMode={ viewMode }
				creationMode={ creationMode }
				transcriptionId={ parseInt(transcriptionId) }
				transcriptionStatusOCRDisabledButtons={ transcriptionStatusOCRDisabledButtons }
				transcriptionStatusOCRProcessingInfo={ transcriptionStatusOCRProcessingInfo }
			/>

		</main>
	) : (
		<Error403 />
	));
};

export default TranscriptionDetails;
