import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from "prop-types";
import { useShowModal } from 'views/elements/Modal/ModalHooks';

import api from "services/api";
import { appActions } from 'store/actions/app.actions';
import Button from "views/elements/Button/Button";

import styles from "../TranscriptionPermissions.module.sass";

const TranscriptionPermissionsGroups = ({ groups, getTranscriptionPermissionsData }) => {

	const dict = useSelector((state) => state.app.dict);
	const dispatch = useDispatch();
	const modalConfirmInfo = useSelector((state) => state.app.modalConfirmInfo);
	const { transcriptionId } = useParams();
	const wrapperRef = useRef(null);

	const [ display, setDisplay ] = useState(false);
	const [ allowedGroups, setAllowedGropus ] = useState([]);
	const [ namesOfGroups, setNamesOfGroups ] = useState([]);
	const [ groupToAdd, setGroupToAdd ] = useState({});
	const [ inputValue, setInputValue ] = useState('');
	const [ groupId, setGroupId ] = useState('');

	useEffect(() => {
		setGroupNameArray();
		window.addEventListener("mousedown", handleClickOutside);
		return () => {
			window.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (modalConfirmInfo === "GROUP_DELETE_CONFIRMED") {
			api.delete(`/transcriptions/${transcriptionId}/allowed-groups/${groupId}`)
				.then(() => {
					getTranscriptionPermissionsData();
				});
		}
		dispatch(appActions.setModalConfirmInfo(null));
	}, [ modalConfirmInfo ]);

	const modalDeleteGroup = useShowModal({
		title: dict.modals.permissionsDeleteGroup.title,
		name: 'deleteGroup',
		componentPath: './parts/ModalDeleteGroup',
	});

	const onAddGroupSubmit = () => {
		api.post(`/transcriptions/${transcriptionId}/allowed-groups`, { groupId: groupToAdd.id })
			.then(() => {
				getTranscriptionPermissionsData();
			});
		setInputValue('');
		setNamesOfGroups(namesGroups => [ ...namesGroups, groupToAdd.name ]);
	};

	const onWrite = (path) => {
		setInputValue(path);
		path.length > 0 ? (
			api.get(`/groups/my-groups/${path}`)
				.then(({ data }) => {
					setAllowedGropus(data);
					(data.length > 0) ? setDisplay(true) : setDisplay(false);
				})
		)
			: setDisplay(false);
	};

	const updateInput = (value) => {
		setDisplay(false);
		setGroupToAdd(value);
		setInputValue(value.name);
	};

	const onDelete = (id, name) => {
		setGroupId(id);
		modalDeleteGroup();
		setNamesOfGroups(namesGroups => namesGroups.filter(item => item !== name));
	};

	const setGroupNameArray = () => {
		if (groups) {
			groups.map((item) => (
				setNamesOfGroups(namesGroups => [ ...namesGroups, item.group.name ])
			));
		}
	};

	const handleClickOutside = event => {
		const { current: wrap } = wrapperRef;
		if (wrap && !wrap.contains(event.target)) {
			setDisplay(false);
		}
	};

	return (
		<div className={ styles["permissions__group--container"] }>
			<h3 className={ styles["permissions__additional-text"] }>{dict.transcription.permissions.groups}</h3>
			<div className={ styles["permissions__group"] }>
				{groups && (
					groups.map((item, index) => (
						<div key={ item.group.id }>
							{index + 1}. {item.group.name}
							<span className={ styles["permissions__group-creator"] }> ({dict.auth.account.groupCreator}: {item.group.creator.email})</span>
							<Button
								variant='link'
								onClick={ () => {
									onDelete(item.group.id, item.group.name);
								} }
							>
								{dict.transcription.permissions.deleteUser}
							</Button>
						</div>
					))
				)}
			</div>
			<h3 className={ styles["permissions__additional-text"] }>{dict.transcription.permissions.addGroupTitle}</h3>
			<p className='text--small'>{dict.transcription.permissions.addGroupName}</p>
			<div className={ styles["permissions__add--container"] }>
				<form
					className={ styles["permissions__add--form"] }
				>
					<div
						className={ styles["permissions__add--wrapper"] }
						ref={ wrapperRef }
					>
						<input
							type='text'
							name='groupName'
							placeholder={ dict.transcription.permissions.groupPlaceholder }
							dict={ dict }
							className={ `form-control 
								${styles["permissions__add--form-input"]}
							` }
							onChange={ (e) => onWrite(e.target.value) }
							onClick={ (e) => onWrite(e.target.value) }
							value={ inputValue }
							autoComplete='off'
						/>
						{(display && allowedGroups.length !== namesOfGroups.length) && (
							<div className={ styles["permissions__dropdown-autocomplete"] }>
								{allowedGroups
									.map((value) => {
										return (
											<>{!namesOfGroups.includes(value.name) &&
												<div
													onClick={ () => updateInput(value) }
													key={ value.id }
													className={ styles["permissions__dropdown-autocomplete--item"] }
												>
													{value.name} / {value.creator.email}
												</div>}
											</>
										);
									})}
							</div>
						)}
					</div>
					<Button
						variant='submit'
						className={ styles["permissions__add--form-submit"] }
						label={ dict.group.addButton }
						disabled={ inputValue !== groupToAdd.name }
						onClick={ () => onAddGroupSubmit() }
					>
						{dict.group.addButton}
					</Button>

				</form>
			</div>
		</div>
	);
};

TranscriptionPermissionsGroups.propTypes = {
	groups: PropTypes.array,
	getTranscriptionPermissionsData: PropTypes.func,
};

export default TranscriptionPermissionsGroups;
