import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import api from "services/api";
import url from "router/urls";
import { reverse } from 'named-urls';
import { appActions } from "store/actions/app.actions";
import { useShowModal } from 'views/elements/Modal/ModalHooks';
import { useHistory } from "react-router-dom";

import { Container, Dropdown } from 'react-bootstrap';

import Button from "views/elements/Button/Button";
import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";

import TranscriptionPermissionsUsers from "./parts/TranscriptionPermissionsUsers";
import TranscriptionPermissionsGroups from "./parts/TranscriptionPermissionsGroups";
import TranscriptionPermissionsStatus from "./parts/TranscriptionPermissionsStatus";

import styles from "./TranscriptionPermissions.module.sass";

const TranscriptionPermissions = () => {

	const dispatch = useDispatch();
	const dict = useSelector((state) => state.app.dict);
	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const isLoggingEnd = useSelector((state) => state.auth.credentials.isLoggingEnd);
	const { transcriptionId } = useParams();
	const history = useHistory();

	const [ title, setTitle ] = useState('');
	const [ allowedGroups, setAllowedGroups ] = useState();
	const [ allowedUsers, setAllowedUsers ] = useState();
	const [ dataLoaded, setDataLoaded ] = useState(false);
	const [ continuation, setContinuation ] = useState(null);
	const [ publicAvailable, setPublicAvailable ] = useState(true);

	const modalDeleteTranscription = useShowModal({
		title: dict.modals.deleteTranscription.title,
		name: 'deleteTranscription',
		componentPath: './parts/ModalDeleteTranscription',
		componentProps: { transcriptionId: parseInt(transcriptionId) },
	});

	const onContinueTranscription = () => {
		if (continuation.lastPageId) {
			const linkToTranscriptionView = reverse(url.transcription.viewer, { transcriptionId: parseInt(transcriptionId), pageId: parseInt(continuation.lastPageId) });
			history.push(linkToTranscriptionView);
		}
	};

	const continueButton = (continuation && (
		<Button
			variant='primary'
			className={ styles['transcription__mobileNav--additional-button'] }
			onClick={ () => onContinueTranscription() }
			disabled={ !(continuation.lastPageId) }
		>
			{(continuation.state === "not-started") && dict.transcription.details.start}
			{(continuation.state === "in-progress") && dict.transcription.details.continue}
			{(continuation.state === "finished") && dict.transcription.details.transcriptionComplete}
		</Button>
	));

	const linkToTranscriptionDetails = reverse(url.transcription.details, { transcriptionId: transcriptionId });

	useEffect(() => {
		if (isLoggedIn && isLoggingEnd) {
			getTranscriptionPermissionsData();
		} else if (!isLoggedIn && isLoggingEnd) {
			history.push(linkToTranscriptionDetails);
		}
	}, [ isLoggedIn, isLoggingEnd ]);

	const getTranscriptionPermissionsData = () => {
		dispatch(appActions.setLoading(true));
		api.get(`/transcriptions/${transcriptionId}/permissions`)
			.then(({ data }) => {
				dispatch(appActions.setLoading(false));
				setTitle(data.transcription.titleLabel);
				setAllowedGroups(data.allowedGroups);
				setAllowedUsers(data.allowedUsers);
				setContinuation(data.transcriptionContinuation);
				setPublicAvailable(data.publiclyAvailable);
				setDataLoaded(true);
			}).catch(() => {
				history.push(linkToTranscriptionDetails);
			});
	};

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: dict.breadcrumbs.transcriptions, link: url.transcriptions },
		{ id: 3, label: title },
	];

	return dataLoaded && (
		<main>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<div className={ styles["permissions__nav-wrapper"] }>
					<div className='block__header block__header--space-between'>
						<h1 className='text--title'>{dict.transcription.permissions.title}</h1>
						<div className={ styles["permissions__desktopNav"] }>
							<div
								className='btn-group'
								role='group'
							>
								<Button
									variant='secondary'
									href={ linkToTranscriptionDetails }
									className={ styles["transcription__link"] }
								>
									{dict.transcription.details.details}
								</Button>
								<Button
									variant='secondary'
									className={ styles["permissions__link--active"] }
								>
									{dict.transcription.details.permissions}
								</Button>
								<Button
									variant='secondary'
									className={ styles["transcription__link"] }
									onClick={ () => modalDeleteTranscription() }
								>
									{dict.transcription.details.remove}
								</Button>
								{continueButton}
							</div>
						</div>

					</div>
					<div className={ styles["permissions__mobileNav"] }>
						<Dropdown className={ styles["permissions__mobileNav--dropdown"] }>
							<Dropdown.Toggle
								variant='dropdown-secondary'
								id='permissions'
							>
								<i className='icon-hamburger' /> {dict.transcription.details.permissions}
								<i className='icon-arrow-down-full' />
							</Dropdown.Toggle>
							<Dropdown.Menu className={ styles["permissions__mobileNav--dropdown-menu"] }>
								<Dropdown.Item href={ linkToTranscriptionDetails }>{dict.transcription.details.details}</Dropdown.Item>
								<Dropdown.Item>{dict.transcription.details.remove}</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						{continueButton}
					</div>
				</div>
				<div className={ `${styles["permissions__main"]} permissions` }>
					<TranscriptionPermissionsStatus
						publicAvailable={ publicAvailable }
						dataLoaded={ dataLoaded }
					/>
					<TranscriptionPermissionsUsers
						users={ allowedUsers }
						getTranscriptionPermissionsData={ getTranscriptionPermissionsData }
					/>
					<TranscriptionPermissionsGroups
						groups={ allowedGroups }
						getTranscriptionPermissionsData={ getTranscriptionPermissionsData }
					/>
				</div>
			</Container>
		</main> 
	);
};

export default TranscriptionPermissions;