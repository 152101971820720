import React from 'react';
import PropTypes from "prop-types";

import FormInput from "views/elements/Form/FormInput";

const FormInputEmail = ({ dict, register, errors, name='email', novalidate }) => {
	return novalidate ? (
		<FormInput
			register={ register }
			name={ name }
			label={ dict.form.email }
			type='text'
			errorMessage={ errors.email && errors.email.message }
		/>
	) : (
		<FormInput
			register={ register }
			name={ name }
			label={ dict.form.email }
			type='text'
			validateData={ {
				required: {
					value: true,
					message: dict.form.messages.emptyEmail,
				},
				pattern: {
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
					message: dict.form.messages.invalidEmail,
				},
			} }
			errorMessage={ errors.email && errors.email.message }
		/>
	);
};
    
FormInputEmail.propTypes = {
	register: PropTypes.func,
	dict: PropTypes.object,
	errors: PropTypes.object,
	name: PropTypes.string,
	novalidate: PropTypes.bool,
};

export default FormInputEmail;