function setAlert(alert) {
	return { type: 'SET_ALERT', alert };
}

function clearAlert() {
	return { type: 'CLEAR_ALERT'};
}

export const alertActions = {
	setAlert,
	clearAlert,
};
