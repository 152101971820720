import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import url from "router/urls";
import api from "services/api";

import { appActions } from 'store/actions/app.actions';

import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";
import Alert from "views/elements/Alert/Alert";
import FormInput from "views/elements/Form/FormInput";
import Button from "views/elements/Button/Button";
import { useShowModal } from 'views/elements/Modal/ModalHooks';

import FormSubmit from "views/elements/Form/FormSubmit";
import styles from './TranscriptionImport.module.sass';

import CommonDropdown from 'views/elements/Dropdown/CommonDropdown';

const importFormats = [
	{ value: 'mets', label: 'METS' },
	{ value: 'iiif', label: "IIIF" },
	{ value: 'tei', label: 'TEI' },
];

const TranscriptionImport = () => {
	const history = useHistory();
	const dict = useSelector((state) => state.app.dict);
	const dispatch = useDispatch();

	const [ dropdownOption, setDropdownOption ] = useState(0);

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: dict.breadcrumbs.transcriptionNew, link: url.transcription.new },
		{ id: 3, label: dict.breadcrumbs.transcriptionImport },
	];

	const { register, handleSubmit, errors, setError, formState, clearError } = useForm({
		defaultValues: {
			resourceUrl: "",
		},
		mode: "onChange",
	});

	useEffect(() => {
		clearError("resourceUrl");
		dispatch(appActions.setImportsDomains(importFormats[dropdownOption].value));
	}, [ dropdownOption ]);

	const onSubmit = data => {
		dispatch(appActions.setLoading(true));
		api.put('imports/', { resourceUrl: data.resourceUrl, fileType: importFormats[dropdownOption].value })
			.then((res) => {
				dispatch(appActions.setImportedTranscription(res.data));
				history.push(url.transcription.new);
			})
			.catch((error) => {
				dispatch(appActions.setLoading(false));
				if (error.data && error.data.resourceUrl && error.data.resourceUrl.length > 0) {
					setError('resourceUrl', 'error', dict.transcription.import.errors[error.data.resourceUrl.replace(/[\.-]/g, "")]);  // eslint-disable-line
				}
				if (error.data && error.data.code) {
					switch (error.data.code) {
						case 'domain.error.not.available.tei':
						case 'domain.error.not.available.iiif':
						case 'domain.error.not.available.mets': {
							setError('resourceUrl', 'error', dict.transcription.import.errors[error.data.code.replace(/[\.-]/g, "")]);  // eslint-disable-line
							break;
						}

						default:
							return false;
					}

				}

			});
	};

	const modalAvailableImportSources = useShowModal({
		title: dict.modals.importAvailableSources.title[importFormats[dropdownOption].value],
		name: 'availableImportSources',
		componentPath: './parts/ModalAvailableImportSources',
	});

	return (
		<main>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<div className='block block--750'>
					<Alert />
					<div className='block__header'>
						<h1 className='text--title'>
							{dict.transcription.import.title}
						</h1>
					</div>

					<div className='block__content'>
						<div className={ `${styles["transcription-import__subtitle"]}` }>
							{dict.transcription.import.subtitle}
						</div>
						<div className={ `${styles["transcription-import__description"]}` }>
							{dict.transcription.import.description}
						</div>

						<form
							className='block__form block__form--inputfile'
							onSubmit={ handleSubmit(onSubmit) }
						>
							<div className={ `${styles["transcription-import__dropdown"]}` }>
								<CommonDropdown
									options={
										importFormats.map(it => it.label)
									}
									selectedOption={ dropdownOption }
									setSelectedOption={ setDropdownOption }
								/>
							</div>

							<div className={ `block__form--row ${styles["transcription-import__input"]}` }>
								<FormInput
									name='resourceUrl'
									register={ register }
									label={ dict.transcription.import.urlLabel }
									validateData={ {
										required: {
											value: true,
											message: dict.form.messages.emptyUrl,
										},
										pattern: { // eslint-disable-line
											value: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/, // eslint-disable-line
											message: dict.form.messages.invalidUrl,
										},
									} }
									type='text'
									errorMessage={ errors.resourceUrl ? errors.resourceUrl.message : '' }
								/>
								<Button
									variant='link'
									onClick={ () => modalAvailableImportSources() }
									className={ `${styles["transcription-import__sources"]}` }
								>
									{dict.transcription.import.checkList}
								</Button>
							</div>
							<div className={ `block__form--row ${styles["transcription-import__remember"]}` }>
								{dict.transcription.import.remember}
							</div>

							<div className='block__form--row block__form--buttons'>
								<Link
									className='block__button--cancel'
									to={ url.transcription.new }
								>
									{dict.form.cancel}
								</Link>
								<FormSubmit
									label={ dict.transcription.import.startImport }
									className='block__button--submit'
									variant='submit'
									disabled={ !formState.isValid }
								/>
							</div>
						</form>
					</div>
				</div>
			</Container>
		</main>
	);
};

export default TranscriptionImport;