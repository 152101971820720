import React from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import url from "router/urls";

import "styles/_static-page.sass";

import start1pl from 'assets/images/transcriptionHelpPage/howToStart/step1pl.png';
import start1en from 'assets/images/transcriptionHelpPage/howToStart/step1en.png';

import start2pl from 'assets/images/transcriptionHelpPage/howToStart/step2pl.png';
import start2en from 'assets/images/transcriptionHelpPage/howToStart/step2en.png';

import start3pl from 'assets/images/transcriptionHelpPage/howToStart/step3pl.png';
import start3en from 'assets/images/transcriptionHelpPage/howToStart/step3en.png';

import start4pl from 'assets/images/transcriptionHelpPage/howToStart/step4pl.png';
import start4en from 'assets/images/transcriptionHelpPage/howToStart/step4en.png';

import start5pl from 'assets/images/transcriptionHelpPage/howToStart/step5pl.png';
import start5en from 'assets/images/transcriptionHelpPage/howToStart/step5en.png';

import start6pl from 'assets/images/transcriptionHelpPage/howToStart/step6pl.png';
import start6en from 'assets/images/transcriptionHelpPage/howToStart/step6en.png';

import start7pl from 'assets/images/transcriptionHelpPage/howToStart/step7pl.png';
import start7en from 'assets/images/transcriptionHelpPage/howToStart/step7en.png';

import start8pl from 'assets/images/transcriptionHelpPage/howToStart/step8pl.png';
import start8en from 'assets/images/transcriptionHelpPage/howToStart/step8en.png';

const HowToStart = () => {

	const currentLang = useSelector((state) => state.app.currentLang);
	const dict = useSelector((state) => state.app.dict);
    
	const imagesStart = {
		step1: { pl: start1pl, en: start1en },
		step2: { pl: start2pl, en: start2en },
		step3: { pl: start3pl, en: start3en },
		step4: { pl: start4pl, en: start4en },
		step5: { pl: start5pl, en: start5en },
		step6: { pl: start6pl, en: start6en },
		step7: { pl: start7pl, en: start7en },
		step8: { pl: start8pl, en: start8en },
	};
    
	const howToStartSteps = [
		{ title: 'step1title', desc: 'step1desc', image: imagesStart.step1, link: url.auth.register, linkText: 'step1link' },
		{ title: 'step2title', image: imagesStart.step2, link: url.auth.login , linkText: 'step2link' },
		{ title: 'step3title', desc: 'step3desc', image: imagesStart.step3, link: url.transcription.new, linkText: 'step3link' },
		{ title: 'step4title', desc: 'step4desc', image: imagesStart.step4 },
		{ title: 'step5title', desc: 'step5desc', image: imagesStart.step5 },
		{ title: 'step6title', image: imagesStart.step6 },
		{ title: 'step7title', desc: 'step7desc', image: imagesStart.step7 },
		{ title: 'step8title', image: imagesStart.step8 },
	];
	
	return (
		<div className='static-page__content--instruction'>
			<h1>{dict.transcriptionHelpPage.howToStart.title}</h1>
			<p className='static-page__content--instruction-desc'>
				{dict.transcriptionHelpPage.howToStart.desc}
			</p>
            
			<ol>
				{howToStartSteps.map(item =>
					<li key={ item.title }>
						<h3>
							{dict.transcriptionHelpPage.howToStart.step[item.title]}
							{ item.link &&
								<Link to={ item.link }>
									{dict.transcriptionHelpPage.howToStart.step[item.linkText]}
								</Link>}
						</h3>
						{ item.desc && 
						<p>{dict.transcriptionHelpPage.howToStart.step[item.desc]}</p>}
						<img
							src={ item.image[currentLang] }
							alt={ item.title }
						/>
					</li>,
				)}
			</ol>
            
		</div>
	);
};

export default HowToStart;
  