import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { reverse } from 'named-urls';
import { Link } from "react-router-dom";
import url from "router/urls";
import TranscriptionsItemPage from './TranscriptionsItemPage';
import TranscriptionsProgress from './TranscriptionsProgress';

import Button from "views/elements/Button/Button";

import styles from './TranscriptionsItem.module.sass';

const TranscriptionsItem = ({ transcription }) => {
	const { titleLabel, authorsLabel, lastModificationDate, hidden, pages, percentOfVerified, viewMode, creationMode } = transcription;

	const dict = useSelector((state) => state.app.dict);

	const linkToTranscriptionDetails = reverse(url.transcription.details, { transcriptionId: parseInt(transcription.id) });

	const formatDate = (date) => {
		return date.split(" ")[0].split("-").reverse().join('.');
	};	

	return (
		<div className={ styles["transcr-item"] }>
			<div className={ styles["transcr-item__description"] }>
				<div className={ styles["transcr-item__description--left-wrapper"] }>

					<div className={ styles["transcr-item__description--label"] }>
						{dict.transcriptions.description.title}
					</div>
					<Link
						to={ linkToTranscriptionDetails }
						className={ styles["transcr-item__description--title"] }
					>
						{hidden ? (
							<i className={ `icon-locked ${styles["transcr-item__description--icon"]}` } />
						) : (
							<i className={ `icon-unlocked ${styles["transcr-item__description--icon"]}` } />
						)}

						{titleLabel}
					</Link>
					{ authorsLabel &&
						<>
							<div className={ styles["transcr-item__description--label"] }>
								{dict.transcriptions.description.author}
							</div>
							<div>
								{authorsLabel}
							</div>
						</>}
					
				</div>
				<div className={ styles["transcr-item__description--right-wrapper"] }>
					{lastModificationDate && 
						<div>
							{dict.transcriptions.description.lastEdited}{formatDate(lastModificationDate)}
						</div>}
					<div className={ styles["transcr-item__description--separator"] }>
						|
					</div>
					{pages &&
						<div>
							{dict.transcriptions.description.files}{pages.hits}
						</div>}
				</div>

				<TranscriptionsProgress
					percentOfVerified={ transcription.percentOfVerified }
					percentOfTranscribed={ transcription.percentOfTranscribed }
				/>
			</div>

			<div className={ styles["transcr-item__pages"] }>
				{pages &&
					(pages.list.length > 0) && pages.list.slice(0, 6).map((page) => 
					(
						<TranscriptionsItemPage
							key={ page.id }
							page={ page }
							transcriptionId={ parseInt(transcription.id) }
							percentOfVerified={ percentOfVerified }
							viewMode={ viewMode }
							creationMode={ creationMode }
						/>
					),
				)}
			</div>
			<div className={ styles["transcr-item__footer"] }>
				<Button
					variant='primary'
					className={ styles["transcr-item__button"] }
					href={ linkToTranscriptionDetails }
				>
					{dict.transcriptions.description.showMore}
				</Button>
			</div>
		</div>
	);
};

TranscriptionsItem.propTypes = {
	transcription: PropTypes.object,
};

export default memo(TranscriptionsItem);