import React from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';

import { useShowModal } from 'views/elements/Modal/ModalHooks';

import Button from "views/elements/Button/Button";

const VerseComment = ({ verse }) => {
	const dict = useSelector((state) => state.app.dict);
	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const readOnly = viewMode === 'read-only' || viewMode === 'student';

	const modalCommentsVerse = useShowModal({
		title: dict.modals.commentsVerse.titleVerse,
		type: 'viewer modal-comments',
		name: 'commentsVerse',
		componentPath: './parts/ModalCommentsVerse',
		componentProps: { verseId: parseInt(verse.id), verified: verse.status === 'verified'  },
		withClose: true,
	});

	return !readOnly && (
		<div className='verse__comments'>
			{ parseInt(verse.comments) > 0 && 
			<Button 
				className='verse__comments--button'
				variant='verseinfo'
				onClick={ () => modalCommentsVerse() }
			>
				<i className='icon-comment' />
				<span className='verse__comments--count'>{verse.comments}</span>
			</Button>}
			
		</div> 
	);
};

VerseComment.propTypes = {
	verse: PropTypes.object,
};

export default VerseComment;