import Login from "views/pages/Auth/Login/Login";
import Register from "views/pages/Auth/Register/Register";
import RemindPassword from "views/pages/Auth/RemindPassword/RemindPassword";
import ChangePassword from "views/pages/Auth/ChangePassword/ChangePassword";

import Account from "views/pages/Auth/Account/Account";
import Group from "views/pages/Auth/Group/Group";

import Dashboard from "views/pages/Admin/Dashboard/Dashboard";
import UserTranscriptions from "views/pages/Admin/UserTranscriptions/UserTranscriptions";

import Transcriptions from "views/pages/Transcriptions/Transcriptions";

import Viewer from "views/pages/Viewer/Viewer";
import TranscriptionPermissions from "views/pages/Transcription/TranscriptionPermissions/TranscriptionPermissions";

import TranscriptionNew from "views/pages/Transcription/TranscriptionNew/TranscriptionNew";
import TranscirptionImport from "views/pages/Transcription/TranscirptionImport/TranscirptionImport";

import TranscriptionDetails from "views/pages/Transcription/TranscriptionDetails/TranscriptionDetails";
import TranscriptionAddPages from "views/pages/Transcription/TranscriptionAddPages/TranscriptionAddPages";
import TranscriptionAddText from "views/pages/Transcription/TranscriptionAddText/TranscriptionAddText";
import TranscriptionVerification from "views/pages/Transcription/TranscriptionVerification/TranscriptionVerification";
import TranscriptionStats from "views/pages/Transcription/TranscriptionStats/TranscriptionStats";

import Home from "views/pages/Home/Home";
import PrivacyPolicy from "views/pages/PrivacyPolicy/PrivacyPolicy";
import Contact from "views/pages/Contact/Contact";
import HowToTranscribe from "views/pages/HowToTranscribe/TranscriptionHelpPage";

import error404 from "views/pages/Error/Error404";

import AppHeader from "views/templates/header/AppHeader/AppHeader";
import AuthHeader from "views/templates/header/AuthHeader/AuthHeader";
import ViewerHeader from "views/templates/header/ViewerHeader/ViewerHeader";

import AppFooter from "views/templates/footer/AppFooter/AppFooter";

import url from "./urls";

export default [
	{
		id: "login",
		path: url.auth.login,
		exact: true,
		isProtected: false,
		components: { header: AuthHeader, main: Login, footer: AppFooter },
	},
	{
		id: "emailToken",
		path: url.auth.emailToken,
		exact: true,
		isProtected: false,
		components: { header: AuthHeader, main: Login, footer: AppFooter },
	},
	{
		id: "register",
		path: url.auth.register,
		exact: true,
		isProtected: false,
		components: { header: AuthHeader, main: Register, footer: AppFooter },
	},
	{
		id: "createGroup",
		path: url.auth.group.new,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: Group, footer: AppFooter },
	},
	{
		id: "editGroup",
		path: "/group/:id",
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: Group, footer: AppFooter },
	},
	{
		id: "account",
		path: url.auth.account,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: Account, footer: AppFooter },
	},
	{
		id: "remindPassword",
		path: url.auth.remindPassword,
		exact: true,
		isProtected: false,
		components: { header: AuthHeader, main: RemindPassword, footer: AppFooter },
	},
	{
		id: "changePassword",
		path: url.auth.changePassword.token,
		exact: true,
		isProtected: false,
		components: { header: AuthHeader, main: ChangePassword, footer: AppFooter },
	},
	{
		id: "adminConsole",
		path: url.auth.admin.dashboard,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: Dashboard, footer: AppFooter },
	},
	{
		id: "adminUserTranscriptions",
		path: url.auth.admin.userTranscriptions,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: UserTranscriptions, footer: AppFooter },
	},
	{
		id: "transcriptionNew",
		path: url.transcription.new,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: TranscriptionNew, footer: AppFooter },
	},
	{
		id: "transcriptionImport",
		path: url.transcription.import,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: TranscirptionImport, footer: AppFooter },
	},
	{
		id: "transcriptionEdit",
		path: url.transcription.edit,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: TranscriptionNew, footer: AppFooter },
	},
	{
		id: "viewer",
		path: url.transcription.viewer,
		exact: true,
		isProtected: false,
		components: { header: ViewerHeader, main: Viewer },
	},
	{
		id: "transcriptionDetails",
		path: url.transcription.details,
		exact: true,
		isProtected: false,
		components: { header: AppHeader, main: TranscriptionDetails, footer: AppFooter },
	},
	{
		id: "transcriptionAddPages",
		path: url.transcription.addPages,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: TranscriptionAddPages, footer: AppFooter },
	},
	{
		id: "transcriptionPermissions",
		path: url.transcription.permissions,
		exact: true,
		isProtected: false,
		components: { header: AppHeader, main: TranscriptionPermissions, footer: AppFooter },
	},
	{
		id: "transcriptionAddText",
		path: url.transcription.addText,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: TranscriptionAddText, footer: AppFooter },
	},
	{
		id: "transcriptionAddTextToPage",
		path: url.transcription.addTextToPage,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: TranscriptionAddText, footer: AppFooter },
	},
	{
		id: "transcriptionVerification",
		path: url.transcription.verification,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: TranscriptionVerification, footer: AppFooter },
	},
	{
		id: "transcriptionStats",
		path: url.transcription.stats,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: TranscriptionStats, footer: AppFooter },
	},
	{
		id: "transcriptions",
		path: url.transcriptions,
		exact: true,
		isProtected: false,
		components: { header: AppHeader, main: Transcriptions, footer: AppFooter },
	},
	{
		id: "transcriptionsUser",
		path: url.transcriptionsUser,
		exact: true,
		isProtected: true,
		components: { header: AppHeader, main: Transcriptions, footer: AppFooter },
	},
	{
		id: "privacy-policy",
		path: url.privacyPolicy,
		exact: true,
		isProtected: false,
		components: { header: AppHeader, main: PrivacyPolicy, footer: AppFooter },
	},
	{
		id: "contact",
		path: url.contact,
		exact: true,
		isProtected: false,
		components: { header: AppHeader, main: Contact, footer: AppFooter },
	},
	{
		id: "howToTranscribe",
		path: url.howToTranscribe,
		exact: true,
		isProtected: false,
		components: { header: AppHeader, main: HowToTranscribe, footer: AppFooter },
	},
	{
		id: "home",
		path: url.home,
		exact: true,
		isProtected: false,
		components: { header: AppHeader, main: Home, footer: AppFooter },
	},
	{
		id: "error404",
		isProtected: false,
		components: { header: AppHeader, main: error404, footer: AppFooter },
	},
];
