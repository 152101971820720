import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router';
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";

import api from "services/api";
import Button from "views/elements/Button/Button";

import styles from "../TranscriptionPermissions.module.sass";

const TranscriptionPermissionsUserItem = ({ orderNum, email, userId, role, onDeleteUserProps }) => {
	const { dict, currentLang } = useSelector(state => state.app);
	const { transcriptionId } = useParams();

	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);
	const [ userRole, setRole ] = useState(role);
	const [ userRoleName, setRoleName ] = useState("");
	const [ availableRoles, setAvailableRoles ] = useState([]);

	useEffect(() => {
		let currentRole = availableRoles.filter(item => item.code === userRole)[0];
		if (currentRole) {
			setRoleName(currentRole[currentLang]);
		}
        
	}, [ userRole, role, availableRoles, currentLang ]);

	useEffect(() => {
		if (isLoggedIn) {
			getAvailableRoles();
		}
	}, [ isLoggedIn ]);

	const getAvailableRoles = () => {
		api.get("/user-roles")
			.then(res => {
				setAvailableRoles(res.data);
			});
	};

	const changeRole = roleCode => {
		setRole(roleCode);
		api.put(`/transcriptions/${transcriptionId}/allowed-users`, {
			userId: userId,
			roleCode: roleCode,
		});
	};
	const onDeleteUser = (id) => {
		onDeleteUserProps(id);
	};

	const roleSelector = (
		<Dropdown className={ styles["permissions__userlist--dropdown"] }>
			<Dropdown.Toggle
				id={ `roleSelector${orderNum}` }
				className={ styles["permissions__userlist--dropdown-role"] }
			>
				{userRoleName}
			</Dropdown.Toggle>
			<Dropdown.Menu className={ styles["permissions__userlist--dropdown-list"] }>
				{availableRoles.map(item => (
					<Dropdown.Item
						key={ item.ord }
						onClick={ () =>
							changeRole(item.code) }

						className={ `
                            ${styles["permissions__userlist--dropdown-item"]}
                            ${item[currentLang] === userRoleName ? styles["permissions__userlist--dropdown-item--active"] : ''}
                        ` }
					>
						{item[currentLang] === userRoleName && <i className='icon-accept' />}
						{item[currentLang]}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);

	const getAdminName = () => {
		const adminObject = availableRoles.filter(item => item.code === "admin")[0];

		return adminObject ? adminObject[currentLang] : null;
	};

	return (
		<div
			key={ email }
			className={ styles["permissions__userlist--item"] }
		>
			<div className={ styles["permissions__userlist--item-order"] }>{orderNum + 1}. <span className={ styles["permissions__userlist--item-emailplaceholder"] }>{email}</span></div>
			<div className={ styles["permissions__userlist--item-email"] }>
				<span className={ styles["permissions__userlist--item-placeholder"] }>{dict.transcription.permissions.email}:</span>
				{email}
			</div>
			{orderNum === 0 &&
				<div className={ styles["permissions__userlist--item-role--admin"] }>
					<span className={ styles["permissions__userlist--item-placeholder"] }>{dict.transcription.permissions.role}:</span>
					{getAdminName()}
				</div>}

			{orderNum !== 0 &&
				<div className={ styles["permissions__userlist--item-role"] }>
					<span className={ styles["permissions__userlist--item-placeholder"] }>{dict.transcription.permissions.role}:</span>
					{roleSelector}
				</div>}

			{orderNum !== 0 &&
				<Button
					variant='link'
					type='button'
					onClick={ () => onDeleteUser(userId) }
					className={ styles["permissions__userlist--item-button"] }
				>
					{dict.group.removeUser}
				</Button>}
		</div>
	);
};

TranscriptionPermissionsUserItem.propTypes = {
	orderNum: PropTypes.number,
	email: PropTypes.string,
	userId: PropTypes.number,
	role: PropTypes.string,
	onDeleteUserProps: PropTypes.func,
};

export default TranscriptionPermissionsUserItem;
