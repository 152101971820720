import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { reverse } from "named-urls";

import api from "services/api";

import url from "router/urls";

import GroupUserItem from "./elements/GroupUserItem";
import GroupUserAdd from "./elements/GroupUserAdd";
import GroupName from "./elements/GroupName";

import Error403 from "views/pages/Error/Error403";

import Button from "views/elements/Button/Button";
import { appActions } from 'store/actions/app.actions';

import styles from "./Group.module.sass";
import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";

import { useShowModal } from 'views/elements/Modal/ModalHooks';

const Group = () => {
	const dict = useSelector((state) => state.app.dict);
	const dispatch = useDispatch();
	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const modalConfirmInfo = useSelector((state) => state.app.modalConfirmInfo);
	const history = useHistory();

	const [ groupId, setGroupId ] = useState(0);

	const [ isOwner, setOwner ] = useState(false);
	const [ groupName, setGroupName ] = useState("");
	const [ groupMembers, setGroupMembers ] = useState([]);

	const [ groupNameExists, setGroupNameExists ] = useState("");

	const [ noAccess, setNoAccess ] = useState(false);

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			setGroupId(parseInt(id));
		}
	}, [ id ]);

	useEffect(() => {
		if (isLoggedIn) {
			if (groupId !== 0) {
				getGroupData();
			} else {
				setOwner(true);
			}
		}
	}, [ isLoggedIn, groupId ]);

	const onAcceptName = name => {
		if (groupId === 0) {
			createGroup(name);
		} else {
			changeGroupName(name);
		}
	};

	const getGroupData = () => {
		api.get("/groups/" + groupId)
			.then(response => {
				setOwner(response.data.owner);
				setGroupName(response.data.name);
				setGroupMembers(response.data.members);
			}).catch(() => {
				setNoAccess(true);
			});
	};

	const createGroup = name => {
		api.post("/groups", { name: name })
			.then(response => {
				history.replace(reverse(url.auth.group.edit, { id: response.data.id }));
			})
			.catch((error) => {
				if (error.data.code === "groups.error.groupAlreadyExists") {
					setGroupNameExists(dict.group.groupNameExists);
				}
			});
	};
	const changeGroupName = name => {
		setGroupNameExists("");
		if (groupName !== name) {
			api.put("/groups/" + groupId, { name: name })
				.catch((error) => {
					if (error.data.code === "groups.error.groupAlreadyExists") {
						setGroupNameExists(dict.group.groupNameExists);
					}
				});
		}
	};

	const modalGroupDeleteGroup = useShowModal({
		title: dict.modals.groupDeleteGroup.title,
		name: 'deleteGroup',
		componentPath: './parts/ModalGroupDeleteGroup',
	});	
	const deleteGroup = () => {
		if (groupId !== 0) {
			modalGroupDeleteGroup();
		}
	};

	const onAddUser = () => {
		getGroupData();
	};

	const [ deleteUserId, setDeleteUserId ] = useState(null);
	const modalGroupDeleteUser = useShowModal({
		title: dict.modals.groupDeleteUser.title,
		name: 'deleteUser',
		componentPath: './parts/ModalGroupDeleteUser',
	});	
	const onDeleteUser = (userId) => {
		setDeleteUserId(userId);
		modalGroupDeleteUser();
	};
	useEffect(() =>{
		switch (modalConfirmInfo) {
			case "USER_DELETE_CONFIRMED":
				api.delete(`/users-groups/${groupId}`, { data: { id: deleteUserId } })
					.then(() => getGroupData());
				break;
			case "GROUP_DELETE_CONFIRMED":
				if(groupId !== 0){
					api.delete(`/groups/${groupId}`)
						.then(() => history.replace(url.auth.account));
				}
				break;
			default:
				break;
		}
		dispatch(appActions.setModalConfirmInfo(null));
	}, [ modalConfirmInfo ]);

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: dict.breadcrumbs.account, link: url.auth.account },
		{ id: 3, label: dict.breadcrumbs.group },
	];

	return (!noAccess ? (
		<main className={ `${styles["group"]}` }>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<div className={ styles["group__divider"] } />

				<div className='block block--1020'>
					<div className='block__header block__header--space-between'>
						<h1 className='text--title'>{dict.group.headerGroup}</h1>
						{isOwner && groupId !== 0 && (
							<Button
								variant='link'
								type='button'
								className={ `${styles["account__button"]}` }
								onClick={ deleteGroup }
							>
								{dict.group.deleteGroup}
							</Button>
						)}
					</div>
					<div className='block__content'>
						{isOwner &&
							<GroupName
								propsGroupName={ groupName }
								onAcceptName={ onAcceptName }
							/>}
						{(groupNameExists.length > 1) && (
							<div className={ styles["group__add-user--error"] }>
								<i className='icon-close' />
								{groupNameExists}
							</div>
						)}

						{!isOwner && (
							<section className={ styles["group__name"] }>
								{dict.group.groupName} {groupName}
							</section>
						)}

						<div className={ styles["group__userlist"] }>
							{groupId !== 0 &&
								groupMembers.map((user, index) => (
									<GroupUserItem
										orderNum={ index }
										groupId={ groupId }
										key={ user.user.email }
										email={ user.user.email }
										userId={ user.user.id }
										role={ user.role.code }
										onDeleteUserProps={ onDeleteUser }
									/>
								))}
						</div>

						{groupId !== 0 &&
							<GroupUserAdd
								groupId={ groupId }
								onAddUserProps={ onAddUser }
							/>}
					</div>
				</div>
			</Container>
		</main>
	) : (
		<Error403 />
	));
};

export default Group;
