import React from 'react';
import { useSelector } from 'react-redux';
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

import { isDariahLab } from "utils/helpers";

import DariahLabUE from '@/assets/images/dariahlab/ue-header.svg';

import url from "router/urls";

import Spinner from "views/elements/Spinner/Spinner";

import styles from './AuthHeader.module.sass';

const AuthHeader = () => {
	const dict = useSelector((state) => state.app.dict);
	const currentAppState = useSelector((state) => state.app);

	return (
		<>
			<Spinner stateFromStore />
			<Container>
				<div className={ styles["auth-header"] }>
					{currentAppState.isError ? (
						<>
							<a href={ url.home }>
								<div
									className={` ${styles["auth-header__logo"]} ${ isDariahLab() && styles["auth-header__logo--dariah"]} `}
								>
									{ dict.headers.logoAlt }
								</div>
							</a>
							{ isDariahLab && <a
								className={ styles["top-bar__item"] }
								href='https://lab.dariah.pl'
								target='_blank'
								rel="noopener noreferrer"
							>
								<div
									className={styles["auth-header__dariahlab"]}
								>
									{ dict.headers.logoDariahAlt }
								</div>
								
							</a>}
						</>
					) : (
						<>
							<Link to={ url.home }>
								<div
									className={` ${styles["auth-header__logo"]} ${ isDariahLab() && styles["auth-header__logo--dariah"]} `}
								>
									{ dict.headers.logoAlt }
								</div>
							</Link>
							{ isDariahLab && <a
								className={ styles["top-bar__item"] }
								href='https://lab.dariah.pl'
								target='_blank'
								rel="noopener noreferrer"
							>
								<div
									className={styles["auth-header__dariahlab"]}
								>
									{ dict.headers.logoDariahAlt }
								</div>
								
							</a>}
						</>
					)}

				</div>
			</Container>
		</>
	);
};

export default AuthHeader;