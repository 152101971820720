import React from 'react';
import PropTypes from "prop-types";

import FormInput from "views/elements/Form/FormInput";

const FormInputPassword = ({ register, dict, errors, name = 'password', skipPatternValidation = false }) => {
	let pattern = (skipPatternValidation) ? (/^.*$/) : (/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])((?=.*[!"#$%&'()*\+,\-\./:;<=>?@\[\]\\\^_`{\|}~])|(?=.*[0-9])).*$/); // eslint-disable-line
	return (
		<FormInput
			name={ name }
			type='password'
			label={ name !== 'password' ? dict.form[name] : dict.form.password }
			register={ register }
			validateData={ {
				required: {
					value: true,
					message: dict.form.messages.fillPassword,
				},
				pattern: {
					value: pattern,
					message: dict.form.messages.incorrectPassword,
				},
			} }
			errorMessage={ errors[name] && errors[name].message }
		/> );
};

FormInputPassword.propTypes = {
	register: PropTypes.func,
	dict: PropTypes.object,
	errors: PropTypes.object,
	name: PropTypes.string,
	skipPatternValidation: PropTypes.bool,
};

export default FormInputPassword;
