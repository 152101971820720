import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { Dropdown } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';

const FORMATTING_CONTROLS = [
	{ label: "header", id: "header", icon: "verse-header" },
	{ label: "footer", id: "footer", icon: "verse-footer" },
	{ label: "pageNo", id: "page-no", icon: "page-description" },
	{ label: "titleH1", id: "end-of-heading-1", icon: "header-1-end" },
	{ label: "titleH2", id: "end-of-heading-2", icon: "header-2-end" },
	{ label: "titleH3", id: "end-of-heading-3", icon: "header-3-end" },
	{ label: "endOfParagraph", id: "endOfParagraph", icon: "quote" },
	{ label: "endOfListItem", id: "end-of-item", icon: "list-item-end" },
	{ label: "endOfList", id: "end-of-list", icon: "list-end" },
	{ label: "endOfCell", id: "end-of-cell", icon: "table-cell-end" },
	{ label: "endOfRow", id: "end-of-row", icon: "table-verse-end" },
	{ label: "endOfTable", id: "end-of-table", icon: "table-end" },
	{ label: "imageCaption", id: "imageCaption", icon: "image-description" },
	{ label: "image", id: "image", icon: "image" },
];

const VerseInfo = ({ verse, removeVerseFormatting }) => {
	const dict = useSelector((state) => state.app.dict);
	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const readOnly = viewMode === 'read-only';
	const student = viewMode === 'student';
	const mobilePanels = useSelector((state) => state.app.viewer.mobilePanels);

	const verseFormatting = verse.formatting && Object.keys(verse.formatting).filter(item => verse.formatting[item])[0];
	const verseFormattingValue = verse.formatting && Object.values(verse.formatting).filter(item => item)[0];

	const verseImage = verse.image;

	let verseInfo = {
		label: "",
		id: "",
		icon: "",
	};

	if (verseImage) {
		verseInfo = { label: "image", id: "image", icon: "image" };
	} else if (!(student && mobilePanels === 'student')) {
		FORMATTING_CONTROLS.forEach(item => {
			if (typeof verseFormattingValue === 'string' && item.id === verseFormattingValue) {
				verseInfo = item;
			}

			if (item.id === verseFormatting) {
				verseInfo = item;
			}
		});
	}
	useEffect(() => {
		ReactTooltip.rebuild();
	}, []);

	return (
		<div className='verse__info'>
			{(verseInfo.id !== '') &&
			<>
				<Dropdown>
					{
                			// when the button underneath is disabled we need "dummy" div for displaying tooltip alone
                			(readOnly || student || (viewMode === 'transcribe' && verse.status === 'verified')) &&
                            <div
                            	data-tip={ dict.viewer.tooltip.info[verseInfo.label] }
                            	data-place='left'
                            	className='viewer__verses--ghostbutton'
                            />
                		}

					<Dropdown.Toggle
						variant='verseinfo'
						data-tip={ dict.viewer.tooltip.info[verseInfo.label] }
						data-place='left'
						disabled={ readOnly || student || (viewMode === 'transcribe' && verse.status === 'verified') }
						id='verseinfo'
					>
						<span className='d-none'>{dict.viewer.tooltip.info[verseInfo.label]}</span>
						<i className={ `icon-${verseInfo.icon}` } />
					</Dropdown.Toggle>

					<Dropdown.Menu alignRight>
						<Dropdown.Item onClick={ () => removeVerseFormatting(verse.id) }>
							{dict.viewer.verse.info.delete}
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</>}
		</div>
	);
};

VerseInfo.propTypes = {
	verse: PropTypes.object,
	removeVerseFormatting: PropTypes.func,
};

export default VerseInfo;