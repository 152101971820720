import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { Link } from "react-router-dom";

import { Container } from 'react-bootstrap';

import api from "services/api";
import url from "router/urls";

import { appActions } from "store/actions/app.actions";
import Button from "views/elements/Button/Button";

import Pagination from 'views/components/Pagination/Pagination';
import SearchModule from 'views/elements/Search/SearchModule';
import TranscriptionItem from 'views/elements/Transcriptions/TranscriptionsItem';
import TranscriptionsSortSelect from 'views/elements/Transcriptions/TranscriptionsSortSelect';
import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";

import styles from "./Transcriptions.module.sass";

const Transcriptions = () => {
	const dict = useSelector((state) => state.app.dict);
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const [ listUserTranscriptions, setListUserTranscriptions ] = useState(null);

	const [ currentPageNum, setCurrentPageNum ] = useState(null);
	const [ pagesNum, setPagesNum ] = useState(null);
	const [ overallTransciptionsNum, setOverallTransciptionsNum ] = useState(0);
	const [ transcriptions, setTranscriptions ] = useState({});

	const [ searchString, setSearchString ] = useState(null);
	const [ order, setOrder ] = useState(null);

	const [ initialDataLoaded, setInitialDataLoaded ] = useState(false);
	const [ noItemWithString, setNoItemWithString ] = useState(false);

	const loadData = useCallback((myParams, userTranscriptions) => {
		const apiAddress = (userTranscriptions) ? '/my-transcriptions' : '/transcriptions';
		dispatch(appActions.setLoading(true));
		api.get(apiAddress, { params: myParams })
			.then(({ data }) => {
				if (data.pages === 0) {
					setNoItemWithString(true);
					setInitialDataLoaded(true);
					dispatch(appActions.setLoading(false));
					setPagesNum(0);
					setOverallTransciptionsNum(0);
					return;
				} else {
					setNoItemWithString(false);
				}
				if (myParams.page > parseInt(data.pages)) {
					loadData({ ...myParams, page: parseInt(data.pages) });
					history.replace(`?order=${myParams.order}${myParams.q ? `&q=${myParams.q}` : ''}&page=${parseInt(data.pages)}`);
				} else {
					dispatch(appActions.setLoading(false));
					setOverallTransciptionsNum(data.hits);
					setPagesNum(data.pages);
					setTranscriptions(data.transcriptions);
					setCurrentPageNum(data.page);
				}
				setInitialDataLoaded(true);
			});
	}, [ dispatch, history ] );

	useEffect(() => {
		if (location.pathname === url.transcriptionsUser) {
			setListUserTranscriptions(true);
		}
		const usp = new URLSearchParams(location.search);
		let pageNum = parseInt(usp.get("page"));
		let urlOrder = usp.get("order");
		const query = usp.get("q");
		if (!pageNum && !urlOrder) {
			history.replace(`?order=last-modification-date&page=1`);
		}
		if (!pageNum || pageNum === 0) {
			pageNum = 1;
		}
		if (!urlOrder) {
			urlOrder = 'last-modification-date';
		}
		if (query && query.length > 1) {
			setSearchString(query);
		}
		setCurrentPageNum(pageNum);
		setOrder(urlOrder);
		const myParams = {
			page: pageNum,
			q: ((query && query.length > 1) ? query : null),
			order: urlOrder,
		};
		loadData(myParams, (url.transcriptionsUser === location.pathname));
	}, [ loadData, history, location.pathname, location.search ]);

	useEffect(() => {
		if (initialDataLoaded) {
			history.push(`?order=${order}${searchString ? `&q=${searchString}` : ''}&page=${currentPageNum}`);
		}
	}, [ searchString, order, currentPageNum, history, initialDataLoaded ] );

	const onGoToPage = (pageNum) => {
		setCurrentPageNum(pageNum);
	};

	const breadcrumbsList = () => {
		if (listUserTranscriptions) {
			return (
				[
					{ id: 1, label: "home", link: url.home },
					{ id: 2, label: dict.breadcrumbs.transcriptions, link: url.transcriptions },
					{ id: 3, label: dict.breadcrumbs.transcriptionsUser },
				]
			);
		} else {
			return (
				[
					{ id: 1, label: "home", link: url.home },
					{ id: 2, label: dict.breadcrumbs.transcriptions },
				]
			);
		}
	};

	return (
		<main className={ styles["transcriptions"] }>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList() } />
				<section
					className={ (!listUserTranscriptions) ? styles["transcriptions__header"] : styles["transcriptions__header--columns"] }
				>
					<h1 className={ styles["transcriptions__header--title"] }>
						{(listUserTranscriptions) ? dict.transcriptions.header.titleMy : dict.transcriptions.header.title}
						{ (!searchString) && (
							<span className={ styles["transcriptions__header--transcr-number"] }> ({overallTransciptionsNum})</span>
						)}
					</h1>
					{(!isLoggedIn) && (
						<>
							<div className={ styles["transcriptions__header--description"] }>
								{dict.transcriptions.header.descr}
							</div>
							<div className={ styles["transcriptions__header--description"] }>
								{dict.transcriptions.header.login}
								<Link to={ url.auth.login }>{dict.transcriptions.header.myTranscriptionsLink}</Link>
							</div>
						</>
					)}

					{(listUserTranscriptions) &&
						<Button
							variant='primary'
							className={ styles["transcr-item__button"] }
							href={ url.transcription.new }
						>
							{dict.transcriptions.header.createNew}
						</Button>}
				</section>
				{(searchString) && (
					<div className={ styles["transcriptions__header--search-result"] }>
						<strong>{dict.transcriptions.search.searchingPhrase} </strong> {searchString} <span className={ styles["transcriptions__header--search-number"] }> ({overallTransciptionsNum})</span>
					</div>
				)}
				<div className={ styles["transcriptions__search"] }>				
					<SearchModule
						setSearchString={ setSearchString }
						searchString={ searchString }
						placeholder={ dict.transcriptions.search.placeholder }
					/>
				</div>
				<div className={ styles["transcriptions__select"] }>
					<TranscriptionsSortSelect
						setOrder={ setOrder }
						order={ order }
					/>
				</div>

				<section className={ (pagesNum > 1 && !noItemWithString) ? styles["transcriptions__pagination-wrapper"] : styles["transcriptions__no-pagination-wrapper"] }>
					{noItemWithString ? (
						<>
							<div className={ styles["transcriptions__error"] }>
								{ searchString ?  dict.transcriptions.search.noTranscriptionsFound : dict.transcriptions.search.userHasNoTranscriptions }
							</div>
						</>
					) : (
						<Pagination
							currentPage={ currentPageNum }
							pagesNum={ pagesNum }
							onGoToPage={ onGoToPage }
						>
							<div className={ styles["transcriptions__transcr-container"] }>
								{
									(transcriptions.length > 0) &&
										transcriptions.map((item, index) => {
											return (
												<TranscriptionItem
													transcription={ item }
													key={ item.titleLabel + index.toString() }
												/>
											);
										})
								}
							</div>
						</Pagination>
					)}
				</section>

			</Container>
		</main>
	);
};
export default Transcriptions;
