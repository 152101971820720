import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Dropdown, Form } from "react-bootstrap";
import Button from "views/elements/Button/Button";
import { useHistory } from 'react-router';
import { reverse } from 'named-urls';

import api from "services/api";
import url from "router/urls";

import styles from '../Dashboard.module.sass';

const UserItem = ({ orderNum, id, email, enabled, userRole, trustedTranscriber, quota, usedSpaceQuota, onQuotaChange }) => {
	const history = useHistory();
	const { dict } = useSelector(state => state.app);
	const [ itemUserRole, setItemUserRole ] = useState(userRole);
	const [ isTrusted, setIsTrusted ] = useState(trustedTranscriber);
	const [ userEnabled, setUserEnabled ] = useState(enabled);
	const [ editMode, setEditMode ] = useState(false);
	const nameInput = useRef(null);
	const [ inputValue, setInputValue ] = useState(quota);

	useEffect(() => {
		nameInput.current.value = inputValue;
	}, [ inputValue ]);

	const showUsersTranscription = () => {
		history.push(reverse(url.auth.admin.userTranscriptions, { id: id }));
	};
	const switchUser = (option) => {
		let enbld = (option === dict.admin.dashboard.active);
		api.put(`/admin/users/${id}?enabled=${enbld}`);
		setUserEnabled(enbld);
	};

	const toggleSuperadmin = () => {
		let roleString = itemUserRole === 'SUPER_ADMIN' ? 'USER' : 'SUPER_ADMIN';
		setItemUserRole(roleString);
		api.put(`/admin/users/${id}/role?role=${roleString}`);
	};
	const toggleTrusted = () => {
		setIsTrusted(state => {
			const newState = !state;
			api.put(`/admin/users/${id}/trust?trust=${newState}`);
			return newState;
		});
	};

	const changeQuotaSubmit = () => {

		setInputValue(nameInput.current.value);
		onQuotaChange(id, nameInput.current.value);
		setEditMode(false);
	};
	const changeQuotaCancel = () => {
		nameInput.current.value = inputValue;
		setEditMode(false);
	};

	const handleKeyDown = (ev) => {
		if (ev.key === 'Enter') {
			changeQuotaSubmit();
		}
	};
	return (
		<div className={ styles["userlist__item"] }>
			<div className={ styles["userlist__item--order"] }>{orderNum + 1}.</div>
			<div className={ styles["userlist__item--email"] }>
				<div className={ styles["userlist__item--text-elipsis"] }>{email}</div>
			</div>
			<div className={ styles["userlist__item--limit"]  }>
				<span className={ styles["userlist__item--placeholder"] }>{dict.admin.dashboard.limit}</span>
				<input
					type='text'
					ref={ nameInput }
					disabled={ !editMode }
					onKeyDown={ handleKeyDown }
					className={ styles["userlist__item--input"] }
				/>
				<span className={ styles["userlist__item--quota-text"] }>{dict.auth.account.mb}</span>
				{editMode ? (
					<div className='input-group-append'>
						<Button
							onClick={ () => changeQuotaCancel() }
							variant='editQuota icon'
							className={ styles["userlist__item--quota-btn"] }

						>
							<i className='icon-close' />
						</Button>
						<Button
							onClick={ changeQuotaSubmit }
							variant='quotaAccept icon'
						>
							<i className='icon-accept' />
						</Button>
					</div>
				)
					: (
						<Button
							variant='editQuota icon icon-xl'
							onClick={ () => setEditMode(true) }
							className={ styles["userlist__item--quota-btn"] }

						>
							<i className='icon-edit' />
						</Button>
					)}

			</div>
			<div className={ styles["userlist__item--used"] }>
				<span className={ styles["userlist__item--placeholder"] }>{dict.admin.dashboard.used}</span>
				<div className={ styles["userlist__item--text-elipsis"] }>
					{usedSpaceQuota !== null ? usedSpaceQuota : 0}
					<span className={ styles["userlist__item--quota-text"] }>{dict.auth.account.mb}</span>
				</div>
			</div>
			<div className={ styles["userlist__item--status"] }>
				<span className={ styles["userlist__item--placeholder"] }>{dict.admin.dashboard.status}: </span>
				<Dropdown className={ styles["userlist__dropdown"] }>
					<Dropdown.Toggle
						id={ "roleSelector" + orderNum }
						className={ styles["userlist__dropdown--btn"] }
					>
						{userEnabled ? dict.admin.dashboard.active : dict.admin.dashboard.disabled}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{[ dict.admin.dashboard.active, dict.admin.dashboard.disabled ].map(item => (
							<Dropdown.Item
								key={ item }
								onClick={ () => switchUser(item) }
							>
								{item}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
			</div>
			<div className={ styles["userlist__item--superadmin"] }>
				<Form.Control
					className={ styles["userlist__checkbox"] }
					type='checkbox'
					checked={ itemUserRole === 'SUPER_ADMIN' }
					onChange={ toggleSuperadmin }
				/>
				<Form.Label className={ styles["userlist__label"] }>
					<div className={ styles["userlist__item--text-elipsis"] }>{dict.admin.dashboard.superadministrator}</div>
				</Form.Label>
			</div>
			<div className={ styles["userlist__item--trusted"] }>
				<Form.Control
					className={ styles["userlist__checkbox"] }
					type='checkbox'
					checked={ isTrusted }
					onChange={ toggleTrusted }
				/>
				<Form.Label className={ styles["userlist__label"] }>
					<div className={ styles["userlist__item--text-elipsis"] }>Zaufany</div>
				</Form.Label>
			</div>

			<Button
				variant='link'
				type='button'
				onClick={ () => showUsersTranscription(email) }
				className={ styles["userlist__item--btn"] }
			>
				<div className={ styles["userlist__item--text-elipsis"] }>{dict.admin.dashboard.transcriptions}</div>
			</Button>
		</div>
	);
};

UserItem.propTypes = {
	id: PropTypes.number,
	orderNum: PropTypes.number,
	email: PropTypes.string,
	enabled: PropTypes.bool,
	userRole: PropTypes.string,
	usedSpaceQuota: PropTypes.number,
	quota: PropTypes.number,
	onQuotaChange: PropTypes.func,
};

export default UserItem;