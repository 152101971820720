import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';

import { staticPageActions } from "store/actions/staticPage.actions";
import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";

import { Container, Row, Col, Image } from "react-bootstrap";

import contactImage from '@/assets/images/contact/contactImage.png';
import "styles/_static-page.sass";

const Contact = () => {

	const currentLang = useSelector((state) => state.app.currentLang);
	const dict = useSelector((state) => state.app.dict);

	const contactPage = useSelector((state) => state.staticPage.contactPage);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(staticPageActions.getContactPage());
	}, [ dispatch ]);

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label: dict.staticPage.contact, id: 2 },
	];

	return (
		<main className='static-page'>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<section className='static-page__content'>
					<Row>
						<Col lg={ 4 }>
							{contactPage !== '' &&
								<ReactMarkdown source={ contactPage[currentLang] } />}
						</Col>
						<Col lg={ 8 }>
							<Image
								src={ contactImage }
								alt={ dict.staticPage.contact }
								fluid
							/>
						</Col>
					</Row>
				</section>
			</Container>
		</main>
	);
};

export default Contact;
