import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "views/elements/Button/Button";

import styles from '../UserTranscriptions.module.sass';

const AdminTranscriptionItem = ({ orderNum, id, title, authors, deleteTranscription, openTranscription }) => {
	const { dict } = useSelector(state => state.app);

	return (
		<div className={ styles["transcription-list__item"] }>
			<div className={ styles["transcription-list__item--first"] }>
				<div className={ styles["transcription-list__item--order"] }>{orderNum + 1}.</div>
				<div className={ styles["transcription-list__item--title"] }>
					{title}
				</div>
			</div>
			<div className={ styles["transcription-list__item--authors"] }>
				{authors}
			</div>

			<div className={ styles["transcription-list__item--buttons"] }>
				<Button
					variant='link'
					type='button'
					onClick={ () => openTranscription(id) }
					className={ styles["transcription-list__item--btn-left"] }
				>
					{dict.admin.userTranscriptions.open}
				</Button>
				<Button
					variant='link'
					type='button'
					className={ `${styles["transcription-list__item--btn"]} ${styles["btn-last"]}` }
					onClick={ () => deleteTranscription(id) }
				>
					{dict.admin.userTranscriptions.delete}
				</Button>
			</div>
		</div>
	);
};

AdminTranscriptionItem.propTypes = {
	id: PropTypes.number,
	orderNum: PropTypes.number,
	title: PropTypes.string,
	authors: PropTypes.string,
	deleteTranscription: PropTypes.func,
	openTranscription: PropTypes.func,
};

export default AdminTranscriptionItem;