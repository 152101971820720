import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import CommonDropdown from 'views/elements/Dropdown/CommonDropdown';

const RoleDropdown = ({ roleOptions, fieldName, isDisabled }) => {
	const dict = useSelector((state) => state.app.dict);
	const { register, setValue, getValues } = useFormContext();

	const [ selectedOption, setSelectedOption ] = useState(roleOptions.findIndex(it => it.value === getValues(fieldName)));

	useEffect(() => {
		if (selectedOption > -1){
			setValue(fieldName, roleOptions[selectedOption].value);
		} else{
			setSelectedOption(0);
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ selectedOption ]);

	return (
		<div className='transcription-new__form--inline-select'>
			<CommonDropdown
				options={ roleOptions.map(item => item.label) }
				selectedOption={ selectedOption }
				setSelectedOption={ setSelectedOption }
				label={ dict.transcription.form.role }
				isDisabled={ isDisabled }
			/>
			<label className='d-inline'>
				<span className='d-none'>{ dict.transcription.form.role }</span>
			
				<input
					name={ fieldName }
					style={ {display: 'none'} }
					ref={ register() }
				/>
			</label>
		</div>
	);
};

RoleDropdown.propTypes = {
	fieldName: PropTypes.string,
	isDisabled: PropTypes.bool,
	roleOptions: PropTypes.array,
};

export default RoleDropdown;