import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import api from "services/api";

import Button from 'views/elements/Button/Button';

import styles from '../Group.module.sass';

const GroupUserAdd = ({ groupId, onAddUserProps }) => {
	const dict = useSelector((state) => state.app.dict);

	const { register, handleSubmit, formState, errors, reset, setError } = useForm({ mode: 'onChange' });

	const [ isSendingEmail, setIsSendingEmail ] = useState(false);

	const onAddUser = (data) => {
		setIsSendingEmail(true);
		api.post(`/users-groups/${groupId}`, { email: data.email})
			.then(() => {
				onAddUserProps();
				reset();
				setIsSendingEmail(false);
			}).catch((error) => {
				if(error.data.code === "groups.error.noUserWithGivenEmail"){
					setError('email', 'notFound', dict.form.messages.emailNotExists);
				}else if (error.data.code === "groups.error.userAlreadyExistsInGroup"){
					setError('email', 'notFound', dict.form.messages.emailExistsInGroup);
				}
				setIsSendingEmail(false);
			});
	};

	return (
		<section className={ styles["group__add-user"] }>
			<h2 className={ styles["group__add-user--header"] }>
				{dict.group.addUserHead}
			</h2>
			<p className={ styles["group__add-user--description"] }>
				{dict.group.addUserText}
			</p>
			<form
				onSubmit={ handleSubmit(onAddUser) }
				noValidate
			>
				<label className={ `input-group ${styles["group__add-user--tf"]}` }>
					<input
						name='email'
						type='email'
						placeholder={ dict.group.addUserPlaceholder }
						className={ 
						`${styles["group__add-user--field"]}
						${errors.email && styles["group__add-user--field-error"]}
						`
						}
						ref={ register({
							pattern: {
                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
								message: dict.form.messages.invalidEmail,
							},
						}) }	
					/>
					<div className='input-group-append'>
						<Button 
							type='submit'
							disabled={ isSendingEmail || !formState.dirty || !formState.isValid }
						>
							{dict.group.addButton}
						</Button>
					</div>
					{errors.email && (
						<div className={ styles["group__add-user--error"] }>
							<i className='icon-close' />
							{errors.email.message}
						</div>
					)}
				</label>
			</form>
		</section>
	);
};

GroupUserAdd.propTypes = {
	groupId: PropTypes.number,
	onAddUserProps: PropTypes.func,
};

export default GroupUserAdd;