import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import { app } from './app.reducer';
import { alert } from './alert.reducer';
import { auth } from './auth.reducer';
import { modal } from './modal.reducer';
import { viewer } from './viewer.reducer';
import { staticPage } from "./staticPage.reducer";

export default combineReducers({
	app,
	auth,
	alert,
	staticPage,
	viewer,
	modal,
	loadingBar: loadingBarReducer,
});
