import React from "react";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import ReactTooltip from 'react-tooltip';

import FormInputCustom from "views/elements/Form/FormInputCustom";

const TranscriptionNewIndentifierLevel = ({ isDisabled }) => {
	const { register } = useFormContext();
	const dict = useSelector((state) => state.app.dict);
	ReactTooltip.rebuild();

	return (
		<div className='transcription-new__level'>
			<h3 className='text--level-title'>
				{dict.transcription.details.identifierLevel}
			</h3>
			<div className='transcription-new__form--row'>
				<label className='transcription-new__form--label'>
					{dict.transcription.details.importAdress}
					<span
						className='transcription__tooltip'
						data-tip={ dict.transcription.details.importAdressDesc }
						data-place='right'
					>
						<i className='icon-question'  />
					</span>
				</label>
				<FormInputCustom
					register={ register }
					name='externalUrl'
					placeholder={ dict.transcription.form.keywordsPlaceholder }
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>
			</div>
			<div className='transcription-new__form--row'>
				<label className='transcription-new__form--label'>
					{dict.transcription.details.externalId}
					<span
						className='transcription__tooltip'
						data-tip={ dict.transcription.details.externalIdDesc }
						data-place='right'
					>
						<i className='icon-question'  />
					</span>
				</label>
				<FormInputCustom
					register={ register }
					name='externalId'
					placeholder={ dict.transcription.form.keywordsPlaceholder }
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>
			</div>

		</div>
	);
};

TranscriptionNewIndentifierLevel.propTypes = {
	isDisabled: PropTypes.bool,
};

export default TranscriptionNewIndentifierLevel;