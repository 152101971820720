import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { reverse } from 'named-urls';
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router';

import { appActions } from 'store/actions/app.actions';
import { viewerActions } from 'store/actions/viewer.actions';

import { useShowModal } from 'views/elements/Modal/ModalHooks';

import url from "router/urls";

import Button from "views/elements/Button/Button";
import Spinner from "views/elements/Spinner/Spinner";
import AuthHeader from "views/templates/header/AuthHeader/AuthHeader";

import wlt_logo from 'assets/images/wlt_logo-small.svg';

const ViewerHeader = () => {
	const viewerData = useSelector((state) => state.viewer.data);
	const dict = useSelector((state) => state.app.dict);
	const swapPanels = useSelector((state) => state.app.viewer.swapPanels);
	const isOCRProcessing = useSelector((state) => state.viewer.isOCRProcessing);
	const dispatch = useDispatch();
	const location = useLocation();
	const { transcriptionId, pageId } = useParams();

	const viewMode = viewerData.viewMode;
	const readOnly = viewMode === 'read-only' || viewMode === 'student';
	const noAccess = useSelector( (state) => state.viewer.noAccess );

	const [ activeTranscribed, setActiveTranscribed ] = useState(false);
	const [ activeVerified, setActiveVerified ] = useState(false);
	const [ anyVerseVerified, setAnyVerseVerified ] = useState(false);

	const changeSwapPanels = () => {
		dispatch(appActions.setSwapPanels(!swapPanels));
	};

	const correctTranscription = () => {
		dispatch(viewerActions.submitCorrectTranscription(viewerData.id));
	};

	const emptyTranscription = () => {
		dispatch(viewerActions.submitEmptyTranscription(viewerData.id));
	};

	const downloadFileFormat = (format) => {
		dispatch(viewerActions.getFileByFormat(viewerData.id, format));
	};

	const modalAutoTranscribePage = useShowModal({
		title: dict.modals.autoTranscribePage.title,
		type: 'viewer',
		name: 'autoTranscribePage',
		componentPath: './parts/ModalAutoTranscribePage',
		componentProps: {
			transcriptionId: parseInt(transcriptionId),
			pageId: parseInt(pageId),
			anyVerseVerified: anyVerseVerified,
			viewMode: viewMode,
		},
	});

	useEffect(() => {
		const verses = [ ...viewerData.verses ];
		const versesTranscribed = verses.filter(item => ( item.status === 'transcribed' || item.status === 'verified' ));
		const versesVerified = verses.filter(item => item.status === 'verified');

		setActiveTranscribed(verses.length === versesTranscribed.length);
		setActiveVerified(verses.length === versesVerified.length);
		setAnyVerseVerified(!!versesVerified.length);
	}, [ viewerData.verses ]);

	return !noAccess ? (
		<>
			<Spinner
				stateFromStore
				viewer
			/>
			<header className='viewer-header'>
				<div className='viewer-header__main'>
					<div
						className='viewer-header__logo'
						data-tip={ dict.viewer.tooltip.header.homeBt }
						data-place='right'
					>
						<Link
							to={ url.home }
							className='viewer-header__logo--href'
						>
							<img
								src={ wlt_logo }
								alt={ dict.headers.logoAlt }
								className='viewer-header__logo--image'
							/>
						</Link>
					</div>
					<div className='viewer-header__title'>
						<h1 className='viewer-header__text--title'>
							<strong>{dict.viewer.header.transcriptionObject}:</strong> {viewerData.titleLabel}
						</h1>
					</div>

					<div className='viewer-header__info'>
						<Button
							variant='icon'
							className='viewer-header__close'
							href={ `${reverse(url.transcription.details, { transcriptionId: transcriptionId })}${location.state && location.state.paginationPage ? `?page=${location.state.paginationPage}` : ''}` }
						>
							<span className='d-none'>X</span>
							<i className='icon-close' />
						</Button>
					</div>
				</div>
				<nav className='viewer-header__nav'>
					<div className='viewer-header__nav--left'>
						{!readOnly &&
							<div
								className='viewer-btn__wrapper'
								data-tip={ dict.viewer.tooltip.header.autoBt }
								data-place='right'
							>
								<Button
									variant='viewer-button'
									className='viewer-header__button--auto-transcribe'
									onClick={ () => modalAutoTranscribePage() }

									disabled={ ( viewMode === 'transcribe' && activeVerified ) || isOCRProcessing }
								>
									<i className='icon-site-transcription' />
									{dict.viewer.header.autoTranscribe}
								</Button>
							</div>}
						<div
							className='viewer-btn__wrapper'
							data-tip={ dict.viewer.tooltip.header.switchBt }
							data-place='bottom'
						>
							<Button
								variant='viewer-button'
								className='viewer-header__button--changepanels'
								onClick={ changeSwapPanels }
							>
								<i className='icon-change-site' />
								{dict.viewer.header.swapPanels}
							</Button>
						</div>
					</div>
					<div className='viewer-header__nav--right'>
						{!readOnly && 
						<>
							{ viewerData.verses.length ? (
								<div
									data-tip={ dict.viewer.tooltip.header.correctTranscription }
									data-place='bottom'
									className='viewer-header__div2tooltip'
								>
									<Button
										variant='viewer-button'
										className='viewer-header__button--correct-transcription'
										onClick={ correctTranscription }
										disabled={ ( viewMode === 'transcribe' && activeTranscribed ) || (viewMode === 'verify' && activeVerified) || (viewMode === 'admin' && activeVerified) || isOCRProcessing }
									>
										{(viewMode === 'transcribe') ? (
											<div className='verse__checkbox--accept'>
												<i className='icon-accept' />
											</div>
										) : (
											<div className='verse__checkbox--accept-double'>
												<i className='icon-accept' />
												<i className='icon-accept' />
											</div>
										)}
										{dict.viewer.header.correctTranscription}
									</Button>
									<hr className='viewer-header__divider' />
								</div>
							) : ( 
								<>
									{ !viewerData.empty && 
									<div
										data-tip={ dict.viewer.tooltip.header.emptyTranscription }
										data-place='bottom'
										className='viewer-header__div2tooltip'
									>
										<Button
											variant='viewer-button'
											className='viewer-header__button--empty-transcription'
											onClick={ emptyTranscription }
											disabled={ isOCRProcessing }
										>
											<div className='verse__checkbox--accept'>
												<i className='icon-accept' />
											</div>
											{dict.viewer.header.emptyTranscription}
										</Button>
										<hr className='viewer-header__divider' />
									</div>}
								</>
							)}
						</>}
						<Dropdown className='viewer-header__dropdown'>
							<div
								className='viewer-btn__wrapper'
								data-tip={ dict.viewer.tooltip.header.downloadTooltip }
								data-place='bottom'
							>
								<Dropdown.Toggle
									variant='viewer-button'
									className='viewer-header__button--download'
									disabled={ isOCRProcessing }
									id='download'
								>
									<i className='icon-download' />
									{dict.viewer.header.download}
								</Dropdown.Toggle>
							</div>

							<Dropdown.Menu>
								<Dropdown.Item onClick={ () => downloadFileFormat('hocr') }>{dict.viewer.header.downloadhOCR}</Dropdown.Item>
								<Dropdown.Item onClick={ () => downloadFileFormat('plain-text') }>{dict.viewer.header.downloadText}</Dropdown.Item>
								<Dropdown.Item onClick={ () => downloadFileFormat('tei') }>{dict.viewer.header.downloadTEI}</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<hr className='viewer-header__divider' />
						<div
							className='viewer-btn__wrapper'
							data-tip={ dict.viewer.tooltip.header.prevPage }
							data-place='left'
						>
							<Button
								variant='viewer-button'
								className='viewer-header__button--pages'
								disabled={ !viewerData.prevPageId }
								href={ reverse(url.transcription.viewer, { transcriptionId: parseInt(transcriptionId), pageId: parseInt(viewerData.prevPageId) }) }
							>
								<i className='icon-chevron-double-left' />
								{dict.viewer.header.pages.prev} <span className='viewer-header__button--touch'>{dict.viewer.header.pages.page}</span>
							</Button>
						</div>
						<div
							className='viewer-btn__wrapper'
							data-tip={ dict.viewer.tooltip.header.nextPage }
							data-place='left'
						>
							<Button
								variant='viewer-button'
								className='viewer-header__button--pages'
								disabled={ !viewerData.nextPageId }

								href={ reverse(url.transcription.viewer, { transcriptionId: parseInt(transcriptionId), pageId: parseInt(viewerData.nextPageId) }) }
							>
								{dict.viewer.header.pages.next} <span className='viewer-header__button--touch'>{dict.viewer.header.pages.page}</span>
								<i className='icon-chevron-double-right' />
							</Button>
						</div>
					</div>
				</nav>
			</header>

		</>
	) : (
		<AuthHeader />
	);
};

export default ViewerHeader;