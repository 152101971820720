import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { Dropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { reverse } from 'named-urls';

import { useShowModal } from 'views/elements/Modal/ModalHooks';
import url from "router/urls";
import Button from 'views/elements/Button/Button';

import styles from "../TranscriptionDetails.module.sass";

const TranscriptionDetailsNav = ({ viewMode, creationMode, creationDate, transcriptionId, continuation, receiverId, creatorEmail }) => {
	const dict = useSelector((state) => state.app.dict);
	const history = useHistory();
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);
	const email = useSelector(state => state.auth.credentials.email);

	const modalDeleteTranscription = useShowModal({
		title: dict.modals.deleteTranscription.title,
		name: 'deleteTranscription',
		componentPath: './parts/ModalDeleteTranscription',
		componentProps: { transcriptionId: parseInt(transcriptionId) },
	});

	const modalContactAuthor = useShowModal({
		title: dict.modals.contactAuthor.title,
		name: 'contactAuthor',
		componentPath: './parts/ModalContactAuthor',
		componentProps: { receiverId: parseInt(receiverId) },
	});

	const onContinueTranscription = () => {
		if (continuation.lastPageId){
			const linkToTranscriptionView = reverse(url.transcription.viewer, { transcriptionId: parseInt(transcriptionId), pageId: parseInt(continuation.lastPageId) });
			history.push(linkToTranscriptionView);
		}
		
	};
	const onEditTranscription = () => {
		history.push(reverse(url.transcription.edit, { transcriptionId: parseInt(transcriptionId) }));
	};

	const linkToTranscriptionPermissions = reverse(url.transcription.permissions, { transcriptionId: transcriptionId });

	const continueButton = ( continuation && (
		<Button
			variant='primary'
			className={ styles['transcription__mobileNav--additional-button'] }
			onClick={ () => onContinueTranscription() }
			disabled={ !(continuation.lastPageId) }
		>
			{(continuation.state === "not-started") && dict.transcription.details.start}
			{(continuation.state === "in-progress") && dict.transcription.details.continue}
			{(continuation.state === "finished") && dict.transcription.details.transcriptionComplete}
		</Button>
	));

	return (
		<div>
			{(isLoggedIn) &&
				<div>
					<div className='block__header block__header--space-between'>
						{(viewMode === 'admin') && <h1 className={ styles["transcription__title"] }>
							<span>{dict.transcription.details.myTranscription}</span>
						</h1>}
						<div className={ styles["transcription__desktopNav"] }>
							<div
								className='btn-group'
								role='group'
							>
								{(viewMode === 'admin') &&
									<>
										<Button
											variant='secondary'
											className={ styles["transcription__link--active"] }
										>
											{dict.transcription.details.details}
										</Button>
										<Button 
											variant='secondary'
											href={ linkToTranscriptionPermissions }
											className={ styles["transcription__link"] }
										>
											{dict.transcription.details.permissions}
										</Button>
										<Button
											variant='secondary'
											className={ styles["transcription__link"] }
											onClick={ () => modalDeleteTranscription() }
										>
											{dict.transcription.details.remove}
										</Button>
									</>}
								{continueButton}
							</div>
						</div>

					</div>
					<div className={ styles["transcription__mobileNav"] }>
						{(viewMode === 'admin') &&
							<Dropdown className={ styles["transcription__mobileNav--dropdown"] }>
								<Dropdown.Toggle
									variant='dropdown-secondary'
									id='details'
								>
									<i className='icon-hamburger' />
									{dict.transcription.details.details}
									<i className='icon-arrow-down-full' />
								</Dropdown.Toggle>
								<Dropdown.Menu className={ styles["transcription__mobileNav--dropdown-menu"] }>
									<Dropdown.Item href={ linkToTranscriptionPermissions }>{dict.transcription.details.permissions}</Dropdown.Item>
									<Dropdown.Item>{dict.transcription.details.remove}</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>}
						{continueButton}
					</div>
				</div>}

			<div className={ styles["transcription__additionalText--box"] }>
				{ (isLoggedIn && (creatorEmail !== email)) && 
					<h3 className={ styles["transcription__additionalText"] }>
						<span>{dict.transcription.details.contact} </span>
						<Button
							variant='link'
							onClick={ () => modalContactAuthor() }
						>
							<i className='icon-mail' />
							{dict.transcription.details.sendEmail}
						</Button>
					</h3>}
				<h3 className={ styles["transcription__additionalText"] }>
					<span className={ styles["transcription__additionalText--gray"] }>{dict.transcription.details.dateOfCreation}</span> {creationDate.slice(0, 10)}
				</h3>
			</div>

			<div className='block__header block__header--space-between'>
				<h1 className={ styles["transcription__metadata"] }>
					{dict.transcription.details.metadata}
				</h1>
				{(viewMode === 'admin' && creationMode === 'web-form') &&
					<Button
						variant='secondary'
						onClick={ () => onEditTranscription() }
					>{dict.transcription.details.edit}
					</Button>}
			</div>
		</div>
	);
};

TranscriptionDetailsNav.propTypes = {
	viewMode: PropTypes.string,
	creationMode: PropTypes.string,
	creationDate: PropTypes.string,
	creatorEmail: PropTypes.string,
	transcriptionId: PropTypes.number,
	receiverId: PropTypes.number,
	continuation: PropTypes.object,
};

export default TranscriptionDetailsNav;
