import React from "react";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import FormInputCustom from "views/elements/Form/FormInputCustom";

import FormTextarea from "views/elements/Form/FormTextarea";

const TranscriptionNewTextProfileLevel = ({ isDisabled }) => {
	const { register } = useFormContext();
	const dict = useSelector((state) => state.app.dict);
    
	return (
		<div className='transcription-new__level'>
			<h3 className='text--level-title'>
				{ dict.transcription.details.textLevel }
			</h3>
			<span className='text--level-desc'>
				{ dict.transcription.details.textLevelDescription }
			</span>
			<div className='transcription-new__form--row'>
				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.keywords }
					name='profileDesc.keywords'
					placeholder={ dict.transcription.form.keywordsPlaceholder }
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>
			</div>

			<div className='transcription-new__form--row'>
				<FormTextarea
					name='profileDesc.summary'
					register={ register }
					label={ dict.transcription.form.summary }
					isDisabled={ isDisabled }
				/>
			</div>
		</div>
	);
};

TranscriptionNewTextProfileLevel.propTypes = {
	isDisabled: PropTypes.bool, 
};

export default TranscriptionNewTextProfileLevel;