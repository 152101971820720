import React from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import url from "router/urls";

import Button from 'views/elements/Button/Button';

import styles from '../../Login.module.sass';

const hostname = window.location.origin;
const serviceProviderAdURL = process.env.REACT_APP_LOGIN_SP_AD_URL;
const serviceProviderPionierIdURL = process.env.REACT_APP_LOGIN_SP_PIONIERID_URL;
const facebookLoginIsActive = process.env.REACT_APP_LOGIN_FACEBOOK === 'true';
const googleLoginIsActive = process.env.REACT_APP_LOGIN_GOOGLE === 'true';
const apiURL = `${process.env.REACT_APP_API}/api`;

const LoginAlternate = () => {
	const dict = useSelector((state) => state.app.dict);
	const currentLang = useSelector((state) => state.app.currentLang);
	const returnUrl = useSelector((state) => state.auth.login.returnUrl);
	const returnURI = `${hostname}${returnUrl ? returnUrl : ''}`;

	return ( (serviceProviderAdURL || serviceProviderPionierIdURL || facebookLoginIsActive || googleLoginIsActive) &&
		<div className={ styles["login-alternative"] }>
			<h2 className={ styles["login-alternative__header"] }>{dict.auth.login.alternativeLoginHead}</h2>
			<h3 className={ styles["login-alternative__subheader"] }>
				{ dict.auth.login.alternativeLoginSubHead1 } <Link to={ url.privacyPolicy }>{ dict.auth.login.alternativeLoginSubHead2 }</Link> { dict.auth.login.alternativeLoginSubHead3 }
			</h3>
			<div className={ styles["login-alternative__button-list"] }>
				{ serviceProviderAdURL && 
				<Button
					variant='external'
					href={ `${serviceProviderAdURL}?lang=${currentLang}&returnUrl=${returnURI}` }
				>
					{dict.auth.login.alternativeLogin.uwrad.title}
					<i className='icon-external' />
				</Button>}
				{ serviceProviderPionierIdURL && 
				<Button
					variant='external'
					href={ `${serviceProviderPionierIdURL}?lang=${currentLang}&returnUrl=${returnURI}` }
				>
					{dict.auth.login.alternativeLogin.pionierid.title}
					<i className='icon-external' />
				</Button>}
				{ facebookLoginIsActive &&
				<Button
					variant='external'
					href={ `${apiURL}/oauth2/authorization/facebook?lang=${currentLang}&returnUrl=${returnURI}` }
				>
					{dict.auth.login.alternativeLogin.facebook.title}
					<i className='icon-external' />
				</Button>}
				{ googleLoginIsActive &&
				<Button
					variant='external'
					href={ `${apiURL}/oauth2/authorization/google?lang=${currentLang}&returnUrl=${returnURI}` }
				>
					{dict.auth.login.alternativeLogin.google.title}
					<i className='icon-external' />
				</Button>}
			</div>
		</div>
	);
};

export default LoginAlternate;
