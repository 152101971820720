import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams} from "react-router";
import { reverse } from 'named-urls';

import url from "router/urls";
import api from "services/api";

import { alertActions } from "store/actions/alert.actions";
import { appActions } from 'store/actions/app.actions';

import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";
import Alert from "views/elements/Alert/Alert";
import Error403 from "views/pages/Error/Error403";

import FormSubmit from "views/elements/Form/FormSubmit";
import FormInputFile from "views/elements/Form/FormInputFile";

const TranscriptionAddText = () => {
	const dict = useSelector((state) => state.app.dict);

	const dispatch = useDispatch();
	const { transcriptionId, pageId } = useParams();

	const [ title, setTitle ] = useState('');
	const [ viewMode, setViewMode ] = useState('read-only');

	useEffect(() => {
		getTranscription();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTranscription = () => {
		dispatch(appActions.setLoading(true));

		api.get(`/transcriptions/${transcriptionId}`)
			.then(({data}) => {
				setTitle(data.titleLabel);
				setViewMode(data.viewMode);
				dispatch(appActions.setLoading(false));
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	};

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: dict.breadcrumbs.transcriptions, link: url.transcriptions },
		{ id: 3, label: title, link: reverse(url.transcription.details, { transcriptionId: transcriptionId }) },
		
	];

	if (pageId) {
		breadcrumbsList.push(
			{ id: 4, label: `[#${pageId}]`, link: reverse(url.transcription.viewer, { transcriptionId: transcriptionId, pageId: pageId }) },
			{ id: 5, label: dict.breadcrumbs.addTextPage },
		);
	} else {
		breadcrumbsList.push(
			{ id: 4, label: dict.breadcrumbs.addTextPages },
		);
	}

	const { register, handleSubmit, errors, formState, setError, watch, reset } = useForm({
		mode: "onChange",
	});
    
	const inputFile = watch('file');

	const formats = pageId ? [
		'.hocr',
		'.tei',
		'.html',
		'.xml',
	] : [
		'.hocr',
		'.tei',
		'.html',
		'.xml',
		'.zip',
	];

	const onSubmit = data => {
		const formData = new FormData();
		formData.append('file', data.file[0]);
		dispatch(appActions.setLoading(true));

		const apiUrl = pageId ? `pages/${pageId}/content` : `/transcription/${transcriptionId}/content`;

		api.post(apiUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
			.then(() => {
				dispatch(appActions.setLoading(false));
				dispatch(
					alertActions.setAlert({
						type: "success",
						icon: "icon-accept",
						text: "form.messages.uploadSuccess",
						close: true,
					}),
				);
				reset();
			}).catch((response) => {
				dispatch(appActions.setLoading(false));
				dispatch(
					alertActions.setAlert({
						type: "danger",
						icon: "icon-circle-warning-empty",
						text: "form.messages.uploadFailed",
						close: true,
					}),
				);

				if (response.data && response.data.code) {
					switch (response.data.code) {
						case 'pages.error.wrongFileType': {
							const files = response.data.params;
							const message = files.length > 1 ? `${dict.form.messages['wrongFilesType']}: ${files.join(', ')}` : `${dict.form.messages['wrongFileType']}: ${files[0]}`;
							setError('file', 'wrongFilesType',  message);
							break;
						}
						case 'import.error.unrecognizablePage': {
							const files = response.data.params;
							const message = files.length > 1 ? `${dict.form.messages['unrecognizablePages']}: ${files.join(', ')}` : `${dict.form.messages['unrecognizablePage']}: ${files[0]}`;
							setError('file', 'unrecognizablePage',  message);
							break;
						}
						case 'import.error.wrongFileProvided': {
							setError('file', 'wrongFileProvided',  dict.form.messages['wrongFileProvided']);
							break;
						}
						case 'pages.not.equal.to.transcription.pages.exception': {
							setError('file', 'pagesNotEqual',  dict.form.messages['pagesNotEqual']);
							break;
						}
						default:
							return false;
					}
				}
				
			});
	};

	return (viewMode === 'admin' || viewMode === "verify") ? (
		<main>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<div className='block block--750'>
					<Alert />
					<div className='block__header'>
						<h1 className='text--title'>
							{ pageId ? dict.transcription.addText.titlePage : dict.transcription.addText.titlePages }
						</h1>
					</div>
					
					<div className='block__content'>
						<p className='paragraph__mb--35'>
							{ pageId ? dict.transcription.addText.descriptionPage : dict.transcription.addText.descriptionPages }
						</p>

						<form
							className='block__form block__form--inputfile'
							onSubmit={ handleSubmit(onSubmit) }
						>
							<div className='block__form--row'>
								<FormInputFile 
									dict={ dict }
									register={ register } 
									errorMessage={ errors.file && errors.file.message }
									formats={ formats }
									chooseFile={ inputFile && Object.values(inputFile).map((value) => { return value.name; }).toString() }
								/>
							</div>
							
							<div className='block__form--row block__form--buttons'>
								<Link 
									className='block__button--cancel' 
									to={ reverse(url.transcription.details, { transcriptionId: transcriptionId }) }
								>
									{dict.form.cancel}
								</Link>
								<FormSubmit
									label={ dict.form.inputFile.sendFile }
									className='block__button--submit'
									variant='submit'
									disabled={ !formState.isValid || ( inputFile && Object.keys(inputFile).length === 0) }
								/>
							</div>
						</form>
					</div>
				</div>
			</Container>
		</main>
	) : (
		<Error403 />
	);
};

export default TranscriptionAddText;