import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";

const CommonDropdown = ({ options, selectedOption = 0, setSelectedOption, placeholder, isDisabled, label, name }) => {
	const [ showPlaceholder, setShowPlaceholder ] = useState("");
	useEffect( () => {
		if(placeholder && placeholder.length > 0){
			setShowPlaceholder(placeholder);
		}
	}, [ placeholder ]);
    
	return (
		<>
			{ (label && label.length > 1) && (
				<label htmlFor={ name }>{label}</label>
			)}
			<Dropdown
				bsPrefix={ `dropdown-common ${ showPlaceholder && 'dropdown-common__placeholder'}` }
				drop='down'
			>
				<Dropdown.Toggle
					variant='dropdown'
					disabled={ isDisabled }
					id='common'
				>
					{ showPlaceholder ? showPlaceholder : options[selectedOption] }
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{ options.map((item, index) => (
						<Dropdown.Item
							onClick={ () => { 
								setShowPlaceholder('');
								setSelectedOption(index);
							} }
							className={ `${index === selectedOption ? "dropdown-item__active" : ""}` }
							key={ `${item}-${index+1}` }
						>
							{ (index === selectedOption) && <i className='icon-accept' /> }
							{ item }
						</Dropdown.Item>
					))}
					
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

CommonDropdown.propTypes = {
	options: PropTypes.array,
	selectedOption: PropTypes.number,
	setSelectedOption: PropTypes.func,
	placeholder: PropTypes.string, 
	isDisabled: PropTypes.bool,
	label: PropTypes.string,
};

export default CommonDropdown;