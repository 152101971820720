const pl = {
	headers: {
		logoAlt: "WLT Logo",
		logoDariahAlt: "Dariah Lab logo",
		menuTitle: "Menu",
		title: "Co to jest Wirtualne Laboratorium Transkrypcji?",
		description:
			"Wirtualne Laboratorium Transkrypcji jest narzędziem, które pozwala tworzyć cyfrowy tekst na podstawie skanów. Wyniki możesz wykorzystać do przygotowania obiektu cyfrowego w takich standardach jak: hOCR, TEI, PDF, eBook. Narzędzie pozwala na transkypcję obiektów importowanych z Bibliotek Cyfrowych, także z wykorzystaniem standardu IIIF.",
		newTranscription: "Nowa transkrypcja",
		help: "Jak transkrybować?",
		contrast: "Kontrast",
		font: "Czcionka",
		eu: "UE Logo",
		menu: {
			transcriptions: "Transkrypcje",
			allTranscriptions: "Wszystkie transkrypcje",
			myTranscriptions: "Moje transkrypcje",
			logIn: "Zaloguj się",
			noAccount: "Nie masz konta? ",
			createAccount: "Załóż je",
			myAccount: "Moje konto",
			myProfile: "Mój profil",
			logOut: "Wyloguj się",
		},
	},
	footer: {
		links: {
			privacyPolicy: "Polityka prywatności",
			contact: "Kontakt",
			shareYourOpinion: "Podziel się opinią o WLT",
		},
		rights: {
			copyright: "Copyright",
			wlt: "Wirtualne Labolatorium Transkrypcji",
			createdBy: "zostało stworzone przez ",
			pcss: {
				title: "PCSS",
				url: "http://www.man.poznan.pl",
			},
		},
	},
	financed: {
		text:
			"Projekt pt. ”Leopoldina online – platforma integracji i udostępniania elektronicznych zasobów Uniwersytetu Wrocławskiego dla nauki, edukacji i popularyzacji wiedzy” współfinansowany przez Unię Europejską z Europejskiego Funduszu Rozwoju Regionalnego.",
		logos: {
			FE: "Fundusze Europejskie Polska Cyfrowa",
			UWr: "Uniwersytet Wrocławski",
			UE: "Unia Europejska - Europejski Fundusz Rozwoju Regionalnego",
		},
	},
	dariahlab: {
		text: 
			"WLT jest częścią infrastruktury Dariah.lab stworzonej w ramach projektu DARIAH-PL",
		eu: "EU Partnerzy Logos",
		euMobile: "EU Partnerzy Logos Mobile",
	},
	form: {
		fieldsRequired: 'Wszystkie pola są wymagane do uzupełnienia',
		cancel: 'Anuluj',
		change: 'Zmień',
		email: 'E-mail',
		password: 'Hasło',
		currentPassword: 'Dotychczasowe hasło',
		newPassword: 'Nowe hasło',
		repeatedNewPassword: 'Powtórz nowe hasło',
		repeatedPassword: 'Powtórz hasło',
		rememberMe: 'Zapamiętaj mnie',
		forgotPass: 'Nie pamiętam hasła',
		signUp: 'Załóż konto',
		signIn: 'Zaloguj się',
		createNewTranscription: 'Utwórz nową transkrypcję',
		saveTranscription: 'Zapisz transkrypcję',
		passwordTip: 'Przy ustalaniu hasła pamiętaj, że prawidłowe hasło powinno zawierać: wielką literę, małą literę, cyfrę lub znak specjalny oraz składać się z min. 8 znaków',
		delete: 'Usuń',
		inputFile: {
			selectFile: 'Wybierz plik',
			selectFiles: 'Wybierz pliki',
			sendFile: "Prześlij plik",
		},
		messages: {
			invalidEmail: "Niepoprawny adres e-mail",
			correctEmail: "Prawidłowo podany adres e-mail",
			invalidUrl: "Niepoprawny adres pliku",
			emptyUrl: "Pole url nie może być puste",
			emptyEmail: "Pole e-mail nie może być puste",
			duplicateEmail: "Ten adres e-mail już istnieje, proszę wpisać inny lub wybrać przypomnienie hasła.",
			emailNotExists: "Ten adres e-mail nie figuruje w naszej bazie danych. Sprawdź poprawność tego adresu.",
			emailExistsInGroup: "Ten użytkownik jest już członkiem tej grupy.",
			incorrectPassword:
				"Prawidłowe hasło powinno zawierać: wielką literę, małą literę, cyfrę lub znak specjalny oraz składać się z min. 8 znaków",
			invalidPassword: "Wprowadzono niepoprawne hasło",
			correctPassword: "Prawidłowo podane hasło",
			fillPassword: "Pole hasła nie może być puste",
			privatePolicy: "Musisz zaakceptować politykę prywatności",
			confirmPassword: "Hasła muszą być zgodne",
			nonExistingEmail: "Adres email nie został odnaleziony",
			error: "Wystąpił błąd",
			notAcceptedRegulations: "Musisz zaakceptować regulamin",
			passwordChangeFailure: "Zmiana hasła nie powiodła się",
			differentPasswords: "Hasła muszą być zgodne",
			requiredField: "To pole jest wymagane",
			wrongFileType: "Niepoprawny format pliku",
			wrongFilesType: "Niepoprawne formaty plików",
			wrongFileProvided: "Przesłany plik jest niewłaściwy",
			uploadSuccess: "Plik został przesłany prawidłowo.",
			uploadFailed: "Plik nie został przesłany. Spróbuj ponownie.",
			transcriptionUserAlreadyExists: 'Użytkownik posiada już dostęp do tej transkrypcji',
			noUserWithGivenEmail: 'Nie znaleziono użytkownika z podanym adresem e-mail',
			invalidRange: "Podany zakres jest nieprawidłowy.",
			minumumOnetitle: "Wymagane jest podanie przynajmniej jednego tytułu",
			unrecognizablePage: "Nie można rozpoznać następującej strony",
			unrecognizablePages: "Nie można rozpoznać następujących stron",
			fileTooLarge: "Plik, który próbujesz przesłać przekroczył wyznaczony limit 100MB. Spróbuj zmniejszyć plik i prześlij go ponownie.",
			quotaExceeded: "Plik nie został przesłany. Wykorzystałeś limit MB dostępny dla Twojego konta. Sprawdź limit na stronie 'Mój profil'.",
			emptySubject: "Pole temat nie może być puste",
			emptyMessage: "Pole treść nie może być puste",
			downloadFailed: "Pobieranie pliku nie powiodło się.",
			filesAlreadyExist: "Pliki już istnieją",
			fileAlreadyExists: "Plik już istnieje",
			pagesNotEqual: "Niezgodna ilość stron transkrypcji z ilością stron w pliku.",
		},
	},
	auth: {
		login: {
			title: "Logowanie",
			alternativeLoginHead: "Logowanie alternatywne",
			alternativeLoginSubHead1: "Logując się za pomocą poniższych systemów akceptujesz",
			alternativeLoginSubHead2: "Politykę prywatności",
			alternativeLoginSubHead3: "WLT.",
			alternativeLogin: {
				uwrad: {
					title: "Active Directory",
				},
				pionierid: {
					title: "Pionier ID",
				},
				facebook: {
					title: "Facebook",
				},
				google: {
					title: "Google",
				},
			},
			isNotValid: "Nieprawidłowy e-mail lub hasło. Spróbuj jeszcze raz lub kliknij „Nie pamiętam hasła”, by je zresetować.",
			isValid: "Zalogowałeś się poprawnie. Możesz teraz korzystać z serwisu.",
			isTokenValid: "Twoje konto zostało założone.<br>Możesz teraz zalogować się.",
			isTokenNotValid: "Link aktywacji jest już nieaktywny. Spróbuj założyć konto jeszcze raz.",
			signOut: "Zostałes poprawnie wylogowany.",
		},
		register: {
			title: "Rejestracja",
			acceptedRegulations: {
				t1: "Zapoznałem się z ",
				link: "Polityką prywatności",
				t2: " i akceptuję jej treść.",
			},
			info:
				"Po utworzeniu konta na podany adres e-mail zostanie wysłana wiadomość z odnośnikiem do aktywacji konta.",
			emailSent: {
				head: "Dziękujemy za rejestrację konta",
				text: "Na podany adres e-mail przyjdzie wiadomość zawierająca link aktywacyjny Twojego konta w Wirtualnym Laboratorium Transkrypcji.",
				info: "Kliknij link zawarty w e-mailu, by zakończyć rejestrację.",
				backToHome: "Przejdź do strony głównej",
			},
		},
		account: {
			title: "Mój profil",
			email: "E-mail",
			contactEmail: "Kontakt e-mail",
			password: "Hasło",
			changeEmail: "Zmień e-mail",
			changePassword: "Zmień hasło",
			passwordChange: "Zmiana hasła",
			myGroup: "Moje grupy",
			createNewGroup: "Utwórz nową grupę",
			groupAdministrator: "Grupy, w których jestem administratorem",
			groupEdit: "Edytuj",
			groupRest: "Pozostałe grupy, do których należę",
			groupCreator: "Twórca",
			groupBlank: "Nie jesteś obecnie przypisany do żadnych grup.",
			function: "Funkcja",
			adminConsole: "Konsola administracyjna",
			passwordChangeAlert: "Hasło zostało zmienione",
			diskSpace: "Miejsce na dysku",
			increase: "Poproś o zwiększenie",
			mb: "MB",
		},
		remind: {
			title: "Nie pamiętam hasła",
			instruction:
				"By ustalić nowe hasło wpisz swój adres e-mail. Na podany adres zostanie wysłana wiadomość zawierająca link aktywacyjny do zmiany hasła. Należy kliknąć w ten link i ustawić nowe hasło.",
			send: "Wyślij",
			cancel: "Anuluj",
			emailSent: "Na podany adres e-mail wysłano link aktywacyjny.",
			error: "Zmiana hasła nie powiodła się",
			changeMessage: "Wiadomość o zmianie hasła",
			changedDescription:
				"Na podany w formularzu adres e-mail przyjdzie wiadomość zawierająca link aktywacyjny uprawniający do zmiany hasła.",
			backToHome: "Przejdź do strony głównej",
		},
		passwordChange: {
			title: "Zmiana hasła",
			warning: "Wszystkie pola są wymagane",
			instruction:
				"Prawidłowe hasło powinno zawierać: wielką literę, małą literę, cyfrę lub znak specjalny oraz składać się z min. 8 znaków",
			newPassword: "Nowe hasło",
			repeat: "Powtórz nowe hasło",
			error: "Nie udało się zmienić hasła",
			success: "Hasło zostało pomyślnie zmienione. Możesz teraz się zalogować.",
		},
	},
	admin: {
		dashboard: {
			title: "Konsola administracyjna",
			description: "Zarządzanie użytkownikami Wirtualnego Laboratorium Transkrypcji",
			listDescription: "Lista użytkowników Wirtualnego Laboratorium Transkrypcji",
			status: 'Status',
			active: 'Aktywny',
			disabled: 'Nieaktywny',
			superadministrator: 'Superadministrator',
			transcriptions: 'Transkrypcje',
			searchPlaceholder: "Wyszukaj użytkownika",
			searchTranscriptionPlaceholder: "Wyszukaj transkrypcje",
			noMatch: "Brak użytkowników spełniających kryteria wyszukiwania",
			limit: "Limit:",
			used: "Użyto: ",
		},
		userTranscriptions: {
			description: 'Zarządzanie obiektami użytkownika WLT ',
			descriptionComment: '(Zarządzanie obiektami użytkownika przysługuje tylko osobie z przypisaną funkcją Superadministratora).',
			listDescription: 'Lista transkrypcji użytkownika ',
			noTranscriptions: 'Brak wyników odpowiadających zapytaniu',
			open: 'Zobacz',
			delete: 'Usuń',
		},
	},
	group: {
		headerGroup: "Grupa",
		deleteGroup: "Usuń grupę",
		groupName: "Nazwa grupy:",
		groupNameInputPlaceholder: "Wpisz nazwę grupy",
		groupNameExists: "Grupa o podanej nazwie już istnieje.",
		groupUserRole: "Rola:",
		groupUserEmail: "E-mail:",
		removeUser: "Usuń",
		addUserHead: "Dodaj użytkownika",
		addUserText: "Podaj e-mail użytkownika, któremu chcesz dać dostęp do obiektu:",
		addButton: "Dodaj",
		addUserPlaceholder: 'kowalski@poczta.pl',
	},
	roles: {
		admin: "Administrator",
		transcribent: "Transkrybent",
		verifier: "Weryfikator",
		third: "Opcja 3",
		changeEmail: "Zmień e-mail",
		changePassword: "Zmień hasło",
		passwordChange: "Zmiana hasła",
		myGroup: "Moje grupy",
		createNewGroup: "Utwórz nową grupę",
		groupAdministrator: "Grupy, w których jestem administratorem",
		groupEdit: "Edytuj",
		groupRest: "Pozostałe grupy, do których należę",
		groupCreator: "Twórca",
		groupBlank: "Nie jesteś obecnie przypisany do żadnych grup.",
		function: "Funkcja",
		adminConsole: "Konsola administracyjna",
		passwordChangeInfo:
			"Po utworzeniu konta na podany adres e-mail zostanie wysłana wiadomość z odnośnikiem do aktywacji konta.",
		passwordChangeAlert: "Hasło zostało zmienione",
	},
	actorRoles: {
		author: 'Autor',
		editor: 'Redaktor',
		translator: 'Tłumacz',
		copyist: 'Kopista',
		publisher: 'Publicysta',
		contributor: 'Współautor',
	},
	languages: {
		pol: "Polski",
		eng: "Angielski",
		deu: "Niemiecki",
		fra: "Francuski",
		lat: "Łotewski",
		rus: "Rosyjski",
	},
	types: {
		print: "Druk",
		manuscript: "Rękopis",
	},
	transcriptions: {
		header: {
			title: 'Transkrypcje',
			titleMy: 'Moje transkrypcje',
			descr: 'Lista transkrybowanych obiektów cyfrowych udostępnionych przez użytkowników WLT.',
			login: 'Zaloguj się, aby zobaczyć wszystkie ',
			myTranscriptionsLink: 'Moje transkrypcje.',
			createNew: "Utwórz nową transkrypcję",
		},
		search: {
			placeholder: 'Przeszukaj transkrybowane obiekty',
			searchingPhrase: 'Wyszukiwana fraza: ',
			searchBt: 'Szukaj',
			noTranscriptionsFound: "Brak transkrypcji spełniających podane kryteria.",
			userHasNoTranscriptions: "Nie masz jeszcze żadnych transkrypcji.",
		},
		sort: {
			publicationDate: "Sortuj według daty publikacji transkrypcji",
			editionDate: "Sortuj według daty edycji transkrypcji",
			sortTitles: "Sortuj według tytułu A-Z",
		},
		item: {
			transcriptionAdvancementFull: 'Zaawansowanie transkrypcji',
			transcriptionAdvancementShort: 'Zaawansowanie',
			fullOCR: '100% OCR',
		},
		dropdown: {
			btn: 'Akcje',
			transcribe: "Transkrybuj",
			loadText: "Załaduj tekst",
			hocrDownload: "Pobierz hOCR",
			textDownload: "Pobierz tekst",
			teiDownload: "Pobierz TEI z koordynatami",
			deletePage: "Usuń stronę",
		},
		description: {
			title: "Tytuł: ",
			author: "Autor: ",
			lastEdited: "Ostatnio edytowany: ",
			files: "Plików: ",
			transcriptionAdvance: "Zaawansowanie transkrypcji: ",
			verificationAdvance: "Zaawansowanie weryfikacji: ",
			showMore: "Zobacz więcej",
		},
	},
	staticPage: {
		contact: "Kontakt",
		privacyPolicy: "Polityka prywatności",
	},
	transcriptionHelpPage: {
		title: "Jak transkrybować?",
		readOn: "Czytaj dalej",
		howToStart: {
			title: "Jak zacząć transkrypcję?",
			desc: "Aby transkrybować strony w Wirtualnym Laboratorium Transkrypcji należy:",
			step: {
				step1title: "Założyć konto na stronie WLT ",
				step1desc: "Po rejestracji na adres mailowy podany w formularzu zostanie wysłana wiadomość zawierająca link do aktywacji konta. Należy na niego kliknąć lub skopiować go do paska adresowego przeglądarki. Link aktywacyjny jest ważny przez ograniczony dostęp czasu.",
				step1link: "Załóż konto",
				step2title: "Po aktywacji konta ",
				step2link: "Zaloguj się",
				step3title: "Aby rozpocząć transkrypcję należy w menu głównym wybrać opcję ",
				step3desc: "Należy wpisać kolejno dane lub zaimportować opis obiektu wraz z plikami z zewnętrznego źródła. Po wpisaniu danych należy zaakceptować regulamin i zatwierdzić zakończenie tworzenia opisu transkrypcji klikając przycisk Utwórz nową transkrypcję.",
				step3link: "Nowa transkrypcja",
				step4title: "Wprowadź opis nowej transkrypcji, zaakceptuj regulamin a następnie kliknij przycisk „Utwórz nową transkrypcję”",
				step4desc: "Po kliknięciu „Utwórz nową transkrypcję” na ekranie pojawi się napis o powodzeniu utworzenia nowej transkrypcji. Jesteś teraz automatycznie na stronie z nową transkrypcją i możesz dodać strony tej transkrypcji przeznaczone do transkrybowania.",
				step5title: "Nowa transkrypcja pojawi się od razu na ekranie. Teraz możesz dodawać strony do transkrybowania.",
				step5desc: "Każda utworzona Twoja transkrypcja znajduje się w zakładce menu „Transkrypcje” / „Moje transkrypcje”. By móc przeglądać stronę „Moje transkrypcje” należy być zalogowanym. Domyślnie nowo utworzona transkrypcja jest niedostępna dla innych użytkowników WLT, zarówno tych zalogowanych jak i niezalogowanych.",
				step6title: "Jeśli chcesz by Twoja transkrypcja była widoczna dla innych użytkowników, wejdź na zakładkę Uprawnienia znajdującą się na stronie Moja transkrypcja",
				step7title: "Po dodaniu stron transkrypcji możesz zacząć automatyczną transkrypcję wszystkich dodanych stron lub możesz transkrybować każdy plik z osobna.",
				step7desc: "Jeżeli chcesz osobno transkrybować automatycznie każdą stronę kliknij najpierw w płytkę strony. Otworzy się nowe okno do transkrypcji wybranej przez Ciebie strony tam jedną z opcji jest „Automatyczna transkrypcja” widocznej strony. Opcja Transkrybuj automatycznie jest jednym z najważniejszych narzędzi dostępnych w WLT. Inaczej nazywana jest ona OCR (Optical Character Recognition) czyli optycznego rozpoznawania znaków na skanie dokumentu. Można powiedzieć, że OCR wyciąga tekst z obrazu.",
				step8title: "Kliknij wybraną stronę i przejdź do okna transkrypcji.",
				readMore: "Po utworzeniu nowej transkrypcji i dodaniu do niej stron do transkrybowania możesz rozpocząć pracę z plikiem. W tym celu kliknij w wybraną stronę. Wybrana strona pojawi się w nowym oknie z wszystkim potrzebnymi narzędziami do transkrybowania pliku strony. Jeśli chcesz wiedzieć w jaki sposób pracować z plikiem na stronie transkrypcji czytaj dalej ",
			},
		},
		howToTranscribe: {
			title: "Jak transkrybować?",
			desc: "Opis narzędzi i sposobu pracy z plikiem na stronie okna transkrypcji.",
			section: {
				workWithFile: "Praca z plikiem.",
				workOnWindow: "Praca na oknie z obiektem transkrybowanym",
				workOnWindowDescription: "Opis działania narzędzi okna obiektu transkrybowanego",
				workWithWindow: "Praca z oknem transkrypcji",
				workWithWindowDescription: "Kliknij na linijkę wiersza, który chcesz transkrybować. Wiersz podświetli się. Niebieska ramka edytowanego wiersza pojawi się zarówno na widoku obiektu transkybowanego jak i na widoku transkrypcji. Dzięki temu będziesz mógł widzieć na obiekcie transkrybowanym, który wiersz strony edytujesz.",
			},
			steps: {
				step1title: "Automatyczna transkrypcja.",
				step1desc: "Automatyczna transkrypcja nie tylko tłumaczy na nowo wszystkie wersy i akapity strony. Możesz automatycznie transkrybować tylko wersy, które jeszcze nie posiadają transkrypcji. Takie ustawienia możesz zdefiniować po kliknięciu w przycisk „Transkrybuj automatycznie”.",
				step2title: "Zwijanie i rozwijanie okien",
				step2desc: "Możesz zwinąć okno transkrypcji i mieć podgląd na całą stronę obiektu transkrybowanego lub odwrotnie. W tym celu kliknij przycisk „Zwiń”, bo ponownie zobaczyć oba okna kliknij „Rozwiń”.",
				step3title: "Zamień stronami",
				step3desc: "Ta funkcja pozwala zamienić stronami transkrypcję z obiektem transkrybowanym.",
				step4title: "Poprawna transkrypcja",
				step4desc: "Jeśli wszystkie wersy uważasz za transkrybowane poprawnie nie musisz klikać oddzielnie każdego wiersza, by zaznaczyć, że cała transkrypcja jest poprawna. Wystarczy w tym celu kliknąć przycisk „Poprawna transkrypcja” a wszystkie wersy zostaną z prawej strony oznaczone jako poprawnie transkrybowane.",
				step5title: "Pobieranie pliku",
				step5desc: "Każdy plik można zapisać już po transkrypcji pierwszego wersu w postaci hOCR, tekstu, czy formatu TEI z koordynatami.",
				step6title: "Przełączanie stron",
				step6desc: "W tym samym obiekcie możesz swobodnie poruszać się pomiędzy stronami obiektu, który transkrybujesz.",
			},
			icons: {
				addTranscriptionArea: 'Dodaj obszar transkrypcji',
				addTranscriptionAreaInfo: 'Narzędzie to służy do samodzielnego zaznaczania dodatkowych wierszy lub zaznaczania całych akapitów.',
				zoomIn: 'Przybliż',
				zoomOut: 'Oddal',
				fullScreen: 'Powiększ na cały ekran',
				fitToWindow: 'Dopasuj do szerokości okna',
				workWithTranscriptionWindow: {
					italic: 'Kursywa',
					bold: 'Pogrubienie',
					symbol: 'Znaki specjalne',
					quote: 'Koniec paragrafu',
					listElementEnd: 'Koniec elementu listy',
					listEnd: 'Koniec listy',
					page: 'Nagłowek, stopka, numer strony',
					pageHeader: 'Nagłowek',
					pageFooter: 'Stopka',
					pageNumber: 'Numer strony',
					header: 'Tytuł',
					header1: 'Koniec tytułu I stopnia',
					header2: 'Koniec tytułu II stopnia',
					header3: 'Koniec tytułu III stopnia',
					image: 'Ilustracja',
					imageDescription: 'Podpis pod ilustracją',
					table: 'Tabela',
					tableCellEnd: 'Koniec komórki',
					tableVerseEnd: 'Koniec wiersza',
					tableEnd: 'Koniec tabeli',
					verseAutoTranscription: 'Transkrybuj automatycznie zaznaczony wiersz',
					verseComment: 'Dodaj komentarz do zaznaczonego wiersza',
					verseDelete: 'Usuń cały wiersz',
					verseClear: 'Wyczyść tekst w polu wiersza',
					verseUp: 'Przesuń wiersz w górę',
					verseDown: 'Przesuń wiersz w dół',
				},
			},
		},
		howToVerify: {
			title: "Jak weryfikować?",
			desc: "Zadaniem weryfikatora transkrypcji jest sprawdzenie transkrypcji pod względem poprawności i jakości tekstu.",
			section: {
				howToAssignRole: "Jak przypisać użytkownikowi rolę weryfikatora?",
				howToStart: "Jak zacząć weryfikację?",
				verification: "Weryfikacja",
				verificationDescription1: "Narzędzia weryfikatora w oknie transkrypcji. Weryfikator posiada wszystkie narzędzia jakie mają transkrybenci i twórcy w oknie transkrypcji. Dodatkowo weryfikator ma więcej możliwości przez oznaczanie poprawności weryfikacji wiersza. Oznaczenie poprawności transkrypcji przez transkrybenta:",
				verificationDescription2: "Jak zaznaczać poprawnie zweryfikowany wiersz?",
				verificationDescription3: "Po zweryfikowaniu transkrypcji transkrybent nie będzie miał już praw do edytowania transkrybowanej treści. Taką funkcjonalnośc będzie posiadał tylko twórca i weryfikator.",
			},
			assignRolesSteps: {
				step1: "Weryfikator transkrypcji jest wyznaczony przez jej twórcę. Twórca może sam weryfikować swoją transkrypcję lub przypisać rolę weryfikatora osobie, której udostępnił obiekt. Takie uprawnienia twórca ustawia w zakładce Uprawnienia na stronie Moja transkrypcja.",
				step2: "Przypisanie roli weryfikatora. Użytkownicy, którzy mają dostęp do obiektu domyślnie mają przypisaną rolę transkrybenta, to oznacza, że domyślnie mają jedynie dostęp do obiektu twórcy. By wybrana osoba mogła weryfikować obiekt, trzeba zmienić jej rolę na Weryfikator.",
			},
			startVeryficationSteps: {
				step1: "By weryfikować kliknij w zakładkę Weryfikacja transkrypcji znajdującej się na stronie Moja transkrypcja.",
				step2: "Z listy wybierz stronę, którą chciałbyś weryfikować i kliknij przycisk Weryfikuj. Otworzy Ci się okno transkrypcji, na której będziesz mógł weryfikować transkrybowany tekst.",
			},
			veryficationIcons: {
				correct: "Wiersz oznaczony przez transkrybenta jako poprawnie transkrybowany.",
				incorrect: "Wiersz oznaczony przez transkrybenta jako błędnie transkrybowany lub taki, którego transkrypcji nie potrafił dokończyć.",
				untranscribed: "Wiersz nietranskrybowany lub niezweryfikowany przez transkrybenta.",
			},
			selectionIcons: {
				firstClick: "<strong>Pierwszy klik</strong> weryfikatora będzie zaznaczał poprawność transkrypcji niezależnie od tego, czy transkrybent oznaczył wiersza jako poprawny lub błędny. Weryfikator błędny wiersz może uznać za poprawnie transkrybowany.",
				secondClick: "<strong>Drugi klik</strong> weryfikatora pokaże, że transkrypcja wiersza jest błędna.",
				thirdClick: "<strong>Trzeci klik</strong> weryfikatora pokaże wraca do stanu zaznaczenia przez transkrybenta przed weryfikacją.",
			},
		},
	},
	functionList: {
		SUPER_ADMIN: "Superadministrator",
		ADMIN: "Administrator",
		USER: "Użytkownik",
	},
	cookies: {
		text: "Używamy plików cookies, by ułatwić korzystanie z naszego serwisu. Sprawdź informacje o plikach ",
		link: "Cookies i Polityce prywatności",
		agree: "Rozumiem",
	},
	pagination: {
		next: "Następna",
		previous: "Poprzednia",
		of: " z ",
	},
	breadcrumbs: {
		account: 'Mój profil',
		accountMyProfile: 'Moje konto - Mój profil',
		group: 'Grupa',
		transcriptions: 'Transkrypcje',
		addPages: 'Dodaj nowe pliki',
		addTextPage: 'Załaduj tekst strony',
		addTextPages: 'Załaduj tekst stron',
		transcriptionImport: 'Import transkrypcji',
		transcriptionEdit: 'Edycja transkrypcji',
		transcriptionNew: 'Nowa transkrypcja',
		transcriptionsUser: 'Moje transkrypcje',
		adminConsole: 'Konsola administracyjna',
		adminUserTranscriptions: 'Transkrypcje użytkownika',
	},
	viewer: {
		header: {
			transcriptionObject: "Transkrybowanie obiektu",
			pages: {
				next: "Następna",
				prev: "Poprzednia",
				page: "strona",
			},
			swapPanels: "Zamień stronami",
			correctTranscription: "Poprawna transkrypcja",
			emptyTranscription: "Oznacz stronę jako pustą",
			autoTranscribe: "Transkrybuj automatycznie",
			download: "Pobierz",
			downloadhOCR: "Pobierz hOCR",
			downloadText: "Pobierz tekst",
			downloadTEI: "Pobierz TEI z koordynatami",
		},
		panels: {
			fold: "Zwiń",
			expand: "Rozwiń",
			title: {
				osd: "Obiekt transkrybowany",
				editor: "Transkrypcja",
				student: "Nauka",
			},
			selection: {
				edit: "Edytuj zaznaczenie",
				save: "Zakończ edycję",
			},
		},
		verse: {
			info: {
				delete: "Usuń",
			},
		},
		tooltip: {
			header: {
				correctTranscription: "Zaznacz wszystkie wiersze jako poprawnie transkrybowane",
				emptyTranscription: "Oznacz stronę jako pustą",
				downloadTooltip: "Pobierz widoczną stronę w wybranym formacie (hOCR, Tekst, TEI z koordynatami)",
				homeBt: "Przejdź na stronę główną Wirtualnego Laboratorium Transkrypcji",
				prevPage: "Przejdź do poprzedniej strony tego obiektu transkrypcji",
				nextPage: "Przejdź do następnej strony tego obiektu transkrypcji",
				switchBt: "Zamiana stronami prezentowanych stron Transkrypcji i Obiektu Transkrybowanego",
				autoBt: "Automatyczna transkrypcja widocznej strony. Dotychczasowe zmiany w formularzu nie zostaną zachowane",
				collapseObject: "Zwiń widoczny obszar Obiektu transkrybowanego",
				collapseTranscription: "Zwiń widoczny obszar Transkrypcji",
				expandObject: "Rozwiń obszar Obiektu transkrybowanego",
				expandTranscription: "Rozwiń obszar Transkrypcji",
			},
			osdMenu: {
				addArea: "Dodaj obszar transkrypcji",
				zoomIn: "Przybliż",
				zoomOut: "Oddal",
				fullScreen: "Wyświetl na cały ekran",
				fitToPage: "Dopasuj do wysokości okna",
				editArea: "Rozpocznij edycję zaznaczonego obszaru",
			},
			status: {
				click1: "Kliknij raz, by zatwierdzić wiersz jako poprawnie transkrybowany.",
				click2: "Kliknij drugi raz, by oznaczyć wiersz jako niepoprawnie transkrybowany.",
				click3: "Kliknij trzeci raz, by oznaczyć wiersz jako niezweryfikowany.",
			},
			menu: {
				style: {
					BOLD: 'Pogrubienie',
					ITALIC: 'Kursywa',
				},
				charmap: "Znaki specjalne",
				endOfParagraph: "Koniec paragrafu",
				endOfListItem: "Koniec elementu listy",
				endOfList: "Koniec listy",
				list: "Lista",
				pageDescription: "Nagłówek, Stopka, Numer strony",
				verseHeader: "Nagłówek",
				verseFooter: "Stopka",
				pageNumber: "Numer strony",
				titleH: "Tytuł",
				titleH1: "Koniec tytułu I stopnia",
				titleH2: "Koniec tytułu II stopnia",
				titleH3: "Koniec tytułu III stopnia",
				image: "Ilustracja",
				imageCaption: "Podpis pod ilustracją",
				table: "Tabela",
				endOfCell: "Koniec komórki",
				endOfRow: "Koniec wiersza",
				endOfTable: "Koniec tabeli",
				verseAutoTranscription: "Transkrybuj automatycznie zaznaczony wiersz",
				addComment: "Dodaj komentarz do zaznaczonego wiersza",
				verseDelete: "Usuń cały wiersz",
				versesDelete: "Usuń wiele wierszy",
				deleting: "Usuwanie",
				verseClear: "Wyczyść tekst w polu wiersza",
				verseUp: "Przesuń wiersz w górę",
				verseDown: "Przesuń wiersz w dół",
			},
			info: {
				header: "Nagłowek",
				footer: "Stopka",
				pageNo: "Numer strony",
				titleH1: "Koniec tytułu I stopnia",
				titleH2: "Koniec tytułu II stopnia",
				titleH3: "Koniec tytuł III stopnia",
				endOfParagraph: "Zakończenie paragrafu",
				endOfListItem: "Zakończenie elementu listy",
				endOfList: "Zakończenie listy",
				endOfCell: "Koniec komórki",
				endOfRow: "Koniec wiersza",
				endOfTable: "Koniec tabeli",
				imageCaption: "Podpis pod ilustracją",
				image: "Ilustracja",
			},
		},
	},
	error: {
		error404: {
			title: "Błąd 404",
			description: "Przepraszamy, strona której szukasz, nie została odnaleziona.",
			backText: 'Wróć do ',
			backLinkText: 'poprzedniej strony',
			homeText: 'Przejdź do ',
			homeLinkText: 'strony głównej',
		},
		error403: {
			title: "Brak dostępu",
			description: "Nie masz uprawnień do tej strony.",
			backText: 'Wróć do ',
			backLinkText: 'poprzedniej strony',
			homeText: 'Przejdź do ',
			homeLinkText: 'strony głównej',
		},
		error500: {
			title: "Błąd 500",
			description: "Przepraszamy, ale chwilowo nie możemy wyświetlić tej strony, ze względu na wewnętrzny błąd serwera.",
			reloadLinkText: 'Odśwież stronę',
		},
	},
	modals: {
		viewerResize: {
			title: "Transkrybowanie pliku",
			body: "<p>Niestety w tym momencie Twoje urządzenie nie spełnia potrzebnych wymagań, by korzystać z wszystkich narzędzi transkrypcji.</p><p>Wszystkie funkcje transkrypcji dostępne są na rozdzielczości powyżej 1200px. Poniżej tej rozdzielczości dostępna jest tylko funkcja automatycznej transkrypcji. </p><p>By móc edytować wiersze zmień lub obróć urządzenie, tak by uzyskać odpowiednią rozdzielczość ekranu.</p>",
			button: "Rozumiem",
		},
		pastedTooLongText: {
			title: "Wklejony tekst jest zbyt długi",
			body: "<p>Wklejony tekst jest zbyt długi. Jego długość została ograniczona do 4000 znaków.</p>",
			button: "Rozumiem",
		},
		autoTranscribeVerse: {
			title: "Transkrybuj wiersz automatycznie",
			bodyHTML: "Automatyczna transkrypcja wiersza wyczyści aktualny tekst.<br>Czy jesteś pewny, że chcesz ponownie transkrybować wiersz?",
			select: {
				title: "Profil rozpoznania",
				default: "Wybierz język...",
			},
			button: "Rozpocznij",
		},
		autoTranscribePage: {
			title: "Transkrybuj stronę automatycznie",
			body: "Funkcja ta tworzy automatyczną transkrypcję wybranej strony",
			select: {
				titleProfile: "Profil rozpoznania",
				titleRange: "Zakres rozpoznania",
				default: "Wybierz język...",
				ocrMode: {
					update: "Uzupełnij istniejącą stronę transkrypcji",
					override: "Nadpisz istniejącą stronę transkrypcji",
				},
			},
			button: "Rozpocznij",
		},
		autoTranscribePages: {
			title: "Transkrybuj strony automatycznie",
			body: "Funkcja ta tworzy automatyczną transkrypcję stron w podanym zakresie",
			radio: {
				pagesAll: "Wszystkie strony",
				pagesRange: "Strony z zakresu",
			},
			input: {
				placeholder: "1,2-6,8",
				label: "Numery stron i/lub zakresy stron oddziel przecinkami",
			},
			select: {
				titleProfile: "Profil rozpoznania",
				titleRange: "Zakres rozpoznania",
				default: "Wybierz język...",
				ocrMode: {
					emptyonly: "Tylko pliki bez transkrypcji",
					update: "Uzupełnij istniejącą transkrypcję",
					override: "Nadpisz istniejącą transkrypcję",
				},
			},
			button: "Rozpocznij",
			notice: "Czas trwania transkrypcji zależy od ilości stron przeznaczonych do transkrypcji. W przypadku wielostronicowych obiektów może to zająć nawet kilka godzin. Jeśli nie możesz poświęcić takiej ilości czasu zalecamy rozpocząć transkrypcję od pojedynczych stron.",
		},
		deleteTranscription: {
			title: "Usuń transkrypcję",
			bodyHTML: "Ta funkcja usuwa cały obiekt transkrypcji, zarówno opis, jak i wszystkie pliki.<br>Czy jesteś pewny, że chcesz kontynuować?",
			button: "Usuń",
		},
		deleteTranscriptionPage: {
			title: "Usuń stronę transkrypcji",
			body: "Czy jesteś pewien, że chcesz usunąć stronę transkrypcji?",
			button: "Usuń",
		},
		groupDeleteUser: {
			title: "Usunięcie użytkownika",
			bodyHTML: "Ta funkcja usunie użytkownika z grupy.<br> Czy jesteś pewny, że chcesz kontynuować?",
			button: "Usuń użytkownika",
		},
		groupDeleteGroup: {
			title: "Usuwanie grupy",
			bodyHTML: "Ta funkcja usuwa całą grupę i przywrócenie jej będzie niemożliwe.<br> Czy jesteś pewny, że chcesz kontynuować?",
			button: "Usuń grupę",
		},
		permissionsDeleteGroup: {
			title: "Usuń grupę.",
			bodyHTML: "Usunięcie grupy spowoduje, że obiekt dla tej grupy nie będzie już dostępny.",
			button: "Usuń",
		},
		permissionsDeleteUser: {
			title: "Usuń użytkownika.",
			bodyHTML: "Usunięcie użytkownika spowoduje, że obiekt dla tego użytkownika nie będzie już dostępny.",
			button: "Usuń",
		},
		downloadResults: {
			title: "Pobierz wyniki",
			download: "Pobierz",
			btOptions: {
				hocr: "Pobierz hOCR",
				hocrZip: "Pobierz hOCR (Zip)",
				tei: "Pobierz TEI",
				teiZip: "Pobierz TEI z koordynatami (Zip)",
				text: "Pobierz Tekst",
				textZip: "Pobierz Tekst (Zip)",
			},
			getEbook: "Pobierz eBook",
			options: {
				mobi: "Pobierz MOBI",
				epub: "Pobierz EPUB",
				pdf: "Pobierz PDF",
			},
		},
		regulations: {
			title: "Regulamin dotyczący publikowania transkrypcji w WLT",
			button: "Rozumiem",
		},
		commentsVerse: {
			titleAdd: "Dodaj komentarz",
			titleVerse: "Komentarze do wiersza",
			button: "Dodaj komentarz",
			clear: "Wyczyść",
			commentsInAVerse: "Komentarzy w wierszu",
			label: "Komentarz",
			limit: "Limit znaków",
			previousComments: "Wcześniejsze komentarze",
			failed: "Dodanie komentarza nie powiodło się",
			delete: "Usuń",
		},
		commentsDelete: {
			title: "Potwierdź usunięcie komentarza",
			bodyHTML: "Ta funkcja usunie komentarz użytkownika do wersu.<br> Czy jesteś pewny, że chcesz kontynuować?",
			button: "Usuń komentarz",
		},
		deleteVerse: {
			title: "Potwierdź usunięcie wiersza",
			bodyHTML: "Ta funkcja usunie wybrany wiersz.<br> Czy jesteś pewny, że chcesz kontynuować?",
			button: "Usuń wiersz",
		},
		deleteVerses: {
			title: "Usuń wiele wierszy",
			bodyHTML: "Po usunięciu wierszy nie będziesz mógł ich przywrócić. <br/> Wiersze zostaną usnięte wraz z przypisanymi do nich komentarzami.",
			button: "Usuń",
			input: {
				placeholder: "1,6-8,12",
				label: "Wybierz zakres wierszy, które chcesz usunąć:",
				error: "Błędny zakres wierszy",
			},
		},
		importAvailableSources: {
			title: {
				mets: 'Lista dostępnych źródeł importu dla METS',
				iiif: 'Lista dostępnych źródeł importu dla IIIF',
				tei: 'Lista dostępnych źródeł importu dla TEI',
			},
			desc: {
				mets: 'Lista źródeł, z których możesz zaimportować do WLT obiekty podając link do metadanych METS.',
				iiif: 'Lista źródeł, z których możesz zaimportować do WLT obiekty podając link do manifestu IIIF.',
				tei: 'Lista źródeł, z których możesz zaimportować do WLT obiekty podając link do pliku TEI.',
			},
			search: 'Wyszukaj źródło',
			close: 'Zamknij',
		},
		contactAuthor: {
			title: "Kontakt z Twórcą transkrypcji",
			subject: "Temat",
			message: "Treść",
			button: "Wyślij",
			sendMsg: "Wiadomość została wysłana",
			notSendMsg: "Wiadomość nie została wysłana",
		},
	},
	transcription: {
		details: {
			myTranscription: "Moja transkrypcja",
			analyticLevel: "POZIOM ANALITYCZNY",
			analyticLevelDescription: "- zawiera elementy bibliograficzne opisujące pozycję (np. artykuł lub rozdział) opublikowane w monografii lub czasopiśmie, a nie jako niezależna publikacja",
			monographicLevel: "POZIOM MONOGRAFICZNY",
			monographicLevelDescription: "- zawiera elementy bibliograficzne opisujące pozycję (np. książkę, rękopis lub czasopismo) opublikowane jako niezależny element (tj. jako oddzielny przedmiot fizyczny)",
			textLevel: "POZIOM PROFILU TEKSTOWEGO",
			textLevelDescription: "- zawiera opis pozabibliograficznych aspektów tekstu",
			metadata: "Metadane obiektu źródłowego",
			collapse: "Zwiń metadane",
			expand: "Rozwiń metadane",
			title: "Tytuł:",
			author: "Autor:",
			language: "Język:",
			type: "Typ:",
			redactor: "Redaktor:",
			numberOfChapter: "Numer rozdziału:",
			numberOfNotebook: "Numer zeszytu:",
			pageRange: "Zakres stron:",
			publisher: "Wydawca:",
			placeOfPublication: "Miejsce wydania:",
			dateOfPublication: "Data wydania:",
			isbn: "ISBN:",
			keywords: "Słowa kluczowe:",
			summary: "Streszczenie:",
			links: "Linki:",
			importAdress: "Adres importu ",
			externalId: "Zewnętrzny Identyfikator ",
			details: "Szczegóły",
			permissions: "Uprawnienia",
			remove: "Usuń",
			start: "Rozpocznij transkrypcję",
			continue: "Kontynuuj transkrypcję",
			transcriptionComplete: "Transkrypcja ukończona",
			verificationComplete: "Transkrypcja zweryfikowana",
			transcriptionPages: "Strony transkrypcji ",
			contact: "Skontaktuj sie z Twórcą transkrypcji",
			edit: "Edytuj opis",
			dateOfCreation: "Data dodania: ",
			sendEmail: "Wyślij e-mail",
			addNewPages: "Dodaj nowe strony",
			automaticallyTranscribe: "Transkrybuj strony automatycznie",
			loadText: "Załaduj tekst do stron",
			verify: "Weryfikacja transkrypcji",
			downloadResults: "Pobierz wyniki",
			returnToMyTranscripts1: "Zobacz wszystkie ",
			returnToMyTranscripts2: "Moje transkrypcje",
			workWithPages: "Praca ze stronami",
			identifierLevel: "POZIOM IDENTYFIKATORÓW",
			EISBN: "e-ISBN:",
			EISSN: "e-ISSN:",
			ISSN: "ISSN:",
			pages: "Liczba stron:",
			volumeNumber: "Numer tomu:",
			importAdressDesc: "Jest to adres URL systemu, z którego obiekt został zaimportowany.",
			externalIdDesc: "Jest to identyfikator obiektu, który został mu przypisany w zewnętrznym systemie, z którego pochodzi.",
		},
		addPages: {
			title: "Dodaj nowe strony",
			description1: "Wybierz a następnie prześlij strony obiektu. Możesz również dokonać importu stron z istniejącej publikacji. Wspierane formaty plików: JPG, TIFF, PDF lub ZIP (utworzony z plików JPG, TIFF lub PDF).",
			description2: "Numeracja stron transkrypcji będzie zgodna z liczbami zapisanymi w nazwie pliku. Jeśli nazwa pliku nie będzie posiadała liczby porządkowej pliki zostaną posortowane alfabetycznie A-Z.",
		},
		addText: {
			titlePage: "Załaduj tekst strony",
			titlePages: "Załaduj tekst stron",
			descriptionPage: "Wybierz a następnie prześlij swój plik transkrypcji w formacie hOCR lub TEI z koordynatami.",
			descriptionPages: "Wybierz a następnie prześlij swój plik transkrypcji w formacie hOCR (Zip) lub TEI z koordynatami (Zip).",
		},
		new: {
			title: 'Nowa transkrypcja',
			importedTitle: 'Zaimportowana transkrypcja',
			import: 'Zaimportuj obiekt z zewnętrznego systemu',
			sourceObjectMetadata: 'Metadane obiektu źródłowego',
			reference: 'Odnośniki',
			terms: 'Aby utworzyć obiekt musisz przeczytać i zaakceptować ',
			termsRegLink: 'Regulamin',
			termsNew1: 'Zapoznałem się z ',
			termsNew2: ' i akceptuję jego treść.',
			termsLink: 'Regulaminem',
			importSucced: 'Importowanie transkrypcji przebiegło pomyślnie.',
			alertSuccess: 'Utworzenie transkrypcji przebiegło pomyślnie. Teraz możesz dodać pliki do nowej transkrypcji. W tym celu kliknij przycisk „Dodaj nowe pliki”',
			alertEdit: 'Dokonane przez Ciebie zmiany w opisie transkrypcji zostały zapisane',
			alertError: 'Wystąpił błąd w tworzeniu nowej transkypcji',
		},
		import: {
			title: 'Import transkrypcji',
			subtitle: 'Zaimportuj obiekt z zewnętrznego systemu.',
			description: 'Funkcja ta załaduje wszystkie metadane i pliki obiektu.',
			selectLabel: 'Wybierz format metadanych',
			urlLabel: 'Wpisz adres URL',
			remember: 'Pamiętaj, by po imporcie metadanych zapoznać się z regulaminem i zaakceptować jego treść. Następnie utwórz nową transkrypcję.',
			startImport: 'Rozpocznij pobieranie',
			checkList: 'Sprawdź listę dostępnych źródeł',
			searchSource: 'Wyszukaj źródło',
			errors: {
				importserrorurlmalformed: "Niepoprawny URL",
				importserrorurlsecured: "URL zabezpieczony",
				importserrorurlunresponsive: "Obiekt pod podanym adresem nie odpowiada",
				importserrorurlnotprocessable: "Obiekt pod podany adresem nie może być przetworzony",
				importserrorurlnotfound: "Adres nie znaleziony",

				importstextformatmalformed: "Niepoprawny format obiektu",
				importstextformatwrongtype: "Niepoprawny typ obiektu",
				importstextformatunsupportedtypes: "Nieobsługiwany typ obiektu",
				importstextformatlinkless: "Obiekt nie zawiera linków",
				domainerrornotavailableiiif: "Ten adres nie należy do naszej bazy źródeł, z których możesz zaimportować pliki typu Manifest IIIF.",
				domainerrornotavailabletei: "Ten adres nie należy do naszej bazy źródeł, z których możesz zaimportować pliki typu TEI.",
				domainerrornotavailablemets: "Ten adres nie należy do naszej bazy źródeł, z których możesz zaimportować pliki typu METS.",
			},
		},
		edit: {
			title: "Edycja transkrypcji",
		},
		form: {
			title: 'Tytuł:',
			select: 'Wybierz',
			titlePlaceholder: 'Jeśli nie znasz oryginalnego tytułu możesz zaproponować swój lub wpisać incipit',
			role: 'Rola:',
			firstAndLastName: 'Imię i nazwisko:',
			none: 'brak',
			firstAndLastNamePlaceholder: 'Jan Kowalski (1934-1999)',
			language: 'Język:',
			languageSelect: 'Wybierz język',
			type: 'Typ:',
			typeSelect: 'Wybierz typ',
			chapterNumber: 'Numer rozdziału:',
			volumeNumber: 'Numer tomu:',
			notebookNumber: 'Numer zeszytu:',
			pagesRange: 'Zakres stron:',
			pagesRangePlaceholder: 'Np.: 13-18',
			pagesNumber: 'Liczba stron:',
			publisher: 'Wydawca:',
			summary: "Streszczenie:",
			publicationPlace: 'Miejsce wydania / powstania:',
			publicationDate: 'Data wydania / powstania:',
			publicationDatePlaceholder: 'Np.: 1789 lub II połowa XVIII wieku',
			isbn: 'ISBN:',
			eisbn: 'e-ISBN:',
			issn: 'ISSN:',
			eissn: 'e-ISSN:',
			keywords: 'Słowa kluczowe:',
			keywordsPlaceholder: 'Ciąg słów kluczowych rozdziel średnikiem',
			link: 'Link:',
			appendAnotherLink: 'Dodaj kolejny link',
			appendAnotherPerson: 'Dodaj kolejną osobę',
		},
		verification: {
			transcriptionVerification: 'Weryfikacja transkrypcji',
			stats: 'Statystyki transkrybentów',
			title: 'Tytuł:',
			author: 'Autor:',
			advancement: {
				main: 'Zaawansowanie weryfikacji:',
				toVerify: 'Stron do weryfikacji w tym obiekcie:',
				verified: 'Zweryfikowanych wierszy:',
				correct: 'Poprawnych:',
				incorrect: 'Błędnych:',
			},
			table: {
				number: 'Nr',
				name: 'Nazwa strony',
				versesNumber: 'Liczba wierszy',
				verified: 'Zweryfikowanych',
				correct: 'Poprawnych',
				incorrect: 'Błędnych',
				lastEdition: 'Ostatnia edycja',
				verification: 'Weryfikacja',
				verify: 'Weryfikuj',
			},
		},
		permissions: {
			title: "Moja transkrypcja - Uprawnienia",
			objectAuthority: "Uprawnienia do obiektu",
			publicAvailability: "Dostępny publicznie",
			privateAvailability: "Niedostępny publicznie",
			status: "Status obiektu na stronie WLT: ",
			shareObject: "Współdziel obiekt z wybranymi użytkownikami i grupami WLT",
			users: "Użytkownicy, którzy mają dostęp do obiektu:",
			groups: "Grupy, które mają dostęp do obiektu:",
			addGroupName: "Podaj nazwę grupy",
			addUserTitle: "Dodaj użytkownika",
			addGroupTitle: "Dodaj grupę",
			addUserInfo: "Podaj e-mail użytkownika, któremu chcesz dać dostęp do obiektu:",
			addUserPlaceholder: "kowalski@poczta.pl",
			addUserButton: "Dodaj",
			email: 'E-mail',
			role: 'Rola',
			deleteUser: 'Usuń',
			groupPlaceholder: 'Instytut Historii',
		},
		stats: {
			transcribersStats: 'Statystyki transkrybentów',
			title: 'Tytuł:',
			author: 'Autor:',
			userName: 'Nazwa użytkownika',
			charactersEntered: 'Liczba wpisanych znaków',
			transcribedVerses: 'Liczba transkrybowanych wersów',
			correctlyVerifiedVerses: 'Liczba poprawnie zweryfikowanych wersów',
			contact: 'Skontaktuj się',
			noStats: 'Ten obiekt nie posiada jeszcze statystyk, by transkrypcja przez użytkownika była wliczona w statystyki musi on kliknąć poprawność transkrypcji wybranej strony. Może to zrobić przez kliknięcie w oknie transkrypcji przycisku "Poprawna transkrypcja" lub oznaczając osobno poprawność każdego wiersza klikając w checkbox zlokalizowany tuż za wierszem. ',
			howTo: 'Dowiedz się więcej o tym jak transkrybować',
		},
		alert: {
			processingOCR: 'Trwa automatyczna transkrypcja...',
		},
	},
	main: {
		lastTranscribed: {
			title: 'Ostatnio transkrybowane w WLT',
			seeAll: 'Zobacz wszystkie udostępnione',
			transcriptions: 'Transkrypcje',
			noTranscriptions: 'Nie znaleziono transkrypcji',
		},
		myLastTranscription: {
			title: 'Ostatnia moja transkrypcja',
			seeAll: 'Zobacz wszystkie',
			transcriptions: 'Moje transkrypcje',
		},
	},
	title: "WLT - Wirtualne Laboratorium Transkrypcji",
};

export default pl;
