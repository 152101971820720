import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import PropTypes from "prop-types";

import { viewerActions } from 'store/actions/viewer.actions';
import { appActions } from 'store/actions/app.actions';

import ViewerOSD from "views/pages/Viewer/parts/ViewerOSD/ViewerOSD";
import ViewerOSDMenu from "views/pages/Viewer/parts/ViewerOSD/ViewerOSDMenu";

import ViewerEditor from "views/pages/Viewer/parts/ViewerEditor/ViewerEditor";
import ViewerEditorMenu from "views/pages/Viewer/parts/ViewerEditor/ViewerEditorMenu";

import Button from "views/elements/Button/Button";
import Error403 from "views/pages/Error/Error403";

import getTranscriptionStatusOCR from 'utils/transcriptionStatusOCR';

import { useShowModal, useModal, useHideModal } from 'views/elements/Modal/ModalHooks';

const ViewerTogglePanel = ({ type }) => {
	const dispatch = useDispatch();
	const dict = useSelector((state) => state.app.dict);

	const togglePanels = useSelector((state) => state.app.viewer.togglePanels);

	const togglePanel = (value) => {
		return value === 'osd' ?
			dispatch(appActions.setTogglePanelsOSD(!togglePanels.osd)) :
			dispatch(appActions.setTogglePanelsEditor(!togglePanels.editor));
	};

	return (
		<Button
			variant='viewer-full'
			className='viewer__button--fold'
			onClick={ () => togglePanel(type) }
		>
			<i className='viewer__icon--fold' />
			{!togglePanels[type] ? (
				<span>{dict.viewer.panels.fold}</span>
			) : (
				<span>{dict.viewer.panels.expand}</span>
			)}
		</Button>
	);
};

ViewerTogglePanel.propTypes = {
	type: PropTypes.string,
};

const ViewerContentHeader = ({ panel, osdDrawMode, isMobile = false }) => {
	const dispatch = useDispatch();
	const dict = useSelector((state) => state.app.dict);

	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const readOnly = viewMode === 'read-only';
	const student = viewMode === 'student';
	const transcribe = viewMode === 'transcribe';

	const mobilePanels = useSelector((state) => state.app.viewer.mobilePanels);
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);
	const verseEdit = useSelector((state) => state.viewer.editor.verseEdit);
	const selectionEdit = useSelector((state) => state.viewer.ocd.selection);

	const verses = useSelector((state) => state.viewer.data.verses);

	const [ currentVerseCannotBeMovedOrScaled, setCurrentVerseCannotBeMovedOrScaled ] = useState(false);

	useEffect(() => {
		if (!verses || !verseActive) return;
		setCurrentVerseCannotBeMovedOrScaled((transcribe && verses.find(({ id }) => id === verseActive).status === "verified") ? true : false);
	}, [ transcribe, verses, verseActive ]);

	const mobilePanel = (mode) => {
		dispatch(appActions.setMobilePanels(mode));
	};

	useEffect(() => {
		dispatch(viewerActions.clearTranscriptionData());
	}, [ dispatch ]);

	useEffect(() => {
		mobilePanel(student ? 'student' : 'osd');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ viewMode ]);

	useEffect(() => {
		if (selectionEdit) {
			// when change active verse leave edit mode
			dispatch(viewerActions.setSelectionEdit(false));
		}
	}, [ verseActive ]);

	const editSelection = () => {
		dispatch(viewerActions.setSelectionEdit(!selectionEdit));
	};

	return (
		<>
			<Button
				variant='viewer-title'
				className={ `viewer__button--title viewer__button--title-${panel} ${mobilePanels === "osd" ? 'viewer__button--title-active' : ''}` }
				onClick={ () => mobilePanel('osd') }
			>
				{dict.viewer.panels.title.osd}
			</Button>
			<Button
				variant='viewer-title'
				className={ `viewer__button--title viewer__button--title-${panel} ${mobilePanels === "student" ? 'viewer__button--title-active' : ''} ${student ? 'viewer__button--student-mode' : 'd-none'}` }
				onClick={ () => mobilePanel('student') }
				disabled={ verseEdit }
			>
				{dict.viewer.panels.title.student}
			</Button>
			<Button
				variant='viewer-title'
				className={ `viewer__button--title viewer__button--title-${panel} ${mobilePanels === "editor" ? 'viewer__button--title-active' : ''} ${student ? 'viewer__button--student-mode' : ''}` }
				onClick={ () => mobilePanel('editor') }
				disabled={ verseEdit }
			>
				{dict.viewer.panels.title.editor}
			</Button>

			{panel === 'osd' && !readOnly && !student &&
			<div
				className='viewer__content--header-edit'
				data-tip={ dict.viewer.tooltip.osdMenu.editArea }
				data-place='bottom'
			>
				{!isMobile &&
				<Button
					variant='viewer-editSelection'
					disabled={ !verseActive || osdDrawMode || currentVerseCannotBeMovedOrScaled }
					onClick={ () => editSelection() }
				>
					{selectionEdit &&
					<>
						<i className='icon-accept' />
						{dict.viewer.panels.selection.save}
					</>}
					{!selectionEdit &&
					<>
						<i className='icon-edit' />
						{dict.viewer.panels.selection.edit}
					</>}

				</Button>}

			</div>}
		</>
	);
};

ViewerContentHeader.propTypes = {
	panel: PropTypes.string,
	osdDrawMode: PropTypes.bool,
    isMobile: PropTypes.bool,
};

const Viewer = () => {
	const [ osdDrawMode, setDrawMode ] = useState(false);
	const [ osdMethods, setOsdMethods ] = useState({});
	const [ zoom, setZoom ] = useState(0);
	const [ homeZoom, setHomeZoom ] = useState(0);
	const [ dataLoaded, setDataLoaded ] = useState(false);
	const [ anyVerseActive, setAnyVerseActive ] = useState(false);

	const [ transcriptionStatusOCR, setTranscriptionStatusOCR ] = useState('LOADING');
	const [ transcriptionStatusOCRPrevious, setTranscriptionStatusOCRPrevious ] = useState('');
	const [ transcriptionStatusOCRRequest, setTranscriptionStatusOCRRequest ] = useState(false);
	const [ transcriptionStatusOCRProcessingInfo, setTranscriptionStatusOCRProcessingInfo ] = useState(false);

	const dispatch = useDispatch();

	const { transcriptionId, pageId } = useParams();

	const dict = useSelector((state) => state.app.dict);
	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const authToken = useSelector((state) => state.auth.credentials.token);
	const charMap = useSelector((state) => state.viewer.charMap);
	const noAccess = useSelector((state) => state.viewer.noAccess);

	const togglePanels = useSelector((state) => state.app.viewer.togglePanels);
	const swapPanels = useSelector((state) => state.app.viewer.swapPanels);
	const mobilePanels = useSelector((state) => state.app.viewer.mobilePanels);

	const isMobile = useSelector((state) => state.viewer.isMobile);
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);

	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	// const readOnly = viewMode === 'read-only';
	const student = viewMode === 'student';

	const { modal } = useModal();

	const modalResize = useShowModal({
		title: dict.modals.viewerResize.title,
		bodyHTML: dict.modals.viewerResize.body,
		type: 'viewer',
		name: 'viewerResize',
		withFooter: true,
		actionButton: dict.modals.viewerResize.button,
	});

	useEffect(() => {
		setAnyVerseActive(!!verseActive);
	}, [ verseActive ]);

	const { handleOnClose } = useHideModal();

	const handleResize = () => {
		const mobile = window.innerWidth < 1200;

		if (mobile) {
			dispatch(viewerActions.setIsMobile(true));
		} else {
			dispatch(viewerActions.setIsMobile(false));
		}
	};

	useEffect(() => {
		if (isMobile) {
			modalResize();
		}
		if (!isMobile && modal['name'] === 'viewerResize') {
			handleOnClose();
		}
	}, [ isMobile ]);

	useEffect(() => {
		if (!dataLoaded && authToken) {
			dispatch(viewerActions.loadData(parseInt(transcriptionId), parseInt(pageId)));
			setDataLoaded(true);
		}
	}, [ isLoggedIn, pageId, transcriptionId, dispatch ]);

	useEffect(() => {
		if (!authToken && !dataLoaded) {
			dispatch(viewerActions.loadData(parseInt(transcriptionId), parseInt(pageId)));
			setDataLoaded(true);
		}
	}, [ authToken, dispatch ]);

	useEffect(() => {

		let mounted = true;

		if (transcriptionStatusOCRRequest) {
			switch (transcriptionStatusOCR) {
				case "PROCESSING":
					setTranscriptionStatusOCRProcessingInfo(true);
					dispatch(viewerActions.isOCRProcessing(true));

					setTimeout(() => {
						if (mounted) getTranscriptionStatusOCR(transcriptionId, setTranscriptionStatusOCR, setTranscriptionStatusOCRRequest);
					}, 5 * 1000);

					break;

				default:
					setTranscriptionStatusOCRProcessingInfo(false);
					dispatch(viewerActions.isOCRProcessing(false));
					break;
			}

		}

		return () => {
			mounted = false;
		};

	}, [ transcriptionStatusOCRRequest ]);

	useEffect(() => {
		if (transcriptionStatusOCRPrevious === 'PROCESSING' && transcriptionStatusOCR !== 'PROCESSING') {
			dispatch(viewerActions.loadData(parseInt(transcriptionId), parseInt(pageId)));
			dispatch(viewerActions.isOCRProcessing(false));
		}

		setTranscriptionStatusOCRPrevious(transcriptionStatusOCR);

	}, [ transcriptionStatusOCR ]);

	useEffect(() => {
		if (charMap.length < 1) {
			dispatch(viewerActions.loadCharMap());
		}
		handleResize();
		window.addEventListener('resize', handleResize);

		getTranscriptionStatusOCR(transcriptionId, setTranscriptionStatusOCR, setTranscriptionStatusOCRRequest);

		return () => {
			// NIE USUWAĆ - to jeszcze wymaga dopracowania
			// dispatch(viewerActions.setVerseActive(null));
			dispatch(viewerActions.isLoaded(false));
			// dispatch(viewerActions.clearTranscriptionData());
			window.removeEventListener('resize', handleResize);
		};

	}, []);

	// const onOverlayAdded = useCallback((verses) => {
	// 	dispatch(viewerActions.reloadVerses(verses));
	// }, [dispatch]);

	const onToggleDrawMode = useCallback(() => {
		if (osdDrawMode) {
			setDrawMode(false);
		} else {
			setDrawMode(true);
		}
	}, [ dispatch, osdDrawMode ]);

	return !noAccess ? (
		<main className={ `viewer ${swapPanels ? 'viewer--toggle' : ''}` }>
			{transcriptionStatusOCRProcessingInfo && (
				<div className='viewer__alert'>
					<div className='viewer__alert--box'>
						{dict.transcription.alert.processingOCR}
					</div>
				</div>
			)}

			<div className={ `viewer__panel viewer__panel--osd ${(togglePanels.osd) ? 'viewer__panel--fold' : ''} ${(mobilePanels === 'osd') ? 'viewer__panel--mobile' : ''} ` }>
				<aside className='viewer__aside'>
					<div className='viewer__aside--container'>
						<div
							className='viewer__fold'
							data-tip={ (!togglePanels.osd) ?
								dict.viewer.tooltip.header.collapseObject :
								dict.viewer.tooltip.header.expandObject }
							data-place={ (swapPanels && togglePanels.osd) ? 'left' : 'right' }
						>
							<ViewerTogglePanel type='osd' />
						</div>
						<div className='viewer__menu'>
							<ViewerOSDMenu
								onToggleDrawMode={ onToggleDrawMode }
								drawMode={ osdDrawMode }
								osdMethods={ osdMethods }
								zoom={ zoom }
								homeZoom={ homeZoom }
							/>
						</div>
					</div>
				</aside>
				<article className='viewer__content'>
					<div className='viewer__content--header'>
						<ViewerContentHeader
							panel='osd'
							osdDrawMode={ osdDrawMode }
							isMobile={ isMobile }
						/>
					</div>
					<div className='viewer__content--inner'>
						<ViewerOSD
							osdDrawMode={ osdDrawMode }
							setOsdMethods={ setOsdMethods }
							setZoom={ setZoom }
							setHomeZoom={ setHomeZoom }
						/>
					</div>
				</article>
			</div>
			<div className='viewer__divider' />
			<div className={ `viewer__panel viewer__panel--editor ${togglePanels.editor ? 'viewer__panel--fold' : ''} ${(mobilePanels === 'editor' || mobilePanels === 'student') ? 'viewer__panel--mobile' : ''} ` }>
				<aside className='viewer__aside'>
					<div className='viewer__aside--container'>

						<div
							className='viewer__fold'
							data-tip={ (!togglePanels.editor) ?
								dict.viewer.tooltip.header.collapseTranscription :
								dict.viewer.tooltip.header.expandTranscription }
							data-place={ (!swapPanels && togglePanels.editor) ? 'left' : 'right' }
						>
							<ViewerTogglePanel type='editor' />
						</div>
						<div className='viewer__menu'>
							{(viewMode === 'transcribe' || viewMode === 'verify' || viewMode === 'admin' || (student && mobilePanels === 'student')) &&
							<ViewerEditorMenu anyVerseActive={ anyVerseActive } />}
						</div>
					</div>
				</aside>
				<article className='viewer__content'>
					<div className='viewer__content--header'>
						<ViewerContentHeader panel='editor' />
					</div>
					<div className='viewer__content--inner'>
						<ViewerEditor />
					</div>
				</article>
			</div>
		</main>
	) : (
		<Error403 />
	);

};

export default Viewer;