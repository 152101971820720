import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Financed from "./parts/Financed/Financed";
import MainDescription from "./parts/MainDescription/MainDescription";
import HomeTranscriptions from "./parts/HomeTranscriptions/HomeTranscriptions";
import Alert from "views/elements/Alert/Alert";

import api from "services/api";
import { appActions } from 'store/actions/app.actions';

import { hiddenFinanced } from "utils/helpers";

const Home = () => {

	const dispatch = useDispatch();
	const [ myLastTranscriptions, setMyLastTranscriptions ] = useState({});
	const [ lastTranscriptions, setLastTranscriptions ] = useState({});
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	useEffect(() => {
		isLoggedIn && getMyLastTranscriptionsData();
		getLastTranscriptionsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ isLoggedIn, dispatch ]);
    
	const getLastTranscriptionsData = () => {
		dispatch(appActions.setLoading(true));
        
		api.get('/last-transcriptions')
			.then(({data}) => {                              	
				setLastTranscriptions(data);
				dispatch(appActions.setLoading(false));				
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	};
    
	const getMyLastTranscriptionsData = () => {
		dispatch(appActions.setLoading(true));
	
		api.get('/my-last-transcription')
			.then(({data}) => {									
				setMyLastTranscriptions(data);
				dispatch(appActions.setLoading(false));
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	};

	return (
		<>
			<MainDescription />
			<Alert />
			<main>
				{isLoggedIn && typeof myLastTranscriptions !== 'string' &&
					<HomeTranscriptions 
						transcriptions={ myLastTranscriptions } 
						homeComponent='myLastTranscription'
					/>}
				<HomeTranscriptions
					transcriptions={ lastTranscriptions } 
					homeComponent='lastTranscribed'
				/>
			</main>
			{ !hiddenFinanced() && <Financed />}
		</>
	);
};

export default Home;
