import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { reverse } from 'named-urls';
import { useHistory } from 'react-router';
import { Dropdown } from 'react-bootstrap';

import { useShowModal } from 'views/elements/Modal/ModalHooks';

import { viewerActions } from 'store/actions/viewer.actions';
import { appActions } from 'store/actions/app.actions';

import url from "router/urls";
import styles from './TranscriptionsItem.module.sass';

import imageOnError from 'assets/images/icon-image.svg';

const TranscriptionsItemPage = ({ 
	page, 
	viewMode = 'read-only', 
	creationMode = 'web_form', 
	isInTranscriptionDetails = false, 
	transcriptionId, 
	currentPage, 
	transcriptionStatusOCRDisabledButtons,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const dict = useSelector((state) => state.app.dict);

	const linkToTranscriptionView = reverse(url.transcription.viewer, { transcriptionId: parseInt(transcriptionId), pageId: parseInt(page.id) });

	const [ transcriptionOptions, setTranscriptionOptions ] = useState([]);

	const modalDeleteTranscriptionPage = useShowModal({
		title: dict.modals.deleteTranscriptionPage.title,
		name: 'deleteTranscriptionPage',
		componentPath: './parts/ModalDeleteTranscriptionPage',
		componentProps: { transcriptionId: parseInt(transcriptionId) },
	});

	useEffect(() => {
		let arr = [ { id: "hocrDownload" }, { id: "textDownload" }, { id: "teiDownload" } ];
		if (viewMode === 'admin' || viewMode === "verify") {
			arr = [ ...arr, { id: "loadText" } ];
		}
		if (viewMode === 'admin' && creationMode === 'web-form') {
			arr = [ ...arr, { id: "deletePage" } ];
		}
		setTranscriptionOptions(arr);
	}, [ page.percentOfTranscribed, viewMode, creationMode ]);
	
	const downloadFileFormat = (format) => {
		dispatch(viewerActions.getFileByFormat(page.id, format));
	};

	const onOption = (id) => {
		switch (id) {
			case 'loadText':
				history.push(reverse(url.transcription.addTextToPage, { transcriptionId: parseInt(transcriptionId), pageId: parseInt(page.id) }));
				break;
			case 'hocrDownload':
				downloadFileFormat('hocr');
				break;
			case 'textDownload':
				downloadFileFormat('plain-text');
				break;
			case 'teiDownload':
				downloadFileFormat('tei');
				break;
			case 'deletePage':
				dispatch(appActions.setModalDeleteTranscriptionPage(page.id));
				modalDeleteTranscriptionPage();
				break;
			default:
				return false;
		}
	};

	const imageRef = useRef(null);

	const handleImageOnError = () => {
		imageRef.current.className = styles["transcription__page--image-error"];
		imageRef.current.style.backgroundImage = `url(${imageOnError})`;
	};	

	return (

		<div className={ styles["transcription__page--box"] }>
			<Link
				to={ {
					pathname: linkToTranscriptionView,
					state: {
						paginationPage: currentPage,
					},
				} }
			>
				<div
					className={ styles["transcription__page--image"] }
					style={ { backgroundImage: `url(${page.thumbnailUrl})` } }
					ref={ imageRef }
				>
					{page.percentOfTranscribed === 100 &&
						<div className={ styles["transcription__page--ribbon"] }>
							{dict.transcriptions.item.fullOCR}
						</div>}
						
					{page.thumbnailUrl && 
						<img 
							src={ page.thumbnailUrl }
							alt={ page.id }
							onError={ handleImageOnError }
						/>}
				</div>
			</Link>
			<div className={ styles["transcription__page--block-bottom"] }>
				<Link
					to={ {
						pathname: linkToTranscriptionView,
						state: {
							paginationPage: currentPage,
						},
					} }
					className={ styles["transcription__page--number"] }
				>
					{page.pageNo}
				</Link>
				<div className={ styles["transcription__page--progress"] }>
					<span className={ styles["transcription__page--progress-text"] }>
						<span className={ styles["transcription__page--progress-text-long"] }>
							{dict.transcriptions.item.transcriptionAdvancementFull}
						</span>
						<span className={ styles["transcription__page--progress-text-short"] }>
							{dict.transcriptions.item.transcriptionAdvancementShort}
						</span>
					</span>
					<span className={ styles["transcription__page--progress-bar"] }>
						<div className={ styles["transcription__page--progress-bar--wrapper"] }>
							<span
								className={
									page.percentOfTranscribed === 100 ?
										styles["transcription__page--progress-bar--active"]
										:
										styles["transcription__page--progress-bar--meter"]
								}
								style={ { width: `${page.percentOfTranscribed}%` } }
							/>
						</div>
						<span className={ styles["transcription__page--progress-value"] }>
							{page.percentOfTranscribed}%
						</span>
					</span>
				</div>

				{isInTranscriptionDetails && (transcriptionOptions.length > 0) &&
					<Dropdown 
						bsPrefix='dropdown-transcription'
						drop='down'
						
					>
						<Dropdown.Toggle
							variant='dropdown'
							disabled={ transcriptionStatusOCRDisabledButtons }
							id='btn'
						>
							{dict.transcriptions.dropdown.btn}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{transcriptionOptions.map(transcriptionOption => (
								<Dropdown.Item
									{ ...transcriptionOption }
									key={ transcriptionOption.id }
									onClick={ () => onOption(transcriptionOption.id) }
									
								>
									{dict.transcriptions.dropdown[transcriptionOption.id]}
								</Dropdown.Item>
							),
							)}
						</Dropdown.Menu>
					</Dropdown>}

			</div>
		</div>

	);
};

TranscriptionsItemPage.propTypes = {
	page: PropTypes.object,
	percentOfVerified: PropTypes.number,
	percentOfTranscribed: PropTypes.number,
	viewMode: PropTypes.string,
	creationMode: PropTypes.string,
	isInTranscriptionDetails: PropTypes.bool,
	transcriptionId: PropTypes.number,
	currentPage: PropTypes.number,
    transcriptionStatusOCRDisabledButtons: PropTypes.bool
};

export default TranscriptionsItemPage;