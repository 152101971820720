import React, { memo } from "react";
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from "prop-types";

import { viewerActions } from 'store/actions/viewer.actions';

import DropdownBox from './parts/DropdownBox';
import FormattingDropdownButton from './parts/FormattingDropdownButton';
import FormattingButton from './parts/FormattingButton';

import ReactTooltip from 'react-tooltip';

import Button from "views/elements/Button/Button";

const INLINE_STYLES = [
	{ label: 'B', style: 'BOLD', icon: 'bold', tooltip: 'viewer.tooltip.menu.style.BOLD' },
	{ label: 'I', style: 'ITALIC', icon: 'italic', tooltip: 'viewer.tooltip.menu.style.ITALIC' },
];

const StyleButton = ({ style, active, icon, tooltip = '' }) => {
	const dispatch = useDispatch();
	const dict = useSelector((state) => state.app.dict);
	const verseEdit = useSelector((state) => state.viewer.editor.verseEdit);

	const setStyle = (event, value) => {
		event.preventDefault();
		dispatch(viewerActions.setStyleActive(value));
	};

	return (
		<div
			data-tip={ dict.viewer.tooltip.menu.style[style] }
			data-place='right'
		>
			<Button
				variant='viewer-button'
				className={ ` viewer__button--menu ${active ? ' btn-viewer-button--active' : ''} ` }
				onMouseDown={ (event) => setStyle(event, style) }
				disabled={ !verseEdit }
				data-tip={ tooltip }
			>
				<span className='d-none'>{icon}</span>
				<i className={ `icon-${icon}` } />
			</Button>
		</div>
	);
};

StyleButton.propTypes = {
	style: PropTypes.string,
	active: PropTypes.bool,
	icon: PropTypes.string,
	tooltip: PropTypes.string,
};

const ViewerEditorMenu = ({ anyVerseActive }) => {
	const dispatch = useDispatch();

	const currentStyle = useSelector((state) => state.viewer.editor.styleCurrent);
	const dict = useSelector((state) => state.app.dict);
	const verseEdit = useSelector((state) => state.viewer.editor.verseEdit);
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);
	const charMap = useSelector(state => state.viewer.charMap);
	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const verses = useSelector((state) => state.viewer.data.verses);
	const verse = verses.find(x => x.id === verseActive);

	const student = viewMode === 'student';

	React.useEffect(() => {
		ReactTooltip.rebuild();
	}, []);

	return (
		<ul className='viewer__menu--list'>
			{INLINE_STYLES.map(type =>
				<li key={ type.style }>
					<StyleButton
						key={ type.label }
						active={ currentStyle.size ? currentStyle.has(type.style) : false }
						label={ type.label }
						style={ type.style }
						icon={ type.icon }
						tooltip={ dict[type.tooltip] }
					/>
				</li>,
			)}

			<li>
				<DropdownBox
					disabled={ !(verseEdit && charMap.length) }
					tooltip={ dict.viewer.tooltip.menu.charmap }
					icon='symbol'
				>
					<ul className='viewer__charmap'>
						{charMap.map(item =>
							<li
								className='viewer__charmap--item'
								key={ item }
							>
								<Button
									variant='viewer-button'
									className='viewer__button--charmap'
									onClick={ () => dispatch(viewerActions.setInsertCharacter(item)) }
								>
									{item}
								</Button>
							</li>,
						)}
					</ul>
				</DropdownBox>
			</li>
			{ !student &&
			<>				
				<hr className='viewer__divider' />

				<li>
					<FormattingButton
						icon='quote'
						func='formatting/end-of-paragraph'
						tooltip={ dict.viewer.tooltip.menu.endOfParagraph }
						data-tip={ dict.viewer.tooltip.menu.endOfParagraph }
					/>
				</li>

				<li>
					<DropdownBox
						disabled={ (!anyVerseActive || verseEdit) || (viewMode === 'transcribe' && verse && verse.status === 'verified') }
						tooltip={ dict.viewer.tooltip.menu.list }
						icon='list'
					>
						<FormattingDropdownButton
							icon='list-item-end'
							func='formatting/list-form/end-of-item'
							label={ dict.viewer.tooltip.menu.endOfListItem }
						/>
						<FormattingDropdownButton
							icon='list-end'
							func='formatting/list-form/end-of-list'
							label={ dict.viewer.tooltip.menu.endOfList }
						/>
					</DropdownBox>
				</li>

				<li>
					<DropdownBox
						disabled={ (!anyVerseActive || verseEdit) || (viewMode === 'transcribe' && verse && verse.status === 'verified') }
						tooltip={ dict.viewer.tooltip.menu.pageDescription }
						icon='verses-desc'
					>
						<FormattingDropdownButton
							icon='verse-header'
							func='formatting/forme-work/header'
							label={ dict.viewer.tooltip.menu.verseHeader }
						/>
						<FormattingDropdownButton
							icon='verse-footer'
							func='formatting/forme-work/footer'
							label={ dict.viewer.tooltip.menu.verseFooter }
						/>
						<FormattingDropdownButton
							icon='page-number'
							func='formatting/forme-work/page-no'
							label={ dict.viewer.tooltip.menu.pageNumber }
						/>
					</DropdownBox>
				</li>

				<li>
					<DropdownBox
						disabled={ (!anyVerseActive || verseEdit) || (viewMode === 'transcribe' && verse && verse.status === 'verified') }
						tooltip={ dict.viewer.tooltip.menu.titleH }
						icon='header'
					>
						<FormattingDropdownButton
							icon='header-1-end'
							func='formatting/heading/end-of-heading-1'
							label={ dict.viewer.tooltip.menu.titleH1 }
						/>
						<FormattingDropdownButton
							icon='header-2-end'
							func='formatting/heading/end-of-heading-2'
							label={ dict.viewer.tooltip.menu.titleH2 }
						/>
						<FormattingDropdownButton
							icon='header-3-end'
							func='formatting/heading/end-of-heading-3'
							label={ dict.viewer.tooltip.menu.titleH3 }
						/>
					</DropdownBox>
				</li>

				<li>
					<DropdownBox
						disabled={ (!anyVerseActive || verseEdit) || (viewMode === 'transcribe' && verse && verse.status === 'verified') }
						tooltip={ dict.viewer.tooltip.menu.image }
						icon='image-description'
					>
						<FormattingDropdownButton
							icon='image'
							func='image'
							label={ dict.viewer.tooltip.menu.image }
						/>
						<FormattingDropdownButton
							icon='image-description'
							func='formatting/image-caption'
							label={ dict.viewer.tooltip.menu.imageCaption }
						/>

					</DropdownBox>
				</li>

				<li>
					<DropdownBox
						disabled={ (!anyVerseActive || verseEdit) || (viewMode === 'transcribe' && verse && verse.status === 'verified') }
						tooltip={ dict.viewer.tooltip.menu.table }
						icon='table'
					>
						<FormattingDropdownButton
							icon='table-cell-end'
							func='formatting/tabular-form/end-of-cell'
							label={ dict.viewer.tooltip.menu.endOfCell }
						/>
						<FormattingDropdownButton
							icon='table-verse-end'
							func='formatting/tabular-form/end-of-row'
							label={ dict.viewer.tooltip.menu.endOfRow }
						/>
						<FormattingDropdownButton
							icon='table-end'
							func='formatting/tabular-form/end-of-table'
							label={ dict.viewer.tooltip.menu.endOfTable }
						/>

					</DropdownBox>
				</li>

				<hr className='viewer__divider' />

				<li>
					<FormattingButton
						icon='verse-auto-transcription'
						func='recognize-content'
						tooltip={ dict.viewer.tooltip.menu.verseAutoTranscription }
					/>
				</li>

				<li>
					<FormattingButton
						icon='verse-comment'
						func='verse-comment'
						tooltip={ dict.viewer.tooltip.menu.addComment }
					/>
				</li>

				<li>
					<DropdownBox
						tooltip={ dict.viewer.tooltip.menu.deleting }
						disabled={ !verses.length }
						icon='verse-delete'
					>
						<FormattingDropdownButton
							icon='verse-delete'
							func='verse-delete'
							disabled={ (!anyVerseActive || verseEdit) }
							label={ dict.viewer.tooltip.menu.verseDelete }
						/>
						<FormattingDropdownButton
							icon='verse-delete'
							func='verses-delete'
							label={ dict.viewer.tooltip.menu.versesDelete }
						/>
					</DropdownBox>
				</li>

				<li>
					<FormattingButton
						icon='verse-clear'
						func='modify-content'
						tooltip={ dict.viewer.tooltip.menu.verseClear }
					/>
				</li>

				<hr className='viewer__divider' />

				<li>
					<FormattingButton
						icon='verse-up'
						func='move-up'
						tooltip={ dict.viewer.tooltip.menu.verseUp }
					/>
				</li>

				<li>
					<FormattingButton
						icon='verse-down'
						func='move-down'
						tooltip={ dict.viewer.tooltip.menu.verseDown }
					/>
				</li>
			</>}
		</ul>

	);
};

ViewerEditorMenu.propTypes = {
	anyVerseActive: PropTypes.bool,
};

export default memo(ViewerEditorMenu);