import React from 'react';
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import styles from './Form.module.sass';

const FormInputFile = ({ dict, register, name='file', errorMessage, label, chooseFile, formats }) => {
	const buttonLabel = label ? label : dict.form.inputFile.selectFile;

	return (
		<Form.Row className={ styles["form__input-row"] }>
			<div className='input-group'>
				<div className='custom-file'>
					<input
						type='file'
						className='custom-file-input'
						ref={ register({
							validate: {
								wrongFileType: inputFile => {
									return inputFile.length > 0 && formats.includes(Object.values(inputFile).map((value) => { return '.' + value.name.substr(value.name.lastIndexOf('.')+1,value.name.length); }).toString());
								},
							},
						}) }
						name={ name }
						accept={ formats.join(',') }
						id='inputFile'
					/>
					<label
						className='custom-file-label'
						htmlFor='inputFile'
						data-buttontext={ buttonLabel }
					>
						{ chooseFile }
					</label>
				</div>
			</div>
			{ errorMessage ? <div className={ styles["form__message--invalid"] }><i className='icon-close' />{errorMessage}</div> : null }
		</Form.Row>
	);
};
    
FormInputFile.propTypes = {
	register: PropTypes.func,
	dict: PropTypes.object,
	name: PropTypes.string,
	errorMessage: PropTypes.string,
	label: PropTypes.string,
	formats: PropTypes.array,
	chooseFile: PropTypes.string,
};

export default FormInputFile;