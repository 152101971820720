import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import styles from "./Alert.module.sass";

import { alertActions } from 'store/actions/alert.actions';

const Alert = ({ leaveAlert = false, className }) => {
	let alertState = JSON.parse(JSON.stringify(useSelector((state) => state.alert)));
	const dispatch = useDispatch();
	const dict = useSelector((state) => state.app.dict);

	useEffect(() => {
		if(!(leaveAlert || alertState.important)){
			dispatch(alertActions.clearAlert());
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const dictResolver = (obj, path) => {
		return path.split('.').reduce(function(prev, curr) {
			return prev ? prev[curr] : null;
		}, obj );
	};

	const onCloseAlert = () => {
		alertState = {};
		dispatch(alertActions.clearAlert());
	};

	const icon = (alertState.icon.length>0) ? (
		<i className={ `${alertState.icon} ${styles.icon}` } />
	) : null;
	const closeIcon = (alertState.close) ? (
		<i
			className={ `icon-close ${styles.close}` }
			onClick={ onCloseAlert }
		/>
	) : null;

	return (
		<>
			{(alertState.text.length > 0 || (alertState.textHTML && alertState.textHTML.length > 0)) ? (
				<div className={ `${styles["alert--" + alertState.type]} ${className ? className : ''}` }>
					{icon}
					{ alertState.text ? (
						<>{ dictResolver(dict, alertState.text)}</>
					) : (
						<span dangerouslySetInnerHTML={ { __html: dictResolver(dict, alertState.textHTML) } } />
					)}
					{closeIcon}
				</div>) : null }
		</>
	);
};

Alert.propTypes ={
	leaveAlert: PropTypes.bool,
	className: PropTypes.string,
};

export default Alert;
