import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';

import { appActions } from 'store/actions/app.actions';
import url from "router/urls";
import { isDariahLab } from "utils/helpers";

import HeaderTopLang from "./HeaderTopLang";
import Button from "views/elements/Button/Button";

import DariahLabLogo from '@/assets/images/dariahlab/logo.svg';
import DariahLabContrast from '@/assets/images/dariahlab/contrast.svg';
import DariahLabFont from '@/assets/images/dariahlab/font.svg';
import DariahLabUE from '@/assets/images/dariahlab/ue-header.svg';

import styles from "./HeaderTopBar.module.sass";

const HeaderTopBar = ({ setLang, collapsed }) => {

	const dict = useSelector((state) => state.app.dict);
	const dispatch = useDispatch();

	let currentAppState = useSelector((state) => state.app);
	const isContrast = currentAppState.isContrast;
	const fontSize = currentAppState.fontSize;

	const setContrast = (contrastState) => {
		dispatch(appActions.setContrast(contrastState));
		document.querySelector("body").classList[contrastState ? "add" : "remove"]("contrast");
	};

	const setFontSize = (fontSizeState) => {
		switch (fontSizeState) {
			case 'font-normal':
				return (
					document.querySelector("html").classList.remove(fontSize),
					dispatch(appActions.setFontSize('font-medium'))
				);
			case 'font-medium':
				return (
					document.querySelector("html").classList.remove(fontSize),
					dispatch(appActions.setFontSize('font-big'))

				);
			case 'font-big':
				return (
					document.querySelector("html").classList.remove(fontSize),
					dispatch(appActions.setFontSize('font-normal'))
				);
			default:
				return (
					document.querySelector("html").classList.remove(fontSize),
					dispatch(appActions.setFontSize('font-medium'))
				);

		}
	};

	return (
		<div className={ `${collapsed ? styles["top-bar__collapsed"] : styles["top-bar"]
            }` }
		>
			<Container className={ styles["top-bar__container"] }>
				<Row className={` ${styles["top-bar__row"]} ${isDariahLab() && styles["top-bar__row--dariah"]} `}>
					{
						isDariahLab() && 
						<div className={ styles["top-bar__col"] }>
							<a
								className={ styles["top-bar__item"] }
								href='https://lab.dariah.pl'
								target='_blank'
								rel="noopener noreferrer"
							>
								<img 
									src={DariahLabLogo} 
									className={ styles["top-bar__logo--dariahlab"] }
									alt={ dict.headers.logoDariahAlt }
								/>
							</a>
						</div>
					}
					<div className={ styles["top-bar__col"] }>
						<Link
							className={ styles["top-bar__item"] }
							to={ url.howToTranscribe }
						>
							{dict.headers.help}
						</Link>
						{
							isDariahLab() ? (
								<div className={ styles["top-bar__item--access"] }>
									<Button
										variant='link'
										onClick={ () => setContrast(!isContrast) }
										className={ styles["top-bar__item"] }
									>
										<img 
											src={DariahLabContrast} 
											className={ styles["top-bar__item--img"] }
											alt={ dict.headers.contrast }
										/>
										{isContrast && <i className='icon-accept' />}
									</Button>

									<Button
										variant='link'
										onClick={ () => setFontSize(fontSize) }
										className={ styles["top-bar__item"] }
									>
										<img 
											src={DariahLabFont} 
											className={ styles["top-bar__item--img"] }
											alt={ dict.headers.font }
										/>
										{fontSize === 'font-medium' && <i className='icon-accept' />}
										{fontSize === 'font-big' && <i className='icon-accept-double' />}
									</Button>

									
								</div>
							) : (
								<div className={ styles["top-bar__item--access"] }>
									<Button
										variant='link'
										onClick={ () => setFontSize(fontSize) }
										className={ styles["top-bar__item"] }
									>
										{dict.headers.font}
										{fontSize === 'font-medium' && <i className='icon-accept' />}
										{fontSize === 'font-big' && <i className='icon-accept-double' />}
									</Button>

									<Button
										variant='link'
										onClick={ () => setContrast(!isContrast) }
										className={ styles["top-bar__item"] }
									>
										{dict.headers.contrast}
										{isContrast && <i className='icon-accept' />}
									</Button>
								</div>
							)
						}
						
						<div className={ styles["top-bar__item"] }>
							<HeaderTopLang setLang={ setLang } />
						</div>
						{
							isDariahLab() && 
							<div className={ styles["top-bar__item--ue"] }>
								<img 
									src={DariahLabUE} 
									className={ styles["top-bar__item--imgue"] }
									alt={ dict.headers.eu }
								/>
							</div>
						}
					</div>
				</Row>
			</Container>
		</div>
	);
};

HeaderTopBar.propTypes = {
	setLang: PropTypes.func,
	collapsed: PropTypes.bool,
};

export default HeaderTopBar;
