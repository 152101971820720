import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams} from "react-router";
import { Container } from 'react-bootstrap';
import { reverse } from 'named-urls';
import { Link } from "react-router-dom";

import url from "router/urls";
import api from "services/api";
import { appActions } from 'store/actions/app.actions';
import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";
import Error403 from "views/pages/Error/Error403";
import TranscriptionStatsItem from "./parts/TranscriptionStatsItem";

import styles from "./TranscriptionStats.module.sass";

const TranscriptionStats = () => {
	const dict = useSelector((state) => state.app.dict);

	const dispatch = useDispatch();
	const { transcriptionId } = useParams();
	const [ title, setTitle ] = useState('');
	const [ author, setAuthor ] = useState('');
	const [ isHidden, setIsHidden ] = useState(false);
	const [ viewMode, setViewMode ] = useState('read-only');
	const [ statsData, setStatsData ]  = useState([]);

	useEffect(() => {
		getTranscriptionData();
		getStatsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTranscriptionData = () => {
		dispatch(appActions.setLoading(true));

		api.get(`/transcriptions/${transcriptionId}`)
			.then(({data}) => {
				setTitle(data.titleLabel);
				setAuthor(data.authorsLabel);
				setViewMode(data.viewMode);
				setIsHidden(data.hidden);
				dispatch(appActions.setLoading(false));				
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	};

	const getStatsData = () => {
		dispatch(appActions.setLoading(true));

		api.get(`/transcriptions/${transcriptionId}/verse-statistics`)
			.then(({data}) => {
				setStatsData(data);
				dispatch(appActions.setLoading(false));				
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	};	
    
	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: dict.breadcrumbs.transcriptions, link: url.transcriptions },
		{ id: 3, label: title, link: reverse(url.transcription.details, { transcriptionId: transcriptionId }) },
		{ id: 4, label: dict.transcription.verification.transcriptionVerification, link: reverse(url.transcription.verification, { transcriptionId: transcriptionId }) },
		{ id: 5, label: dict.transcription.stats.transcribersStats},
	];
	
	return (viewMode === 'admin' || viewMode === 'verify') ? (
		<main>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<section className={ styles["stats"] }>
					<div className={ styles["stats__title--container"] }>
						<h1 className={ styles["stats__title"] }>
							{dict.transcription.stats.transcribersStats}
						</h1>
					</div>
					<div className={ styles["stats__info"] }>
						<div className={ styles["stats__info--container"] }>
							<span className={ styles["stats__info--label"] }>
								{dict.transcription.verification.title}
							</span>
							<span className={ styles["verification__info--title"] }>
								{isHidden === 'true' ?
									<i className='icon-lock-open2' />
									:
									<i className='icon-lock-close2' />}
								{title}
							</span>
						</div>
						<div className={ styles["verification__info--container"] }>
							<span className={ styles["verification__info--label"] }>
								{dict.transcription.verification.author}
							</span>
							<span className={ styles["verification__info--author"] }>
								{author}
							</span>
						</div>
					</div>

					{statsData && statsData.length > 0 ?
						<div className={ styles["stats__table"] }>
							<div className={ styles["stats__table--header"] }>
								<span>{dict.transcription.stats.userName}</span>
								<span>{dict.transcription.stats.charactersEntered}</span>
								<span>{dict.transcription.stats.transcribedVerses}</span>
								<span>{dict.transcription.stats.correctlyVerifiedVerses}</span>
							</div>
							<ul className={ styles["verification__table--content"] }>
								{statsData.map(item => (
									<TranscriptionStatsItem 
										key={ item.email }
										statsData={ item }
									/>
								))}
							</ul>
						</div>
						:
						<p className={ styles["stats__no-stats"] }>
							{dict.transcription.stats.noStats}
							<Link to={ url.howToTranscribe }>
								{dict.transcription.stats.howTo}
							</Link>
						</p>}
				</section>
			</Container>

		</main>
	) : (
		<Error403 />
	);
};

export default TranscriptionStats;
