import React from 'react';
import PropTypes from "prop-types";

import FormInput from "views/elements/Form/FormInput";

const FormInputPasswordRepeated = ({ register, dict, errors, name = 'repeatedPassword', getValues }) => (
	<FormInput
		name={ name }
		type='password'
		label={ name !== 'repeatedPassword' ? dict.form[name] : dict.form.repeatedPassword }
		register={ register }
		validateData={
			{
				required: {
					value: true,
					message: dict.form.messages.fillPassword,
				},
				validate: {
					matchesPreviousPassword: (value) => {
						if (name === 'repeatedNewPassword') {
							const { newPassword } = getValues;
							return newPassword === value || dict.form.messages.differentPasswords;
						} else {
							const { password } = getValues;
							return password === value || dict.form.messages.differentPasswords;
						}
					},
				},
			}
		}
		errorMessage={ errors[name] && errors[name].message }
	/>
);

FormInputPasswordRepeated.propTypes = {
	register: PropTypes.func,
	dict: PropTypes.object,
	errors: PropTypes.object,
	name: PropTypes.string,
	getValues: PropTypes.object,
};

export default FormInputPasswordRepeated;
