import React, { memo } from "react";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from "views/elements/Button/Button";

const ViewerOSDMenu = ( { onToggleDrawMode, osdMethods, drawMode, zoom, homeZoom } ) => {
	const dict = useSelector((state) => state.app.dict);
	const isEditingOverlay = useSelector((state) => state.viewer.ocd.selection);

	const ViewerOSDMenuItems = [
		{
			id: "toggle-draw-mode",
			tooltip: dict.viewer.tooltip.osdMenu.addArea,
			icon: "add-area",
			hideOnViewMode: true,
		},
		{
			id: "zoom-in",
			tooltip: dict.viewer.tooltip.osdMenu.zoomIn,
			icon: "zoom-in",
			hideOnViewMode: false,
			clickFunction: osdMethods.zoomInFunction,
		},
		{
			id: "zoom-out",
			tooltip: dict.viewer.tooltip.osdMenu.zoomOut,
			icon: "zoom-out",
			hideOnViewMode: false,
			clickFunction: osdMethods.zoomOutFunction,
		},
		{
			id: "full-page",
			tooltip: dict.viewer.tooltip.osdMenu.fullScreen,
			icon: "fix-to-screen",
			hideOnViewMode: false,
			clickFunction: osdMethods.fullScreen,
		},
		{
			id: "fit-to-page",
			tooltip: dict.viewer.tooltip.osdMenu.fitToPage,
			icon: "fit-to-window",
			hideOnViewMode: false,
			clickFunction: osdMethods.fitToPage,
		},
	];

	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const readOnly = viewMode === 'read-only' || viewMode === 'student';

	return (
		<ul className='ocd-toolbar viewer__menu--list'>
			{ViewerOSDMenuItems.map(item => {
				if (readOnly && item.hideOnViewMode){
					return null;
				}else{
					return (
						<li
							key={ item.id }
							className='viewer__menu--item'
							data-tip={ item.tooltip }
							data-place='right'
						>
							<Button
								id={ item.id }
								className='viewer__button--menu'
								disabled={ isEditingOverlay }
								{ ...(item.id === 'toggle-draw-mode' ? 
									{ 
										onClick: () => onToggleDrawMode(),
										variant: (drawMode) ? 'viewer-button--active' : 'viewer-button',
									} :
									{ 
										onClick: item.clickFunction,
										variant: 'viewer-button',
									})
								}
								{ ...(item.id === 'fit-to-page') ?
									{ disabled: zoom === homeZoom} : {}
								}
							>
								<span className='d-none'>${item.tooltip}</span>
								<i className={ `icon-${item.icon} viewer__menu--icon` } />
							</Button>
						</li>
					);
				}
				
			})}
		</ul>
	);
};
ViewerOSDMenu.propTypes = {
	onToggleDrawMode: PropTypes.func,
	osdMethods: PropTypes.object,
	drawMode: PropTypes.bool,
	zoom: PropTypes.number,
	homeZoom: PropTypes.number,
};

export default memo(ViewerOSDMenu);