import React from "react";
import { useSelector } from 'react-redux';

import "styles/_static-page.sass";

import transcribe1pl from 'assets/images/transcriptionHelpPage/howToTranscribe/step1pl.png';
import transcribe1en from 'assets/images/transcriptionHelpPage/howToTranscribe/step1en.png';

import transcribe2pl from 'assets/images/transcriptionHelpPage/howToTranscribe/step2pl.png';
import transcribe2en from 'assets/images/transcriptionHelpPage/howToTranscribe/step2en.png';

import transcribe3pl from 'assets/images/transcriptionHelpPage/howToTranscribe/step3pl.png';
import transcribe3en from 'assets/images/transcriptionHelpPage/howToTranscribe/step3en.png';

import transcribe4pl from 'assets/images/transcriptionHelpPage/howToTranscribe/step4pl.png';
import transcribe4en from 'assets/images/transcriptionHelpPage/howToTranscribe/step4en.png';

import transcribe5pl from 'assets/images/transcriptionHelpPage/howToTranscribe/step5pl.png';
import transcribe5en from 'assets/images/transcriptionHelpPage/howToTranscribe/step5en.png';

import transcribe6pl from 'assets/images/transcriptionHelpPage/howToTranscribe/step6pl.png';
import transcribe6en from 'assets/images/transcriptionHelpPage/howToTranscribe/step6en.png';

const HowToTranscribe = () => {

	const currentLang = useSelector((state) => state.app.currentLang);
	const dict = useSelector((state) => state.app.dict);

	const imagesWorkWithFile = {
		step1: { pl: transcribe1pl, en: transcribe1en },
		step2: { pl: transcribe2pl, en: transcribe2en },
		step3: { pl: transcribe3pl, en: transcribe3en },
		step4: { pl: transcribe4pl, en: transcribe4en },
		step5: { pl: transcribe5pl, en: transcribe5en },
		step6: { pl: transcribe6pl, en: transcribe6en },
	};

	const workWithFile = [
		{ title: 'step1title', desc: 'step1desc', image: imagesWorkWithFile.step1 },
		{ title: 'step2title', desc: 'step2desc', image: imagesWorkWithFile.step2 },
		{ title: 'step3title', desc: 'step3desc', image: imagesWorkWithFile.step3 },
		{ title: 'step4title', desc: 'step4desc', image: imagesWorkWithFile.step4 },
		{ title: 'step5title', desc: 'step5desc', image: imagesWorkWithFile.step5 },
		{ title: 'step6title', desc: 'step6desc', image: imagesWorkWithFile.step6 },
	];
    
	const workOnWindowWithObject = [
		{ icon: 'icon-add-area', text: 'addTranscriptionArea', textInfo: 'addTranscriptionAreaInfo' },
		{ icon: 'icon-zoom-in', text: 'zoomIn' },
		{ icon: 'icon-zoom-out', text: 'zoomOut' },
		{ icon: 'icon-full-screen', text: 'fullScreen'},
		{ icon: 'icon-fit-to-window', text: 'fitToWindow'},
	];

	const workWithTranscriptionWindow = [
		{ icon: 'icon-italic', text: 'italic' },
		{ icon: 'icon-bold', text: 'bold' },
		{ icon: 'icon-symbol', text: 'symbol' },
		{ icon: 'icon-quote', text: 'quote'},
		{
			icon: 'icon-list-end-1',
			text: 'listEnd',
			subIcons: [
				{ icon: 'icon-list-item-end', text: 'listElementEnd' },
				{ icon: 'icon-list-end', text: 'listEnd' },
			],
		},
		{
			icon: 'icon-verses-desc',
			text: 'page',
			subIcons: [
				{ icon: 'icon-verse-header', text: 'pageHeader' },
				{ icon: 'icon-verse-footer', text: 'pageFooter'},
				{ icon: 'icon-page-number', text: 'pageNumber'},
			],
		},
		{
			icon: 'icon-header',
			text: 'header',
			subIcons: [
				{ icon: 'icon-header', text: 'header1'},
				{ icon: 'icon-header2', text: 'header2'},
				{ icon: 'icon-header3', text: 'header3'},
			],
		},
		{
			icon: 'icon-image-description',
			text: 'image',
			subIcons: [
				{ icon: 'icon-image', text: 'image' },
				{ icon: 'icon-image-description', text: 'imageDescription' },
			],
		},
		{
			icon: 'icon-table',
			text: 'table',
			subIcons: [
				{ icon: 'icon-table-cell-end', text: 'tableCellEnd' },
				{ icon: 'icon-table-verse-end', text: 'tableVerseEnd' },
				{ icon: 'icon-table-end', text: 'tableEnd' },
			],
		},
		{ icon: 'icon-verse-auto-transcription', text: 'verseAutoTranscription'},
		{ icon: 'icon-verse-comment', text: 'verseComment'},
		{ icon: 'icon-verse-delete', text: 'verseDelete' },
		{ icon: 'icon-verse-clear', text: 'verseClear'},
		{ icon: 'icon-verse-up', text: 'verseUp' },
		{ icon: 'icon-verse-down', text: 'verseDown' },
	];
	
	return (
		<div className='static-page__content--instruction'>
			<h1>{dict.transcriptionHelpPage.howToTranscribe.title}</h1>
			<p className='static-page__content--instruction-desc'>
				{dict.transcriptionHelpPage.howToTranscribe.desc}
			</p>
            
			<section className='static-page__content--section'>
				<h2>{dict.transcriptionHelpPage.howToTranscribe.section.workWithFile}</h2>
				<ol>
					{workWithFile.map(item =>
						<li key={ item.title }>
							<h3 dangerouslySetInnerHTML={ { __html: dict.transcriptionHelpPage.howToTranscribe.steps[item.title] } } />
							{item.desc &&
							<p>{dict.transcriptionHelpPage.howToTranscribe.steps[item.desc]}</p>}
                            
							<img
								src={ item.image[currentLang] }
								alt={ item.title }
							/>
						</li>,
					)}
				</ol>
			</section>

			<section className='static-page__content--section'>
				<h2>{dict.transcriptionHelpPage.howToTranscribe.section.workOnWindow}</h2>
				<p>{dict.transcriptionHelpPage.howToTranscribe.section.workOnWindowDescription}</p>
				<ul className='static-page__content--icons-list'>
					{workOnWindowWithObject.map(item =>
						<li
							className='static-page__content--icons-item'
							key={ item.text }
						>
							<i className={ item.icon } />
							<span className='static-page__content--icons-item--text'>
								{dict.transcriptionHelpPage.howToTranscribe.icons[item.text]}
								{item.textInfo &&
								<span className='static-page__content--icons-item--subtext'>
									{dict.transcriptionHelpPage.howToTranscribe.icons[item.textInfo]}
								</span>}
							</span>
						</li>,
					)}
				</ul>
			</section>

			<section className='static-page__content--section'>
				<h2>{dict.transcriptionHelpPage.howToTranscribe.section.workWithWindow}</h2>
				<p>{dict.transcriptionHelpPage.howToTranscribe.section.workWithWindowDescription}</p>
				<ul className='static-page__content--icons-list'>
					{workWithTranscriptionWindow.map(item =>
						<li
							className='static-page__content--icons-item'
							key={ item.text }
						>
							<i className={ item.icon } />
							<span className='static-page__content--icons-item--text'>
								{ dict.transcriptionHelpPage.howToTranscribe.icons.workWithTranscriptionWindow[item.text]}
								{item.subIcons &&
								<span className='static-page__content--icons-item--subtext'>
									{dict.transcriptionHelpPage.howToTranscribe.icons.workWithTranscriptionWindow[item.textInfo]}
								</span>}
								<ul className='static-page__content--icons-item--sublist'>
									{item.subIcons && item.subIcons.map(subIcon =>
										<li key={ subIcon.text }>
											<i className={ subIcon.icon } />
											{dict.transcriptionHelpPage.howToTranscribe.icons.workWithTranscriptionWindow[subIcon.text]}
										</li>,
									)}
								</ul>
							</span>
						</li>,
					)}
				</ul>
			</section>
		</div>
	);
};

export default HowToTranscribe;
  