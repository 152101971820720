import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import url from "router/urls";

import Button from 'views/elements/Button/Button';

const SearchModuleHome = ({ placeholder }) => {
	const history = useHistory();
	const dict = useSelector((state) => state.app.dict);
	const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });

	const searchSubmit = (data) => {
		history.push(url.transcriptions + '?q=' + data.searchText);
	};

	return (
		<form
			onSubmit={ handleSubmit(searchSubmit) }
			noValidate
		>
			<label className='input-group'>
				<input
					name='searchText'
					type='text'
					placeholder={ placeholder }
					className='form-control'
					ref={ register() }
				/>
				<div className='input-group-append'>
					<Button
						type='submit'
						disabled={ !formState.dirty }
					>
						{dict.transcriptions.search.searchBt}
					</Button>
				</div>
			</label>
			
		</form>
	);
};

SearchModuleHome.propTypes = {
	placeholder: PropTypes.string,
};

export default SearchModuleHome;