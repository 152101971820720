import React from "react";
import { useSelector } from 'react-redux';
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import FormInput from "views/elements/Form/FormInput";
import FormDropdown from '../elements/FormDropdown';

import AuthorNameRoleArray from '../elements/AuthorNameRoleArray';

const TranscriptionNewAnalyticalLevel = ({ languageOptions, roleOptions, isDisabled }) => {
	const { register, errors, watch, errorMessage } = useFormContext();  // eslint-disable-line
	const dict = useSelector((state) => state.app.dict);
	const secondFieldValue = watch('monographicInfo.title');
	
	return (
		<div className='transcription-new__level'>
			<h3 className='text--level-title'>
				{dict.transcription.details.analyticLevel}
			</h3>
			<span className='text--level-desc'>
				{dict.transcription.details.analyticLevelDescription}
			</span>

			<div className='transcription-new__form--row'>
				<FormInput
					name='analyticInfo.title'
					register={ register }
					label={ dict.transcription.form.title }
					placeholder={ dict.transcription.form.titlePlaceholder }
					validateData={
						{
							validate: value => value !== '' || secondFieldValue !== '' || dict.form.messages.minumumOnetitle,
						}
					}
					isDisabled={ isDisabled }

					errorMessage={ errors.analyticInfo && errors.analyticInfo.title && errors.analyticInfo.title.message ? errors.analyticInfo.title.message : '' }
				/>
			</div>

			<AuthorNameRoleArray
				linkedObject='analyticInfo.actors'
				isDisabled={ isDisabled }
				roleOptions={ roleOptions }
			/>

			<div className='transcription-new__form--inline-row'>
				<div className='transcription-new__form--inline-select'>
					<FormDropdown
						label={ dict.transcription.form.language }
						placeholder={ dict.transcription.form.languageSelect }
						formName='analyticInfo.language'
						options={ languageOptions }
						isDisabled={ isDisabled }
					/>
				</div>
			</div>
		</div>
	);
};

TranscriptionNewAnalyticalLevel.propTypes = {
	languageOptions: PropTypes.array,
	roleOptions: PropTypes.array,
	isDisabled: PropTypes.bool,
};

export default TranscriptionNewAnalyticalLevel;