import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { authActions } from "store/actions/auth.actions";
import url from "router/urls";
import Button from "views/elements/Button/Button";

import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "styles/_navbar.sass";

const AppHeaderNav = ({ toggleNavbar, sticky }) => {
	const dict = useSelector((state) => state.app.dict);
	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const isProtected = useSelector((state) => state.auth.path.isProtected);
	const dispatch = useDispatch();

	const signOut = () => {
		dispatch(authActions.signOut(isProtected));
	};
	const location = useLocation();

	const [ collapsed ] = useState(false);
	const pathName = location.pathname;

	return (
		<Navbar
			expand='lg'
			collapseOnSelect
			className={
				collapsed ? "navbar navbar__collapsed" : "navbar"
			}
		>
			<Navbar.Toggle
				aria-controls='basic-navbar-nav'
				className='navbar__menu--hamburger'
				onClick={ toggleNavbar }
			>
				<span />
				<span />
				<span />
			</Navbar.Toggle>

			<Navbar.Collapse
				className={
					sticky ? "sticky" : ""
				}
				id='basic-navbar-nav'
			>
				<Nav className='navbar__menu'>
					<div className={ `navbar__menu--title${collapsed ? " navbar__menu--title-active" : ""}` }>
						{dict.headers.menuTitle}
					</div>

					<NavDropdown
						alignRight
						className={ `navbar__menu--dropdown ${(pathName === url.transcriptions || pathName === url.transcriptionsUser) ? "navbar__menu--dropdown-active" : ""}` }
						title={
							<span className='navbar__menu--dropdown-title'>
								{dict.headers.menu.transcriptions}
								<i className='icon-arrow-down-full' />
							</span>
						}
						id='logged-nav-dropdown'
					>
						<NavDropdown.Item
							as={ NavLink }
							to={ url.transcriptions }
							className='navbar__menu--dropdown-item'
						>
							{dict.headers.menu.allTranscriptions}
							<i className='icon-arrow-right-full' />
						</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item
							as={ NavLink }
							to={ url.transcriptionsUser }
							className='navbar__menu--dropdown-item'
						>
							{dict.headers.menu.myTranscriptions}
							<i className='icon-arrow-right-full' />
						</NavDropdown.Item>
					</NavDropdown>
					<Nav.Link
						as={ NavLink }
						className='navbar__menu--item'
						to={ url.transcription.new }
					>
						{dict.headers.newTranscription}
					</Nav.Link>

					{ isLoggedIn ? (
						<NavDropdown
							alignRight
							className={ `
								${(pathName === url.auth.account)
								? "navbar__menu--dropdown-active" : ""
								}
								navbar__menu--dropdown
							` }
							title={
								<span className='navbar__menu--dropdown-title'>
									{dict.headers.menu.myAccount}
									<i className='icon-arrow-down-full' />
								</span>
							}
							id='basic-nav-dropdown'
						>
							<NavDropdown.Item
								as={ NavLink }
								to={ url.auth.account }
								className='navbar__menu--dropdown-item'
							>
								{dict.headers.menu.myProfile}
								<i className='icon-arrow-right-full' />
							</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item
								onClick={ () => signOut() }
								className='navbar__menu--dropdown-item'
							>
								{dict.headers.menu.logOut}
								<i className='icon-arrow-right-full' />
							</NavDropdown.Item>
						</NavDropdown>
					) : (
						<NavDropdown
							alignRight
							className='navbar__menu--login'
							title={
								<span className='navbar__menu--dropdown-title'>
									{dict.headers.menu.logIn}
									<i className='icon-arrow-down-full' />
								</span>
							}
							id='basic-nav-dropdown'
						>
							<Button
								variant='primary'
								href={ url.auth.login }
							>
								{dict.headers.menu.logIn}
							</Button>
							<Navbar.Text className='navbar__text navbar__text--black navbar__text--nowrap'>
								{dict.headers.menu.noAccount}
								<a
									className='navbar__text--link'
									href={ url.auth.register }
								>
									{dict.headers.menu.createAccount}
								</a>
							</Navbar.Text>
						</NavDropdown>
					)}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

AppHeaderNav.propTypes = {
	sticky: PropTypes.bool,
	toggleNavbar: PropTypes.func,
};

export default AppHeaderNav;
