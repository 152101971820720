import React from 'react';
import PropTypes from "prop-types";

import CommonDropdownForm from 'views/elements/Dropdown/CommonDropdownForm';

const FormDropdown = ({ label, formName, options, placeholder, isDisabled, name }) => {
	return (
		<>
			<CommonDropdownForm
				options={ options }
				fieldName={ formName }
				isDisabled={ isDisabled }
				placeholder={ placeholder }
				label={ label }
				name={ name }
			/>
		</>
	);
};
FormDropdown.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	formName: PropTypes.string,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	isDisabled: PropTypes.bool,
};

export default FormDropdown;