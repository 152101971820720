import React from 'react';
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import ProgressBar from 'views/elements/ProgressBar/ProgressBar';
import styles from './TranscriptionsItem.module.sass';

const TranscriptionsProgress = ({percentOfVerified = 0, percentOfTranscribed = 0}) => {
	const dict = useSelector(state => state.app.dict);
	return (
		<div className={ styles["transcr-item__description--progress"] }>
			{(percentOfVerified || percentOfVerified > 0) ?
				(
					<div className={ styles["transcr-item__description--progress-wrapper"] }>
						<div className={ styles["transcr-item__description--progress-text"] }>
							{dict.transcriptions.description.verificationAdvance}
						</div>
						<div className={ styles["transcr-item__description--progress-bar"] }>
							<ProgressBar progress={ percentOfVerified } />
						</div>
						<div className={ styles["transcr-item__description--progress-text"] }>
							{percentOfVerified}%
						</div>
					</div>
				) : null}
			{(percentOfTranscribed || percentOfTranscribed === 0) ? (
				<div className={ styles["transcr-item__description--progress-wrapper"] }>
					<div className={ `${styles["transcr-item__description--progress-text"]} ${styles["transcr-item__description--progress-text-space"]}` }>
						{dict.transcriptions.description.transcriptionAdvance}
					</div>
					<div className={ styles["transcr-item__description--progress-bar"] }>
						<ProgressBar progress={ percentOfTranscribed } />
					</div>
					<div className={ styles["transcr-item__description--progress-text"] }>
						{percentOfTranscribed}%
					</div>
				</div>
			) : null}

		</div>
	);
};

TranscriptionsProgress.propTypes = {
	percentOfVerified: PropTypes.number,
	percentOfTranscribed: PropTypes.number,
};

export default TranscriptionsProgress;