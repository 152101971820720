import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import url from "router/urls";

import styles from "../TranscriptionDetails.module.sass";

const TranscriptionDetailsBackLink = () => {
	const dict = useSelector((state) => state.app.dict);
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	return (
		<div>
			{(isLoggedIn) &&
				<div>
					<div className={ styles["transcription__backLink"] }>
						{dict.transcription.details.returnToMyTranscripts1}
						<Link to={ url.transcriptionsUser }>
							{dict.transcription.details.returnToMyTranscripts2}
						</Link>
					</div>
					
				</div>}
		</div>
	);
};

export default TranscriptionDetailsBackLink;
