import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation, useHistory, useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { reverse } from 'named-urls';

import url from "router/urls";
import api from "services/api";
import { appActions } from 'store/actions/app.actions';

import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";
import Error403 from "views/pages/Error/Error403";
import Pagination from 'views/components/Pagination/Pagination';
import TranscriptionVerificationItem from "./parts/TranscriptionVerificationItem";

import styles from "./TranscriptionVerification.module.sass";

const TranscriptionVerification = () => {
	const dict = useSelector((state) => state.app.dict);
	const history = useHistory();
	const location = useLocation();

	const dispatch = useDispatch();
	const { transcriptionId } = useParams();
	const [ title, setTitle ] = useState('');
	const [ isHidden, setIsHidden ] = useState(false);
	const [ viewMode, setViewMode ] = useState('read-only');
	const [ verificationData, setVerificationData ]  = useState([]);
	const [ verificationFiles, setVerificationFiles ]  = useState([]);
	const [ verificationCurrentPage, setVerificationCurrentPage ] = useState(0);
	const [ verificationPagesNum, setVerificationPagesNum ] = useState(1);

	useEffect(() => {
		getTranscriptionData();
	}, []);

	const getTranscriptionData = () => {
		dispatch(appActions.setLoading(true));

		api.get(`/transcriptions/${transcriptionId}`)
			.then(({data}) => {				
				setTitle(data.titleLabel);
				setViewMode(data.viewMode);
				setIsHidden(data.hidden);
				dispatch(appActions.setLoading(false));				
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	};

	useEffect(() => {
		if (verificationCurrentPage === 0) return;
		dispatch(appActions.setLoading(true));

		api.get(`/transcriptions/${transcriptionId}/statistics`, { params: { page: verificationCurrentPage, perPage: 50 } })
			.then(({data}) => {
				setVerificationData(data);
				setVerificationFiles(data.paginatedPagesStatistics.pageStatisticsList);
				setVerificationCurrentPage(data.paginatedPagesStatistics.page);
				setVerificationPagesNum(data.paginatedPagesStatistics.pages);
				if (verificationCurrentPage > data.paginatedPagesStatistics.pages) {
					goPage(data.paginatedPagesStatistics.pages);
				}				
				dispatch(appActions.setLoading(false));				
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	}, [ verificationCurrentPage ]);

	const goPage = (pageNum) => {
		const linkToTranscriptionVerification = reverse(url.transcription.verification, { transcriptionId: parseInt(transcriptionId) });
		history.push(linkToTranscriptionVerification + '?page=' + pageNum);
	};

	useEffect(() => {
		const pageNum = new URLSearchParams(location.search).get("page");
		if (!pageNum || pageNum < 1) {
			const linkToTranscriptionVerification = reverse(url.transcription.verification, { transcriptionId: parseInt(transcriptionId) });
			history.replace(linkToTranscriptionVerification + '?page=1');
		} else {
			setVerificationCurrentPage(parseInt(pageNum));
		}
	}, [ location ]);
    
	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: dict.breadcrumbs.transcriptions, link: url.transcriptions },
		{ id: 3, label: title, link: reverse(url.transcription.details, { transcriptionId: transcriptionId }) },
		{ id: 4, label: dict.transcription.verification.transcriptionVerification },
	];

	return (viewMode === 'admin' || viewMode === 'verify') ? (
		<main>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<section className={ styles["verification"] }>
					<div className={ styles["verification__title--container"] }>
						<h1 className={ styles["verification__title"] }>
							{dict.transcription.verification.transcriptionVerification}
						</h1>
						<Link
							className={ styles["verification__stats-link"] }
							to={ reverse(url.transcription.stats, { transcriptionId: transcriptionId }) }
						>
							{dict.transcription.verification.stats}
						</Link>
					</div>
					<div className={ styles["verification__info"] }>
						<div className={ styles["verification__info--container"] }>
							<span className={ styles["verification__info--label"] }>
								{dict.transcription.verification.title}
							</span>
							<span className={ styles["verification__info--title"] }>
								{isHidden === 'true' ?
									<i className='icon-lock-open2' />
									:
									<i className='icon-lock-close2' />}
								{title}
							</span>
						</div>
						<div className={ styles["verification__info--container"] }>
							<span className={ styles["verification__info--label"] }>
								{dict.transcription.verification.author}
							</span>
							<span className={ styles["verification__info--author"] }>
								{verificationData.authorsLabel}
							</span>
						</div>
					</div>
					<div className={ styles["verification__advancement"] }>
						<span className={ styles["verification__advancement--label-main"] }>
							{dict.transcription.verification.advancement.main}
						</span>
						<div className={ styles["verification__advancement--label-container"] }>
							<span className={ styles["verification__advancement--label"] }>
								{dict.transcription.verification.advancement.toVerify}
							</span>
							<span className={ styles["verification__advancement--value"] }>
								{verificationData.filesToVerification}
							</span>
						</div>

						<div className={ styles["verification__advancement--label-container"] }>
							<span className={ styles["verification__advancement--label"] }>
								{dict.transcription.verification.advancement.verified}
							</span>
							<span className={ styles["verification__advancement--value"] }>
								{verificationData.verifiedVerses}
                                
								<span className={ styles["verification__advancement--value-all"] }>
									/ {verificationData.allVerses}
								</span>
							</span>
						</div>

						<div className={ styles["verification__advancement--label-container"] }>
							<span className={ styles["verification__advancement--label"] }>
								{dict.transcription.verification.advancement.correct}
							</span>
							<span className={ styles["verification__advancement--value"] }>
								<span className={ styles["verification__advancement--value-correct"] }>
									{verificationData.versesMarkedAsVerified}
									<i className='icon-accept-double' />
								</span>
							</span>
						</div>

						<div className={ styles["verification__advancement--label-container"] }>
							<span className={ styles["verification__advancement--label"] }>
								{dict.transcription.verification.advancement.incorrect}
							</span>
							<span className={ styles["verification__advancement--value"] }>
								<span className={ styles["verification__advancement--value-incorrect"] }>
									{verificationData.versesMarkedAsIncorrect}
									<i className='icon-close' />
								</span>
							</span>
						</div>
					</div>

					<div className={ styles["verification__table"] }>
						{(verificationFiles) && (verificationFiles.length > 0) && (
							<Pagination
								onGoToPage={ goPage }
								currentPage={ verificationCurrentPage }
								pagesNum={ verificationPagesNum }
							>
								<div>
									<div className={ styles["verification__table--header"] }>
										<span>{dict.transcription.verification.table.number}</span>
										<span>{dict.transcription.verification.table.name}</span>
										<span>{dict.transcription.verification.table.versesNumber}</span>
										<span>{dict.transcription.verification.table.verified}</span>
										<span>{dict.transcription.verification.table.correct}</span>
										<span>{dict.transcription.verification.table.incorrect}</span>
										<span>{dict.transcription.verification.table.lastEdition}</span>
										<span className={ styles["verification__table--header-verification"] }>
											{dict.transcription.verification.table.verification}
										</span>
									</div>
									<ul className={ styles["verification__table--content"] }>
										{verificationFiles.map(item => (
											<TranscriptionVerificationItem 
												verification={ item }
												key={ item.pageNo }
											/>
										))}
									</ul>
								</div>
							</Pagination>
						)}
					</div>
				</section>
			</Container>

		</main>
	) : (
		<Error403 />
	);
};

export default TranscriptionVerification;
