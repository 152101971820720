import React from "react";
import { useSelector } from 'react-redux';

import { Container, Row, Col } from "react-bootstrap";
import Button from 'views/elements/Button/Button';

import url from "router/urls";

import styles from "./AppFooter.module.sass";

const AppFooter = () => {
	const dict = useSelector((state) => state.app.dict);
	const questionnaireLink = useSelector((state) => state.app.questionnaireLink);
    
	return (
		<footer className={ styles["footer"] }>
			<Container>
				<Row className={ styles["footer__block"] }>
					<Col
						xl={ 6 }
						className={ styles["footer__block--left"] }
					>
						<ul className={ styles["footer__links"] }>
							<li className={ styles["footer__links-item"] }>
								<Button
									variant='link-onblack'
									href={ url.privacyPolicy }
									className={ styles["footer__links-anchor"] }
								>
									{dict.footer.links.privacyPolicy}
								</Button>
							</li>
							<li className={ styles["footer__links-item"] }>
								<Button
									variant='link-onblack'
									href={ url.contact }
									className={ styles["footer__links-anchor"] }
								>
									{dict.footer.links.contact}
								</Button>
							</li>
							{ questionnaireLink && 
								<li className={ styles["footer__links-item"] }>
									<Button
										variant='link-onblack'
										className={ styles["footer__links-anchor"] }
										href={ questionnaireLink }
										target='_blank'
									>
										{dict.footer.links.shareYourOpinion}
									</Button>
								</li>}
						</ul>
					</Col>
					<Col
						xl={ 6 }
						className={ styles["footer__block--right"] }
					>
						<div className={ styles["footer__rights"] }>
							<span>
								{dict.footer.rights.copyright} &copy; 2020 {dict.footer.rights.wlt} {dict.footer.rights.createdBy} 
								<Button
									variant='link-external-onblack'
									href={ dict.footer.rights.pcss.url }
									className={ styles["footer__rights--link"] }
									target='_blank'
								>
									{dict.footer.rights.pcss.title}<i className='icon-external' />
								</Button>
							</span>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default AppFooter;