import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import PropTypes from "prop-types";
import { useShowModal } from 'views/elements/Modal/ModalHooks';

import api from "services/api";
import { appActions } from 'store/actions/app.actions';

import FormSubmit from "views/elements/Form/FormSubmit";
import FormInputCustom from "views/elements/Form/FormInputCustom";

import TranscriptionPermissionsUserItem from "./TranscriptionPermissionsUserItem";

import styles from "../TranscriptionPermissions.module.sass";

const TranscriptionPermissionsUsers = ({ users, getTranscriptionPermissionsData }) => {

	const dict = useSelector((state) => state.app.dict);
	const { transcriptionId } = useParams();
	const dispatch = useDispatch();
	const modalConfirmInfo = useSelector((state) => state.app.modalConfirmInfo);
	const [ isSendingEmail, setIsSendingEmail ] = useState(false);
	const [ deleteUserId, setDeleteUserId ] = useState(null);

	const defaultValues = {
		email: '',
	};

	const { register, handleSubmit, formState, errors, setError, reset } = useForm({
		defaultValues: defaultValues,
		mode: 'onChange',
	});

	const modalDeleteUserFromTranscription = useShowModal({
		title: dict.modals.permissionsDeleteUser.title,
		name: 'deleteGroup',
		componentPath: './parts/ModalDeleteUserFromTranscription',
	});

	const onDeleteUser = (userId) => {
		setDeleteUserId(userId);
		modalDeleteUserFromTranscription();
	};

	useEffect(() => {
		if (modalConfirmInfo === "USER_DELETE_CONFIRMED") {
			api.delete(`/transcriptions/${transcriptionId}/allowed-users`, { data: { id: deleteUserId } })
				.then(() => {
					getTranscriptionPermissionsData();
				});
		}
		dispatch(appActions.setModalConfirmInfo(null));
	}, [ modalConfirmInfo ]);

	const onAddUserSubmit = (data) => {
		setIsSendingEmail(true);
		api.post(`/transcriptions/${transcriptionId}/allowed-users`,{ email: data.email})
			.then(() => {
				reset();
				setIsSendingEmail(false);
				getTranscriptionPermissionsData();
			}).catch((error) => {
				const errorCode = error.data.code.split('.')[2];
				setError('email', errorCode, dict.form.messages[errorCode]);
				setIsSendingEmail(false);
			});
	};

	return (
		<div>
			<h2 className='text--header'>{dict.transcription.permissions.shareObject}</h2>
			<div>
				<h3 className={ styles["permissions__additional-text"] }>{dict.transcription.permissions.users}</h3>
				{users && (
					users.map((user, index) => (
						<TranscriptionPermissionsUserItem
							orderNum={ parseInt(index) }
							key={ user.user.email }
							email={ user.user.email }
							userId={ parseInt(user.user.id) }
							role={ user.role.code }
							getTranscriptionPermissionsData={ getTranscriptionPermissionsData }
							onDeleteUserProps={ onDeleteUser }
						/>
					))
				)}

			</div>
			<h3 className={ styles["permissions__additional-text"] }>{dict.transcription.permissions.addUserTitle}</h3>
			<p className='text--small'>{dict.transcription.permissions.addUserInfo}</p>
			<div className={ styles["permissions__add--container"] }>
				<form
					className={ styles["permissions__add--form"] }
					onSubmit={ handleSubmit(onAddUserSubmit) }
				>
					<FormInputCustom
						name='email'
						register={ register }
						dict={ dict }
						errors={ errors }
						placeholder={ dict.transcription.permissions.addUserPlaceholder }
						validateData={ {
							pattern: {
								value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
								message: dict.form.messages.invalidEmail,
							},
						} }
						errorMessage={ errors.email && errors.email.message }
						inputContainerClassName={ styles["permissions__add--form-input"] }
					/>
					<FormSubmit
						variant='submit'
						className={ styles["permissions__add--form-submit"] }
						label={ dict.transcription.permissions.addUserButton }
						disabled={ isSendingEmail || !formState.dirty || !formState.isValid }
					/>
				</form>
			</div>

		</div>
	);
};
TranscriptionPermissionsUsers.propTypes = {
	users: PropTypes.array,
};

TranscriptionPermissionsUsers.propTypes = {
	users: PropTypes.array,
	getTranscriptionPermissionsData: PropTypes.func,
};

export default TranscriptionPermissionsUsers;
