import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';

import { viewerActions } from 'store/actions/viewer.actions';

const VerseStatus = ({ verse }) => {
	const dispatch = useDispatch();
	const dict = useSelector((state) => state.app.dict);
	const isMobile = useSelector((state) => state.viewer.isMobile);
	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const readOnly = viewMode === 'read-only' || viewMode === 'student';

	const [ isTooltip, setIsTooltip ] = useState(false);

	const changeStatus = () => {
		const statuses = viewMode === 'transcribe' ? [ "none", "transcribed" ] : [ "transcribed", "verified", "incorrect" ];
		const index = statuses.indexOf(verse.status) >= 0 ? statuses.indexOf(verse.status) : 0;
		const nextStatus = (statuses[index + 1]) ? statuses[index + 1] : statuses[0];

		dispatch(viewerActions.submitVerseStatus(verse.id, nextStatus));
	};

	return (
		<div className='verse__status'>
			{(isTooltip && (viewMode === 'verify' || viewMode === 'admin'))   && ( 
				<div className='verse__tooltip--status'>
					<div><div className='verse__tooltip--accept-double'><i className='icon-accept' /><i className='icon-accept' /></div> {dict.viewer.tooltip.status.click1}</div>
					<div><div className='verse__tooltip--decline'><i className='icon-close' /></div> {dict.viewer.tooltip.status.click2}</div>
					<div><div className='verse__tooltip--accept'><i className='icon-accept' /></div> {dict.viewer.tooltip.status.click3}</div>
				</div>
			)}
			<button
				className={ `verse__checkbox${!readOnly && !isMobile ? ' verse__checkbox--active' : ''}` }
				onClick={ changeStatus }
				onMouseEnter={ () => setIsTooltip(true) }
				onMouseLeave={ () => setIsTooltip(false) }
				disabled={ viewMode === 'transcribe' && verse.status === 'verified' }
			>
				<span className='d-none'>Status</span>
				{verse.status === 'transcribed' &&
					<div className='verse__checkbox--accept'>
						<i className='icon-accept' />
					</div>}

				{verse.status === 'verified' &&
					<div className='verse__checkbox--accept-double'>
						<i className='icon-accept' />
						<i className='icon-accept' />
					</div>}

				{verse.status === 'incorrect' &&
					<div className='verse__checkbox--decline'>
						<i className='icon-close' />
					</div>}

			</button>
		</div>
	);
};

VerseStatus.propTypes = {
	verse: PropTypes.object,
};

export default memo(VerseStatus);