import React, { Suspense, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Switch, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGA from 'react-ga';
import ReactTooltip from 'react-tooltip';

import { appActions } from "store/actions/app.actions";
import { authActions } from "store/actions/auth.actions";

import routes from "router/routes";
import AppRoute from "./AppRoute";

import Cookies from "views/components/Cookies/Cookies";
import LoadingBar from "views/components/LoadingBar/LoadingBar";
import Spinner from "views/elements/Spinner/Spinner";

import AppHeader from "views/templates/header/AppHeader/AppHeader";
import AuthHeader from "views/templates/header/AuthHeader/AuthHeader";
import AppFooter from "views/templates/footer/AppFooter/AppFooter";
import Error500 from "views/pages/Error/Error500";
import Error404 from "views/pages/Error/Error404";

const googleAnalyticsID = process.env.REACT_APP_GA_TAG_ID;

const App = () => {

	const dispatch = useDispatch();
	const location = useLocation();
	const currentAppState = useSelector((state) => state.app);
	const isContrast = currentAppState.isContrast;
	const currentLang = useSelector((state) => state.app.currentLang);
	const dict = useSelector((state) => state.app.dict);

	useEffect(() => {
		document.title = dict.title;
	}, [ currentLang ]);

	const isFontSize = currentAppState.fontSize;

	useEffect(() => {
		dispatch(appActions.saveCookie());
	}, [ currentAppState ]);

	useEffect(() => {
		ReactGA.initialize(googleAnalyticsID);
		ReactGA.pageview(location.pathname + location.search);
		ReactTooltip.rebuild();
	}, [ location.pathname ]);

	useEffect(() => {
		if (location.search) {
			const email = new URLSearchParams(location.search).get("email");
			if (email) {
				dispatch(authActions.fedSignIn(email, location.pathname));
			}
		}

		dispatch(appActions.getOCRProfiles());
		dispatch(authActions.checkAuth());
		dispatch(appActions.getQuestionnaireLink());
	}, []);

	useEffect(() => {
		document.querySelector("body").classList[isContrast ? "add" : "remove"]("contrast");
	}, [ isContrast ]);

	useEffect(() => {
		document.querySelector("html").classList.add(isFontSize);
	}, [ isFontSize ]);

	return (
		<>
			{(currentAppState.isError || currentAppState.isNotFound) ? (
				<>
					{currentAppState.isError &&
						<>
							<AuthHeader />
							<Error500 />
						</>}
					{currentAppState.isNotFound &&
						<>
							<AppHeader />
							<Error404 />
							<AppFooter />
						</>}
				</>
			) : (
				<>
					<LoadingBar />
					<Suspense fallback={ <Spinner /> }>
						<Switch>
							{routes.map(route => (
								<AppRoute
									{ ...route }
									key={ route.id }
								/>
							))}
						</Switch>
					</Suspense>
				</>

			)}

			{currentAppState.cookiePermission !== true &&
				<Cookies />}

			<ReactTooltip />
		</>
	);
};

App.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			path: PropTypes.string,
			exact: PropTypes.bool,
			component: PropTypes.object,
		}).isRequired,
	),
};

export default App;