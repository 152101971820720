import React from "react";
import PropTypes from 'prop-types';

import LoadingBar from "react-redux-loading-bar";

import styles from "./utils/loadingBarStyles";

const LoadingBarCustom = ({ variant = "main" }) => (
	<LoadingBar
		showFastActions
		style={ styles[ variant ] }
		updateTime={ 100 }
		maxProgress={ 100 }
		progressIncrease={ 10 }
	/>
);

LoadingBarCustom.propTypes = {
	variant: PropTypes.string,
};

export default LoadingBarCustom;
