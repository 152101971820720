import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { reverse } from 'named-urls';
import { Dropdown, Container } from 'react-bootstrap';

import url from "router/urls";

import Button from 'views/elements/Button/Button';
import Pagination from 'views/components/Pagination/Pagination';
import TranscriptionsItemPage from 'views/elements/Transcriptions/TranscriptionsItemPage';
import TranscriptionsProgress from 'views/elements/Transcriptions/TranscriptionsProgress';

import { useShowModal } from 'views/elements/Modal/ModalHooks';

import styles from "../TranscriptionDetails.module.sass";

const TranscriptionDetailsPages = ({ 
	pages, 
	percentOfTranscribed,
	percentOfVerified, 
	lastModificationDate, 
	onGoToPage, 
	viewMode, 
	creationMode, 
	transcriptionId, 
	transcriptionStatusOCRDisabledButtons, 
	transcriptionStatusOCRProcessingInfo, 
}) => {
	const dict = useSelector(state => state.app.dict);
	const isLoggedIn = useSelector((state) => state.auth.credentials.isLoggedIn);
	const [ currentPageNum, setCurrentPageNum ] = useState(0);
	const [ pagesNum, setPagesNum ] = useState(1);
	const [ overallTransciptionsNum, setOverallTransciptionsNum ] = useState(0);
	const [ pagesList, setPagesList ] = useState([]);

	const modalAutoTranscribePages = useShowModal({
		title: dict.modals.autoTranscribePages.title,
		name: 'autoTranscribePages',
		componentPath: './parts/ModalAutoTranscribePages',
		componentProps: { transcriptionId: parseInt(transcriptionId) },
	});

	useEffect(() => {
		setCurrentPageNum(pages.page);
		setPagesNum(pages.pages);
		setOverallTransciptionsNum(pages.hits);
		setPagesList(pages.list);
	}, [ pages ]);

	const modalDownloadResult = useShowModal({
		title: dict.modals.downloadResults.title,
		name: 'downloadResultsPage',
		type: 'download',
		componentPath: './parts/ModalDownloadResults',
		componentProps: { transcriptionId: parseInt(transcriptionId) },
		withClose: true,
	});

	return (
		<div className={ styles["pages-list__wrapper"] }>
			<Container>
				<section className={ styles["pages-list"] }>
					{ transcriptionStatusOCRProcessingInfo && <div className={ styles["pages-list__alert"] }>
						{dict.transcription.alert.processingOCR}
					</div> }
					<div className={ styles["pages-list__head"] }>
						<h1 className={ styles["pages-list__header"] }>
							{dict.transcription.details.transcriptionPages}
							<span className={ styles["pages-list__header--thin"] }> ({overallTransciptionsNum})</span>
						</h1>
						
						<div className={ styles["transcription__desktopNav"] }>
							<div
								className='btn-group'
								role='group'
							>
								{isLoggedIn &&
									<>
										<Button
											variant='secondary'
											href={ reverse(url.transcription.addPages, { transcriptionId: parseInt(transcriptionId) }) }
											disabled={ !(viewMode === 'admin' && creationMode === 'web-form') || transcriptionStatusOCRDisabledButtons }
										>{dict.transcription.details.addNewPages}
										</Button>
										<Button
											variant='secondary'
											onClick={ () => modalAutoTranscribePages() }
											disabled={ !((viewMode === 'admin' || viewMode === 'verify') && overallTransciptionsNum > 0) || transcriptionStatusOCRDisabledButtons }
										>
											{dict.transcription.details.automaticallyTranscribe}
										</Button>
										<Button
											variant='secondary'
											href={ reverse(url.transcription.addText, { transcriptionId: parseInt(transcriptionId) }) }
											disabled={ !((viewMode === 'admin' || viewMode === 'verify') && overallTransciptionsNum > 0) || transcriptionStatusOCRDisabledButtons }

										>
											{dict.transcription.details.loadText}
										</Button>
										<Button
											variant='secondary'
											href={ reverse(url.transcription.verification, { transcriptionId: parseInt(transcriptionId) }) }
											disabled={ !((viewMode === 'admin' || viewMode === 'verify') && overallTransciptionsNum > 0) }
										>
											{dict.transcription.details.verify}
										</Button>
									</>}
								<Button
									variant='secondary'
									disabled={ !(percentOfTranscribed > 0) && !(pages.count > 0) }
									onClick={ (e) => { 
										e.target.blur();
										modalDownloadResult();
									} }
								>{dict.transcription.details.downloadResults}
								</Button>
							</div>
						</div>
					</div>
					<div className={ styles["transcription__mobileNav"] }>
						<Dropdown>
							<Dropdown.Toggle
								variant='dropdown-secondary'
								id='details'
							>
								<i className='icon-hamburger' /> {dict.transcription.details.workWithPages} <i className='icon-arrow-down-full' />
							</Dropdown.Toggle>
							<Dropdown.Menu className={ styles["transcription__mobileNav--dropdown-menu"] }>
								{(viewMode === 'admin' && creationMode === 'web-form') &&
									<Dropdown.Item href={ reverse(url.transcription.addPages, { transcriptionId: parseInt(transcriptionId) }) }>{dict.transcription.details.addNewPages}</Dropdown.Item>}
								{((viewMode === 'admin' || viewMode === 'verify') && overallTransciptionsNum > 0) &&
									<>
										<Dropdown.Item onClick={ () => modalAutoTranscribePages() }>{dict.transcription.details.automaticallyTranscribe}</Dropdown.Item>
										<Dropdown.Item href={ reverse(url.transcription.addText, { transcriptionId: parseInt(transcriptionId) }) }>{dict.transcription.details.loadText}</Dropdown.Item>
										<Dropdown.Item>{dict.transcription.details.verify}</Dropdown.Item>
									</>}
								{(percentOfTranscribed > 0) && (pages.count > 0) &&
									<Dropdown.Item onClick={ () => modalDownloadResult() }>{dict.transcription.details.downloadResults}</Dropdown.Item>}
							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div className={ `${styles["pages-list__modification-date"]}` }>
						{dict.transcriptions.description.lastEdited}{lastModificationDate}
					</div>

					<TranscriptionsProgress
						percentOfVerified={ percentOfVerified }
						percentOfTranscribed={ percentOfTranscribed }
					/>

					{(pagesList) && (pagesList.length > 0) && (
						<Pagination
							currentPage={ currentPageNum }
							pagesNum={ pagesNum }
							onGoToPage={ onGoToPage }
						>
							<div className={ `${styles["pages-list__grid"]}` }>
								{
									pagesList.map((page) => (
										<TranscriptionsItemPage
											key={ page.id }
											page={ page }
											viewMode={ viewMode }
											creationMode={ creationMode }
											isInTranscriptionDetails
											transcriptionId={ transcriptionId }
											currentPage={ currentPageNum }
											transcriptionStatusOCRDisabledButtons={ transcriptionStatusOCRDisabledButtons }
										/>
									))
								}
							</div>
						</Pagination>
					)}
				</section>
			</Container>
		</div>
	);
};

TranscriptionDetailsPages.propTypes = {
	pages: PropTypes.object,
	percentOfTranscribed: PropTypes.number,
	percentOfVerified: PropTypes.number,
	lastModificationDate: PropTypes.string,
	onGoToPage: PropTypes.func,
	viewMode: PropTypes.string,
	creationMode: PropTypes.string,
	transcriptionId: PropTypes.number,
};

export default TranscriptionDetailsPages;