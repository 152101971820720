const appInitialState = {

	langs: [
		{
			id: 'pl',
			name: 'PL',
		},
		{
			id: 'en',
			name: 'EN',
		},
	],
	currentLang: 'pl',
	isContrast: false,
	isLoading: false,
	isError: false,
	isNotFound: false,
	fontSize: 'font-normal',
	cookiePermission: false,
	dict: {},
	viewer: {
		togglePanels: {
			osd: false,
			editor: false,
		},
		swapPanels: false,
		mobilePanels: 'osd',
	},
	OCRProfiles: [],
	modalConfirmInfo: "",
	modalDeleteTranscriptionPage: null,
	modalCommentDeleteCommentId: null,
	importedTranscription: null,
	questionnaireLink: null,
};

export default appInitialState;