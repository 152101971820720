import React from "react";
import { useSelector } from 'react-redux';

import "styles/_static-page.sass";

import assignVerifierRole1pl from 'assets/images/transcriptionHelpPage/howToVerify/assignVerifierRole1pl.png';
import assignVerifierRole1en from 'assets/images/transcriptionHelpPage/howToVerify/assignVerifierRole1en.png';

import assignVerifierRole2pl from 'assets/images/transcriptionHelpPage/howToVerify/assignVerifierRole2pl.png';
import assignVerifierRole2en from 'assets/images/transcriptionHelpPage/howToVerify/assignVerifierRole2en.png';

import startVeryficationSteps1pl from 'assets/images/transcriptionHelpPage/howToVerify/startVeryficationSteps1pl.png';
import startVeryficationSteps1en from 'assets/images/transcriptionHelpPage/howToVerify/startVeryficationSteps1en.png';

import startVeryficationSteps2pl from 'assets/images/transcriptionHelpPage/howToVerify/startVeryficationSteps2pl.png';
import startVeryficationSteps2en from 'assets/images/transcriptionHelpPage/howToVerify/startVeryficationSteps2en.png';

const HowToVerify = () => {

	const currentLang = useSelector((state) => state.app.currentLang);
	const dict = useSelector((state) => state.app.dict);

	const imagesAssignVerifierRole = {
		step1: { pl: assignVerifierRole1pl, en: assignVerifierRole1en },
		step2: { pl: assignVerifierRole2pl, en: assignVerifierRole2en },
	};
    
	const assignVerifierRoleSteps = [
		{ title: 'step1', image: imagesAssignVerifierRole.step1 },
		{ title: 'step2', image: imagesAssignVerifierRole.step2 },
	];

	const imagesStartVeryficationSteps = {
		step1: { pl: startVeryficationSteps1pl, en: startVeryficationSteps1en },
		step2: { pl: startVeryficationSteps2pl, en: startVeryficationSteps2en },
	};

	const startVeryficationSteps = [
		{ title: 'step1', image: imagesStartVeryficationSteps.step1 },
		{ title: 'step2', image: imagesStartVeryficationSteps.step2 },
	];

	const veryficationIcons = [
		{ icon: 'icon-accept green', text: 'correct'},
		{ icon: 'icon-close red', text: 'incorrect' },
		{ icon: 'empty', text: 'untranscribed' },
	];

	const selectionIcons = [
		{ icon: '<i class="icon-accept-double green" />', text: 'firstClick'},
		{ icon: '<i class="icon-close red" />', text: 'secondClick' },
		{ icon: '<i class="icon-accept green"></i><i class="icon-close red"></i><i class="empty"></i>', text: 'thirdClick' },
	];
	
	return (
		<div className='static-page__content--instruction'>
			<h1>{dict.transcriptionHelpPage.howToVerify.title}</h1>
			<p className='static-page__content--instruction-desc'>
				{dict.transcriptionHelpPage.howToVerify.desc}
			</p>
            
			<section className='static-page__content--section'>
				<h2>{dict.transcriptionHelpPage.howToVerify.section.howToAssignRole}</h2>
				<ol>
					{assignVerifierRoleSteps.map(item =>
						<li key={ item.title }>
							<h3 dangerouslySetInnerHTML={ { __html: dict.transcriptionHelpPage.howToVerify.assignRolesSteps[item.title] } } />
							<img
								src={ item.image[currentLang] }
								alt={ item.title }
							/>
						</li>,
					)}
				</ol>
			</section>

			<section className='static-page__content--section'>
				<h2>{dict.transcriptionHelpPage.howToVerify.section.howToStart}</h2>
				<ol>
					{startVeryficationSteps.map(item =>
						<li key={ item.title }>
							<h3 dangerouslySetInnerHTML={ { __html: dict.transcriptionHelpPage.howToVerify.startVeryficationSteps[item.title] } } />
							<img
								src={ item.image[currentLang] }
								alt={ item.title }
							/>
						</li>,
					)}
				</ol>
			</section>

			<section className='static-page__content--section'>
				<h2>{dict.transcriptionHelpPage.howToVerify.section.verification}</h2>
				<p>{dict.transcriptionHelpPage.howToVerify.section.verificationDescription1}</p>

				<ul className='static-page__content--icons-list'>
					{veryficationIcons.map(item =>
						<li
							className='static-page__content--icons-item'
							key={ item.text }
						>
							<i className={ item.icon } />
							<span className='static-page__content--icons-item--text'>
								{dict.transcriptionHelpPage.howToVerify.veryficationIcons[item.text]}
							</span>
						</li>,
					)}
				</ul>

				<p>{dict.transcriptionHelpPage.howToVerify.section.verificationDescription2}</p>
				{selectionIcons.map(item =>
					<li
						className='static-page__content--icons-item'
						key={ item.text }
					>
						<span 
							className='static-page__content--icons-item--set'
							dangerouslySetInnerHTML={ { __html: item.icon} }
						/>
						<span
							dangerouslySetInnerHTML={ { __html: dict.transcriptionHelpPage.howToVerify.selectionIcons[item.text] } }
						/>
					</li>,
				)}
				<p>{dict.transcriptionHelpPage.howToVerify.section.verificationDescription3}</p>
			</section>
            
		</div>
	);
};

export default HowToVerify;
  