import React from "react";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import FormInput from "views/elements/Form/FormInput";
import FormInputCustom from "views/elements/Form/FormInputCustom";

import FormDropdown from '../elements/FormDropdown';

import AuthorNameRoleArray from '../elements/AuthorNameRoleArray';

const TranscriptionNewMonographicLevel = ({ languageOptions, roleOptions, biblTypes, isDisabled }) => {
	const { register, errors, errorMessage, watch } = useFormContext();  // eslint-disable-line
	const dict = useSelector((state) => state.app.dict);

	const secondFieldValue = watch('analythicalInfo.title');

	return (
		<div className='transcription-new__level'>
			<h3 className='text--level-title'>
				{dict.transcription.details.monographicLevel}
			</h3>
			<span className='text--level-desc'>
				{dict.transcription.details.monographicLevelDescription}
			</span>

			<div className='transcription-new__form--row'>
				<FormInput
					name='monographicInfo.title'
					register={ register }
					label={ dict.transcription.form.title }
					isDisabled={ isDisabled }

					placeholder={ dict.transcription.form.titlePlaceholder }
					validateData={
						{
							validate: value => value !== '' || secondFieldValue !== '' || dict.form.messages.minumumOnetitle,
						}
					}
					errorMessage={ errors.monographicInfo && errors.monographicInfo.title && errors.monographicInfo.title.message ? errors.monographicInfo.title.message : '' }
				/>
			</div>

			<div className='transcription-new__form--inline-row'>
				<div className='transcription-new__form--inline-select'>
					<FormDropdown
						label={ dict.transcription.form.type }
						placeholder={ dict.transcription.form.typeSelect }
						formName='monographicInfo.biblType'
						options={ biblTypes }
						isDisabled={ isDisabled }
					/>
				</div>
			</div>

			<div className='transcription-new__form--inline-list'>
				<AuthorNameRoleArray
					linkedObject='monographicInfo.actors'
					isDisabled={ isDisabled }
					roleOptions={ roleOptions }
				/>
			</div>

			<div className='transcription-new__form--inline-row'>
				<div className='transcription-new__form--inline-select'>
					<FormDropdown
						label={ dict.transcription.form.language }
						placeholder={ dict.transcription.form.languageSelect }
						formName='monographicInfo.language'
						options={ languageOptions }
						isDisabled={ isDisabled }
					/>
				</div>
			</div>

			<div className='transcription-new__form--inline'>
				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.chapterNumber }
					name='monographicInfo.chapterNo'
					inputContainerClassName='transcription-new__form--inline-small'
					type='number'
					isDisabled={ isDisabled }
				/>

				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.volumeNumber }
					name='monographicInfo.volumeNo'
					inputContainerClassName='transcription-new__form--inline-small'
					type='number'
					isDisabled={ isDisabled }
				/>

				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.notebookNumber }
					name='monographicInfo.issueNo'
					inputContainerClassName='transcription-new__form--inline-small'
					type='number'
					isDisabled={ isDisabled }
				/>
			</div>

			<div className='transcription-new__form--inline'>
				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.pagesRange }
					name='monographicInfo.pageRange'
					placeholder={ dict.transcription.form.pagesRangePlaceholder }
					inputContainerClassName='transcription-new__form--inline-small'
					isDisabled={ isDisabled }
				/>

				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.pagesNumber }
					name='monographicInfo.pages'
					type='number'
					inputContainerClassName='transcription-new__form--inline-small'
					isDisabled={ isDisabled }
				/>
			</div>

			<div className='transcription-new__form--row'>
				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.publisher }
					name='monographicInfo.publisher'
					isDisabled={ isDisabled }
				/>
			</div>

			<div className='transcription-new__form--inline'>
				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.publicationPlace }
					name='monographicInfo.publicationPlace'
					isDisabled={ isDisabled }
				/>
			</div>

			<div className='transcription-new__form--row'>
				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.publicationDate }
					name='monographicInfo.publicationDate'
					placeholder={ dict.transcription.form.publicationDatePlaceholder }
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>
			</div>

			<div className='transcription-new__form--inline'>
				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.isbn }
					name='monographicInfo.isbn'
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>

				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.eisbn }
					name='monographicInfo.eisbn'
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>
			</div>

			<div className='transcription-new__form--inline'>
				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.issn }
					name='monographicInfo.issn'
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>

				<FormInputCustom
					register={ register }
					label={ dict.transcription.form.eissn }
					name='monographicInfo.eissn'
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>
			</div>
		</div>
	);
};

TranscriptionNewMonographicLevel.propTypes = {
	languageOptions: PropTypes.array,
	roleOptions: PropTypes.array,
	biblTypes: PropTypes.array,
	isDisabled: PropTypes.bool,
};

export default TranscriptionNewMonographicLevel;