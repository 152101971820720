const viewerInitialState = {
	isLoaded: false,
	isSaving: false,
	isMobile: false,
	isOCRProcessing: true,
	noAccess: false,
	data: {
		id: null,
		path: "",
		pageNo: null,
		boundingBox: {},
		verses: [],
		studentVerses: null,
		titleLabel: "",
		prevPageId: null,
		nextPageId: null,
		viewMode: "",
	},
	ocd: {
		tileSources: {},
		selection: false,
	},
	editor: {
		verseActive: null,
		verseEdit: null,
		styleActive: '',
		styleCurrent: {},
		addChar: '',
	},
	charMap: [],
};

export default viewerInitialState;