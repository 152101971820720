import React, { useState, useRef } from "react";
import { useSelector } from 'react-redux';

import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";

import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";
import Button from "views/elements/Button/Button";

import HowToStart from "./parts/HowToStart";
import HowToTranscribe from "./parts/HowToTranscribe";
import HowToVerify from "./parts/HowToVerify";

import "styles/_static-page.sass";

const HowToTranscribeInfo = () => {
	const dict = useSelector((state) => state.app.dict);
	
	const [ key, setKey ] = useState('howToStart');	

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label: dict.transcriptionHelpPage.title , id: 2 },
	];

	const scrollToTopRef = useRef();

	const scrollToTop = (setNextSection) => {
		scrollToTopRef.current.scrollIntoView();		
		setKey(setNextSection);
	};	

	return (
		<main
			className='static-page'
			ref={ scrollToTopRef }
		>
			<Container 
				ref={ scrollToTopRef } 
				className='static-page__content--instruction-container'
			>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<section className='static-page__content'>
					<Row>
						<Col>
							<Tabs
								id='controlled-tab-example'
								activeKey={ key }
								onSelect={ (k) => setKey(k) }
								className='static-page__content--tabs'
							>
								<Tab
									eventKey='howToStart'
									title={ dict.transcriptionHelpPage.howToStart.title }
								>
									<HowToStart />
								</Tab>
								<Tab
									eventKey='howToTranscribe'
									title={ dict.transcriptionHelpPage.howToTranscribe.title }
								>
									<HowToTranscribe />
								</Tab>
								<Tab
									eventKey='howToVerify'
									title={ dict.transcriptionHelpPage.howToVerify.title }
								>
									<HowToVerify />
								</Tab>
							</Tabs>
							
							{key === 'howToStart' &&
								<>
									<span 
										dangerouslySetInnerHTML={ { __html: dict.transcriptionHelpPage.howToStart.step.readMore } }
										className='static-page__content--read-on-description'
									/>
									<Button
										onClick={ () => scrollToTop('howToTranscribe') }
										className='static-page__content--read-on-description--btn'
										variant='link'
									>
										{dict.transcriptionHelpPage.title}
									</Button>
									<div className='static-page__content--read-on'>
										
										<span className='static-page__content--read-on-text'>
											{dict.transcriptionHelpPage.readOn}
										</span>
										<Button
											onClick={ () => scrollToTop('howToTranscribe') }
											className='static-page__content--read-on-btn'
										>
											{dict.transcriptionHelpPage.title}
										</Button>
									</div>
								</>}
							{key === 'howToTranscribe' &&
								<div className='static-page__content--read-on'>
									<span className='static-page__content--read-on-text'>
										{dict.transcriptionHelpPage.readOn}
									</span>
									<Button
										variant='primary'
										onClick={ () => {scrollToTop('howToVerify');} }
										className='static-page__content--read-on-btn'
									>
										{dict.transcriptionHelpPage.howToVerify.title}
									</Button>
								</div>}
							
						</Col>
					</Row>
				</section>
			</Container>
		</main>
	);
};

export default HowToTranscribeInfo;
  