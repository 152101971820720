import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import Button from "views/elements/Button/Button";
import FormCheckbox from "views/elements/Form/FormCheckbox";
import FormSubmit from "views/elements/Form/FormSubmit";

import { useShowModal } from 'views/elements/Modal/ModalHooks';

import TranscriptionNewAnalyticalLevel from './TranscriptionNewAnalyticalLevel';
import TranscriptionNewMonographicLevel from './TranscriptionNewMonographicLevel';
import TranscriptionNewTextProfileLevel from './TranscriptionNewTextProfileLevel';
import TranscriptionNewReferenceLevel from './TranscriptionNewReferenceLevel';
import TranscriptionNewIdentifierLevel from './TranscriptionNewIdentifierLevel';

const TranscriptionNewForm = ({ transcriptionId, defaultValues, isDisabled, roleOptions, biblTypes, languageOptions, onExportData, langs, isImported }) => {
	const dict = useSelector((state) => state.app.dict);
	const history = useHistory();

	const formMethods = useForm(
		{
			mode: 'onSubmit',
			reValidateMode: 'onChange',
			defaultValues,
		},
	);

	const analyticInfo = formMethods.watch('analyticInfo');
	const monographicInfo = formMethods.watch('monographicInfo');

	const parseActors = (actors, actorsDataSource) => actors.map((item, index) => (item.name.length ? {
		name: item.name,
		role: { code: actorsDataSource.actors[index].role },
	} : null)).filter(it => it);

	const parseData = (data, dataSource) => (dataSource && dataSource !== 'none') ? ({
		code: dataSource,
	}) : null;

	const onSubmit = data => {
		data.profileDesc.keywords = data.profileDesc.keywords.split(/[;]+/).map((item) => item.replace(/^\s+|\s+$/g, ""));

		if (Object.keys(data.profileDesc.links).length) {
			data.profileDesc.links = data.profileDesc.links.map((item) => item.name);
		}

		data.analyticInfo.actors = parseActors(data.analyticInfo.actors, analyticInfo);

		data.analyticInfo.language = parseData(data.analyticInfo.language, analyticInfo.language);

		data.monographicInfo.actors = parseActors(data.monographicInfo.actors, monographicInfo);
		data.monographicInfo.language = parseData(data.monographicInfo.language, monographicInfo.language);
		data.monographicInfo.biblType = parseData(data.monographicInfo.biblType, monographicInfo.biblType);
		onExportData(data);
	};

	const modalTerms = useShowModal({
		title: dict.modals.regulations.title,
		name: 'regulations',
		componentPath: './parts/ModalRegulations',
	});

	return (
		<form
			className='transcription-new__form'
			onSubmit={ formMethods.handleSubmit(onSubmit) }
		>
			<FormContext { ...formMethods }>
				<TranscriptionNewAnalyticalLevel
					isDisabled={ isDisabled }
					roleOptions={ roleOptions }
					languageOptions={ languageOptions }
					defaultValues={ defaultValues }
					langs={ langs }
				/>
				<TranscriptionNewMonographicLevel
					isDisabled={ isDisabled }
					roleOptions={ roleOptions }
					languageOptions={ languageOptions }
					biblTypes={ biblTypes }
					defaultValues={ defaultValues }
					langs={ langs }
				/>
				<TranscriptionNewTextProfileLevel
					isDisabled={ isDisabled }
				/>
				<TranscriptionNewReferenceLevel
					isDisabled={ isDisabled }
				/>

				{isImported &&
					<TranscriptionNewIdentifierLevel
						isDisabled={ isDisabled }
						defaultValues={ defaultValues }
					/>}
			</FormContext>
			{!transcriptionId && (
				<div className='transcription-new__form--terms'>
					<div>{dict.transcription.new.terms}
						<Button
							variant='link'
							onClick={ () => modalTerms() }
						>{dict.transcription.new.termsRegLink}
						</Button>.
					</div>
					<FormCheckbox
						name='acceptedRegulations'
						register={ formMethods.register }
						validateData={ {
							required: {
								value: true,
								message: dict.form.messages.notAcceptedRegulations,
							},
						} }
						errorMessage={ formMethods.errors.acceptedRegulations && formMethods.errors.acceptedRegulations.message }
					>
						{dict.transcription.new.termsNew1}
						<Button
							variant='link'
							onClick={ () => modalTerms() }
						>{dict.transcription.new.termsLink}
						</Button>
						{dict.transcription.new.termsNew2}
					</FormCheckbox>
				</div>
			)}

			<div className='transcription-new__form--btns'>
				<Button
					variant='link'
					className='transcription-new__form--cancel'
					onClick={ () => history.goBack() }
				>{dict.form.cancel}
				</Button>
				<FormSubmit
					label={ transcriptionId ? dict.form.saveTranscription : dict.form.createNewTranscription }
					className='transcription-new__form--submit'
					variant='submit'
				/>
			</div>
		</form>
	);
};
TranscriptionNewForm.propTypes = {
	transcriptionId: PropTypes.number,
	defaultValues: PropTypes.object,
	isDisabled: PropTypes.bool,
	roleOptions: PropTypes.array,
	biblTypes: PropTypes.array,
	languageOptions: PropTypes.array,
	onExportData: PropTypes.func,
	setErrorCode: PropTypes.string,
	langs: PropTypes.array,
	isImported: PropTypes.bool,
};

export default TranscriptionNewForm;
