import React from "react";
import { useSelector } from 'react-redux';

import { Container, Row, Col } from "react-bootstrap";

import styles from "./DariahLab.module.sass";

import UE from '@/assets/images/dariahlab/ue-footer.svg';
import UEMobile from '@/assets/images/dariahlab/ue-footer-mobile.svg';



const DariahLab = () => {
	const dict = useSelector((state) => state.app.dict);

	return (
		<section className={ styles["dariahlab"] }>
			<Container>
				<Row>
					<Col>
						<div>
							<img 
								src={UE} 
								className={ styles["dariahlab__ue"] }
								alt={dict.dariahlab.eu}
							/>
							<img 
								src={UEMobile} 
								className={ styles["dariahlab__ue--mobile"] }
								alt={dict.dariahlab.euMobile}
							/>
						</div>
						<div>
							<p className={ styles["dariahlab__text"] }>
								{dict.dariahlab.text}
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default DariahLab;
