import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";

import url from "router/urls";

import TranscriptionItem from 'views/elements/Transcriptions/TranscriptionsItem';
import SearchModuleHome from 'views/elements/Search/SearchModuleHome';

import styles from "./HomeTranscriptions.module.sass";

const HomeTranscriptions = ({transcriptions, homeComponent}) => {
	
	const dict = useSelector((state) => state.app.dict);

	return (
		<section className={ styles["home-transcriptions"] }>
			<Container>
				<div className={ styles["home-transcriptions__header"] }>
					<h2 className={ styles["home-transcriptions__header--title"] }>
						{dict.main[homeComponent].title}
					</h2>
					<span className={ styles["home-transcriptions__header--link-container"] }>
						{dict.main[homeComponent].seeAll}
						<Link
							className={ styles["home-transcriptions__header--link"] }
							to={
								homeComponent === 'lastTranscribed' ?
									url.transcriptions
									:
									url.transcriptionsUser
							}
						>
							{dict.main[homeComponent].transcriptions}
						</Link>
					</span>
					{ homeComponent === 'lastTranscribed' && 
					<div className={ styles["home-transcriptions__header--search"] }>
						<SearchModuleHome placeholder={ dict.transcriptions.search.placeholder } />
					</div>}
				</div>
				<div className={ styles["home-transcriptions__transcriptions"] }>
					{homeComponent === 'lastTranscribed' ?
						(transcriptions.length > 0 ?
							transcriptions.map((item, index) => (
								<TranscriptionItem
									transcription={ item }
									key={ item.titleLabel + index.toString() }
								/>
							))
							:
							<h2 className={ styles["home-transcriptions__header--title"] }>
								{dict.main[homeComponent].noTranscriptions}
							</h2>
						) : <TranscriptionItem transcription={ transcriptions } />}
				</div>
			</Container>
		</section>
	);
};

HomeTranscriptions.propTypes = {
	transcriptions: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(
			PropTypes.shape({
				map: PropTypes.object,
				length: PropTypes.number,
			}).isRequired,
		),
	]),
	homeComponent: PropTypes.string,
};

export default HomeTranscriptions;
