const en = {
	headers: {
		logoAlt: "WLT Logo",
		logoDariahAlt: "Dariah Lab logo",
		menuTitle: "Menu",
		title: "What is the Virtual Transcription Laboratory?",
		description:
			"Virtual Transcription Laboratory is a tool that allows you to create digital text based on scans. The results can be used to prepare a digital object in standards such as: hOCR, TEI, PDF, eBook. The tool allows the transcription of objects imported from Digital Libraries, also using the IIIF standard.",
		newTranscription: "New transcription",
		help: "How to transcribe?",
		contrast: "Contrast",
		font: "Font",
		eu: "EU Logo",
		menu: {
			transcriptions: "Transcriptions",
			allTranscriptions: "All transcriptions",
			myTranscriptions: "My transciptions",
			logIn: "Sign in",
			noAccount: "Don't have an account? ",
			createAccount: "Create one",
			myAccount: "My account",
			myProfile: "My profile",
			logOut: "Log out",
		},
	},
	footer: {
		links: {
			privacyPolicy: "Privacy policy",
			contact: "Contact",
			shareYourOpinion: "Share Your opinion about VTL",
		},
		rights: {
			copyright: "Copyright",
			wlt: "Virtual Transcription Laboratory",
			createdBy: "was created by ",
			pcss: {
				title: "PSNC",
				url: "http://www.man.poznan.pl/online/en/",
			},
		},
	},
	financed: {
		text:
			'Project "Leopoldina online - a platform for integration and sharing of electronic resources of the University of Wrocław for science, education and knowledge dissemination" co-financed by the European Union from the European Regional Development Fund.',
		logos: {
			FE: "European Digital Poland Funds",
			UWr: "University of Wroclaw",
			EU: "European Union - European Regional Development Fund",
		},
	},
	dariahlab: {
		text: 
			"WLT is part of the Dariah.lab infrastructure developed as part of the DARIAH-PL project",
		eu: "EU Partners Logos",
		euMobile: "EU Partners Logos Mobile",
	},
	form: {
		fieldsRequired: "All fields are required to be completed",
		cancel: "Cancel",
		change: "Change",
		email: "E-mail",
		password: "Password",
		currentPassword: "Current password",
		newPassword: "New password",
		repeatedNewPassword: "Repeat new password",
		repeatedPassword: "Repeat password",
		rememberMe: "Remember me",
		forgotPass: "Forgot password",
		signIn: "Sign in",
		signUp: "Sign up",
		createNewTranscription: 'Create new transcription',
		saveTranscription: 'Save transcription',
		passwordTip:
			"When setting a password, remember that the correct password should contain: uppercase letter, lowercase letter, number or special character and consist of min. 8 characters",
		delete: 'Delete',
		inputFile: {
			selectFile: 'Select file',
			selectFiles: 'Select files',
			sendFile: "Send file",
		},
		messages: {
			invalidEmail: "Incorrect e-mail address",
			correctEmail: "Correct e-mail address",
			emptyEmail: "Email field can't be empty",
			invalidUrl: "Incorrect file URL",
			emptyUrl: "URL field cannot be empty",
			duplicateEmail: "This e-mail address already exists, please enter another one or select option remind password",
			emailNotExists: "This email address does not appear in our database. Check the correctness of this address.",
			emailExistsInGroup: "This user is already a member of this group.",
			incorrectPassword:
				"Correct password should contain: uppercase letter, lowercase letter, number or special character and consist of min. 8 characters",
			invalidPassword: "Invalid password",
			correctPassword: "Correct password",
			fillPassword: "Password field can't be empty",
			privatePolicy: "You must accept the privacy policy",
			confirmPassword: "Passwords must match",
			nonExistingEmail: "Email address not found",
			error: "Error occured",
			notAcceptedRegulations: "You have to accept the regulations",
			passwordChangeFailure: "Password change failed",
			differentPasswords: "Passwords should match",
			requiredField: "This field is required",
			wrongFileType: "Wrong file format",
			wrongFilesType: "Wrong files format",
			wrongFileProvided: "Wrong file provided",
			uploadSuccess: "The file has been uploaded correctly.",
			uploadFailed: "File not uploaded. Try again.",
			transcriptionUserAlreadyExists: 'The user already has access to this transcription',
			noUserWithGivenEmail: 'No user found with the email address provided',
			invalidRange: "The range provided is invalid.",
			minumumOnetitle: "At least one title is required",
			unrecognizablePage: "The following page cannot be recognized",
			unrecognizablePages: "The following pages cannot be recognized",
			fileTooLarge: "The file you are trying to upload has exceeded the designated 100MB limit. Try shrinking the file and upload it again.",
			quotaExceeded: "File not uploaded. You have used the MB limit available for your account. Check the limit on the 'My profile' page.",
			emptySubject: "Subject field cannot be empty",
			emptyMessage: "Message field cannot be empty",
			downloadFailed: "File download failed.",
			filesAlreadyExist: "Files already exist",
			fileAlreadyExists: "File already exists",
			pagesNotEqual: "Incompatible number of transcription pages with the number of pages in the file.",
		},
	},
	auth: {
		login: {
			title: "Login",
			alternativeLoginHead: "Alternative login",
			alternativeLoginSubHead1: "By logging in using the following systems, you accept the",
			alternativeLoginSubHead2: "Privacy Policy",
			alternativeLoginSubHead3: "WLT.",
			alternativeLogin: {
				uwrad: {
					title: "Active Directory",
				},
				pionierid: {
					title: "Pionier ID",
				},
				facebook: {
					title: "Facebook",
				},
				google: {
					title: "Google",
				},
			},
			isNotValid: 'Invalid email or password. Please try again or click "I forgot my password" to reset it.',
			isValid: "You have logged in correctly. You can now use the website.",
			isTokenValid: "Your account has been created.<br>You can now login.",
			isTokenNotValid: "The activation link is no longer active. Try to create an account again.",
			signOut: "You have been successfully logged out.",
		},
		register: {
			title: "Sign up",
			acceptedRegulations: {
				t1: "I have read the ",
				link: "Privacy Policy",
				t2: " and accept its content.",
			},
			info:
				"After creating the account, a message will be sent to the e-mail address provided with a link to activate the account.",
			emailSent: {
				head: "Thank you for registering an account",
				text: "A message will be sent to the e-mail address containing the activation link for your account in the Virtual Transcription Laboratory.",
				info: "Click the link in the e-mail to complete the registration.",
				backToHome: "Back to home page",
			},
		},
		account: {
			title: "My profile",
			email: "E-mail",
			contactEmail: "Contact e-mail",
			password: "Password",
			changeEmail: "Change e-mail",
			changePassword: "Change password",
			passwordChange: "Password change",
			myGroup: "My groups",
			createNewGroup: "Create new group",
			groupAdministrator: "Groups in which I am an administrator",
			groupEdit: "Edit",
			groupRest: "Other groups I belong to",
			groupCreator: "Creator",
			groupBlank: "You are not currently assigned to any groups.",
			function: "Function",
			adminConsole: "Admin console",
			passwordChangeAlert: "Password has been changed",
			diskSpace: "Disk space",
			increase: "Ask for increase",
			mb: "MB",
		},
		remind: {
			title: "Forgot my password",
			instruction:
				"To set a new password, enter your e-mail address. A message will be sent to the provided address containing the activation link to change the password. Click this link and set a new password.",
			send: "Send",
			cancel: "Cancel",
			emailSent: "An activation link has been sent to the e-mail address provided.",
			error: "Password change failed",
			changeMessage: "Password change message",
			changedDescription:
				"A message will be sent to the e-mail address provided in the form containing an activation link authorizing the user to change the password.",
			backToHome: "Back to home page",
		},
		passwordChange: {
			title: "Password change",
			warning: "All fields are required",
			instruction:
				"The correct password should contain: uppercase letter, lowercase letter, number or special character and consist of min. 8 characters",
			newPassword: "New password",
			repeat: "Repeat new password",
			error: "Cannot change password",
			success: "The password has been successfully changed. You can login now.",
		},
	},
	admin: {
		dashboard: {
			title: "Admin console",
			description: "Management of users of the Virtual Transcription Laboratory",
			listDescription: "Virtual Transcription Laboratory user list",
			status: 'Status',
			active: 'Active',
			disabled: 'Disabled',
			superadministrator: 'Superadministrator',
			transcriptions: 'Transcriptions',
			searchPlaceholder: "Search for user",
			searchTranscriptionPlaceholder: "Search for transcripts",
			noMatch: "No users matching search criteria",
			limit: "Limit:",
			used: "Used: ",
		},
		userTranscriptions: {
			description: 'Managing VTL user objects ',
			descriptionComment: '(User objects can be managed only by a user with Super Administrator role).',
			listDescription: 'Transcription list of user ',
			noTranscriptions: 'No results matching the query',
			open: 'Open',
			delete: 'Delete',
		},
	},
	group: {
		headerGroup: "Group",
		deleteGroup: "Remove group",
		groupName: "Group name:",
		groupNameInputPlaceholder: "Enter group name",
		groupNameExists: "The group of given name already exists.",
		groupUserRole: "Role:",
		groupUserEmail: "E-mail:",
		removeUser: "Remove",
		addUserHead: "Add user",
		addUserText: "Enter e-mail of user, You want to give access to the object",
		addButton: "Add",
		addUserPlaceholder: 'kowalski@poczta.pl',
	},
	roles: {
		admin: "Admin",
		transcribent: "Transcribent",
		verifier: "Verifier",
		third: "Third",
		changeEmail: "Change e-mail",
		changePassword: "Change password",
		passwordChange: "Password change",
		myGroup: "My groups",
		createNewGroup: "Create new group",
		groupAdministrator: "Groups in which I am an administrator",
		groupEdit: "Edit",
		groupRest: "Other groups I belong to",
		groupCreator: "Creator",
		groupBlank: "You are not currently assigned to any groups.",
		function: "Function",
		adminConsole: "Admin console",
		passwordChangeInfo:
			"After creating the account, a message will be sent to the e-mail address provided with a link to activate the account.",
		passwordChangeAlert: "Password has been changed",
	},
	actorRoles: {
		author: 'Author',
		editor: 'Editor',
		translator: 'Translator',
		copyist: 'Copyist',
		publisher: 'Publisher',
		contributor: 'Contributor',
	},
	languages: {
		pol: "Polish",
		eng: "English",
		deu: "German",
		fra: "French",
		lat: "Latvian",
		rus: "Russian",
	},
	types: {
		print: "Print",
		manuscript: "Manuscript",
	},
	transcriptions: {
		header: {
			title: 'Transciptions',
			titleMy: 'My transcriptions',
			descr: 'List of transcribed digital objects shared by WLT users',
			login: 'Log in to see all ',
			myTranscriptionsLink: 'My transcriptions',
			createNew: "Create new transcription",
		},
		search: {
			placeholder: 'Search in transcribed objects',
			searchingPhrase: 'Searching phrase: ',
			searchBt: 'Search',
			noTranscriptionsFound: "There are no transcripts matching provided search criteria.",
			userHasNoTranscriptions: "You have no transcriptions yet.",
		},
		sort: {
			publicationDate: "Sort by transcription publication date",
			editionDate: "Sort by date of editing transcription",
			sortTitles: "Sort by title A-Z",
		},
		item: {
			transcriptionAdvancementFull: 'Transcription advancement',
			transcriptionAdvancementShort: 'Advancement',
			fullOCR: '100% OCR',
		},
		dropdown: {
			btn: 'Actions',
			transcribe: "Transcribe",
			loadText: "Load text",
			hocrDownload: "Download hOCR",
			textDownload: "Download text",
			teiDownload: "Download TEI with coordinates",
			deletePage: "Delete page",
		},
		description: {
			title: "Title: ",
			author: "Author: ",
			lastEdited: "Last edited: ",
			files: "Files: ",
			transcriptionAdvance: "Transcription advancement: ",
			verificationAdvance: "Verification advancement: ",
			showMore: "Show more",
		},
	},
	staticPage: {
		contact: 'Contact',
		privacyPolicy: 'Privacy policy',
	},
	transcriptionHelpPage: {
		title: 'How to transcribe?',
		readOn: 'Read on',
		howToStart: {
			title: 'How to start transcribing?',
			desc: 'To transcribe pages in the Virtual Transcription Laboratory you must:',
			step: {
				step1title: 'Set up an account on the WLT page ',
				step1desc: 'After registration, a message containing a link to activate the account will be sent to the e-mail address provided in the form. Click on it or copy it to the browsers address bar. The activation link is valid for limited time.',
				step1link: "Create an account",
				step2title: 'After activating your account, ',
				step2link: "Log in",
				step3title: 'To start transcription, select from the main menu option ',
				step3link: "New transcription",
				step3desc: 'Enter the data one by one or import the object description with files from an external source. After entering the data, accept the regulations and confirm the completion of the transcription description by clicking the Create new transcription button.',
				step4title: 'Enter a description of the new transcription, accept the terms and then click "Create a new transcription"',
				step4desc: 'After clicking "Create a new transcription", a message will appear on the screen saying that you have successfully created a new transcription. You are now automatically on the page with the new transcription and you can add the pages of this transcription to be transcribed.',
				step5title: 'The new transcription will appear on the screen immediately. You can now add pages to transcribe.',
				step5desc: 'Every transcription you create is located in the Transcripts / My Transcriptions tab. You must be logged in to view the <a href="/my-transcriptions"> My Transcriptions page </a>. By default, the newly created transcription is not available to other WLT users, both logged in and not logged in.',
				step6title: 'If you want your transcription to be visible to other users, go to the Permissions tab on the My transcription page',
				step7title: 'After adding transcription pages, you can start automatically transcribing all added pages, or you can transcribe each file individually.',
				step7desc: 'If you want to automatically transcribe each page separately, first click on the page tile. A new window will open for transcribing the page you selected, one of the options is "Automatic transcription" of the visible page. The Automatically transcribe option is one of the most important tools available in WLT. Otherwise it is called OCR (Optical Character Recognition) or optical character recognition on a document scan. It can be said that OCR extracts text from an image.',
				step8title: 'Click the selected page and go to the transcription window.',
				readMore: 'After creating a new transcription and adding transcription pages to it, you can start working with the file. To do this, click on the selected page. The selected page will appear in a new window with all the tools you need to transcribe the page file. If you want to know how to work with a file on the transcription page, read on ',
			},
		},
		howToTranscribe: {
			title: 'How to transcribe?',
			desc: 'Description of tools and how to work with the file on the transcription window page.',
			section: {
				workWithFile: 'Work with file.',
				workOnWindow: 'Work on a window with a transcribed object',
				workOnWindowDescription: 'Description of the operation of the transcribed object window tools',
				workWithWindow: 'Work with the transcription window',
				workWithWindowDescription: 'Click on the line ruler you want to transcribe. The poem will be highlighted. The blue frame of the edited line appears in both the transcribed object view and the transcription view. Thanks to this you will be able to see on the transcribed object which page row you are editing.',
			},
			steps: {
				step1title: 'Automatic transcription.',
				step1desc: 'Automatic transcription not only translates all verses or paragraphs of the page again. You can automatically transcribe only verses that have not yet been transcribed. You can define such settings after clicking the „Automatically transcribe” button.',
				step2title: 'Collapsing and expanding windows',
				step2desc: 'You can collapse the transcription window and view the entire page of the transcribed object or vice versa. To do this, click the "Collapse" button, because again see both windows click "Expand".',
				step3title: 'Swap pages',
				step3desc: 'This feature allows you to swap pages with the transcription of the transcribed object.',
				step4title: 'Correct transcription',
				step4desc: 'If all the verses are considered transcribed correctly, you do not need to click each row separately to indicate that all transcription is correct. Just click the "Correct transcription" button and all lines will be marked as correctly transcribed on the right.',
				step5title: 'File Download',
				step5desc: 'Each file can be saved after transcribing the first verse in the form of hOCR, text or TEI format with coordinates.',
				step6title: 'Page switching',
				step6desc: 'In the same object, you can move freely between the pages of the object you are transcribing.',
			},
			icons: {
				addTranscriptionArea: 'Add transcription area',
				addTranscriptionAreaInfo: 'Narzędzie to służy do samodzielnego zaznaczania dodatkowych wierszy lub zaznaczania całych akapitów.',
				zoomIn: 'Zoom in',
				zoomOut: 'Zoom out',
				fullScreen: 'Full screen',
				fitToWindow: 'Adjust to window width',
				workWithTranscriptionWindow: {
					italic: 'Italic',
					bold: 'Bold',
					symbol: 'Special signs',
					quote: 'End of paragraph',
					listElementEnd: 'List element end',
					listEnd: 'End of list',
					page: 'Header, footer, page number',
					pageHeader: 'Heading',
					pageFooter: 'Footer',
					pageNumber: 'Page number',
					header: 'Title',
					header1: 'End of I degree title',
					header2: 'End of II degree title',
					header3: 'Koniec tytułu III stopnia',
					image: 'Image',
					imageDescription: 'Image description',
					table: 'Table',
					tableCellEnd: 'End of cell',
					tableVerseEnd: 'End of verse',
					tableEnd: 'End of table',
					verseAutoTranscription: 'Transcribe automatically selected row',
					verseComment: 'Add a comment to the selected row',
					verseDelete: 'Delete the entire line',
					verseClear: 'Clear the text in the line field',
					verseUp: 'Move the line up',
					verseDown: 'Move the line down',
				},
			},
		},
		howToVerify: {
			title: 'How to verify?',
			desc: 'The task of the transcription verifier is to check the transcription for correctness and quality of the text.',
			section: {
				howToAssignRole: 'How do you assign a verifier role to a user?',
				howToStart: 'How to start verification?',
				verification: 'Weryfikacja',
				verificationDescription1: 'Verifier tools in the transcription window. The verifier has all the tools transscribers and developers have in the transcription window. In addition, the verifier has more options by marking the correctness of row verification. Transcription validation by the transcript:',
				verificationDescription2: 'How to select a correctly verified row?',
				verificationDescription3: 'After verifying the transcription, the transcript will no longer have the rights to edit the transcribed content. Only the creator and verifier will have such functionality.',
			},
			assignRolesSteps: {
				step1: 'The transcription verifier is designated by its creator. The creator can verify his transcription himself or assign the role of verifier to the person to whom he shared the object. The author sets such permissions on the Privileges tab on the My Transcription page.',
				step2: 'Verifier role assignment. Users who have access to the object have a transcript role by default, which means that by default they only have access to the creator object. For a selected person to be able to verify an object, their role must be changed to Verifier.',
			},
			startVeryficationSteps: {
				step1: 'To verify, click the Transcription Verification tab on the My Transcription page.',
				step2: 'Select the page you want to verify from the list and click the Verify button. A transcription window will open, where you will be able to verify the transcribed text.',
			},
			veryficationIcons: {
				correct: 'Row marked by transcript as correctly transcribed.',
				incorrect: 'The verse marked by the transcriptor as incorrectly transcribed or one which he could not complete transcribing.',
				untranscribed: 'Non-transcribed or unverified version of the transcript.',
			},
			selectionIcons: {
				firstClick: '<strong>First click</strong> the verifier will mark the correct transcription regardless of whether the transcript has marked the line as correct or incorrect. The verifier may view the incorrect row as correctly transcribed.',
				secondClick: '<strong>Second click</strong> the verifier will show that the transcription of the poem is incorrect.',
				thirdClick: '<strong>Third click</strong> the verifier will show it returns to the transcript selected state before verification.',
			},
		},
	},
	functionList: {
		SUPER_ADMIN: "Superadministrator",
		ADMIN: "Administrator",
		USER: "User",
	},
	cookies: {
		text: "We use cookies in order to facilitate the use of our site. Check information on ",
		link: "Cookies and the Privacy Policy.",
		agree: "Agree",
	},
	pagination: {
		next: "Next",
		previous: "Previous",
		of: " of ",
	},
	breadcrumbs: {
		account: 'My profile',
		accountMyProfile: 'My account - My profile',
		group: 'Group',
		transcriptions: 'Transcriptions',
		addPages: 'Add new files',
		transcriptionImport: 'Import transcription',
		transcriptionEdit: 'Edit transcription',
		transcriptionNew: 'New transcription',
		addTextPage: 'Load page text',
		addTextPages: 'Load pages text',
		transcriptionsUser: 'My transcriptions',
		adminConsole: 'Admin console',
		adminUserTranscriptions: 'User transcriptions',
	},
	viewer: {
		header: {
			transcriptionObject: "Transcription object",
			pages: {
				next: "Next",
				prev: "Previous",
				page: "page",
			},
			swapPanels: "Swap panels",
			correctTranscription: "Correct transcription",
			emptyTranscription: "Mark the page as blank",
			autoTranscribe: "Transcribe automatically",
			download: "Download",
			downloadhOCR: "Download hOCR",
			downloadText: "Download text",
			downloadTEI: "Download TEI with corridates",
		},
		panels: {
			fold: "Fold",
			expand: "Expand",
			title: {
				osd: "Transcribed object",
				editor: "Transcription",
				student: "Learning",
			},
			selection: {
				edit: "Edit selection",
				save: "Finish editing",
			},
		},
		verse: {
			info: {
				delete: "Delete",
			},
		},
		tooltip: {
			header: {
				correctTranscription: "Mark all verses as correctly transcribed",
				emptyTranscription: "Mark the page as blank",
				downloadTooltip: "Download the page below in the selected format (hOCR, Text, TEI with coordinates)",
				homeBt: "Go to the VTL home page",
				prevPage: "Go to the previous page of this transcription object",
				nextPage: "Go to the next page of this transcription object",
				switchBt: "Swap the pages of the presented Transcription and Transcribed Object pages",
				autoBt: "Automatic transcription of the visible page. Existing changes to the form will not be saved",
				collapseObject: "Collapse the visible area of the transcribed object",
				collapseTranscription: "Collapse the visible Transcription area",
				expandObject: "Expand the area of the transcribed object",
				expandTranscription: "Expand the Transcription area",
			},
			osdMenu: {
				addArea: "Add transcription area",
				zoomIn: "Zoom in",
				zoomOut: "Zoom out",
				fullScreen: "Display in full screen",
				fitToPage: "Adjust to window height",
				editArea: "Start editing the selected area",
			},
			status: {
				click1: "Click once to confirm the row as correctly transcribed.",
				click2: "Click a second time to mark the row as incorrectly transcribed.",
				click3: "Click a third time to mark the row as unverified.",
			},
			menu: {
				style: {
					BOLD: 'Bold',
					ITALIC: 'Italic',
				},
				charmap: "Character map",
				endOfParagraph: "End of paragraph",
				endOfListItem: "End of list item",
				endOfList: "End of list",
				list: "List",
				pageDescription: "Header, Footer, Page number",
				verseHeader: "Header",
				verseFooter: "Footer",
				pageNumber: "Page number",
				titleH: "Title",
				titleH1: "End of 1st degree title",
				titleH2: "End of 2nd degree title",
				titleH3: "End of 3rd degree title",
				image: "Illustration",
				imageCaption: "Caption under the illustration",
				table: "Table",
				endOfCell: "End of cell",
				endOfRow: "End of row",
				endOfTable: "End of table",
				verseAutoTranscription: "Transcribe automatically selected verse",
				addComment: "Add a comment to the selected verse",
				verseDelete: "Delete entire verse",
				versesDelete: "Delete multiple lines",
				deleting: "Deleting",
				verseClear: "Clear the text in the verse",
				verseUp: "Move verse up",
				verseDown: "Move verse down",
			},
			info: {
				header: "Header",
				footer: "Footer",
				pageNo: "Page number",
				titleH1: "End of 1st degree title",
				titleH2: "End of 2nd degree title",
				titleH3: "End of 3rd degree title",
				endOfParagraph: "End of paragraph",
				endOfListItem: "End of list item",
				endOfList: "End of list",
				endOfCell: "End of cell",
				endOfRow: "End of row",
				endOfTable: "End of table",
				imageCaption: "Image description",
				image: "Image",
			},
		},
	},
	error: {
		error404: {
			title: "Error 404",
			description: "We're sorry. The page you requested cannot be found.",
			backText: 'Back to ',
			backLinkText: 'previous page',
			homeText: 'Go to ',
			homeLinkText: 'main page',
		},
		error403: {
			title: "Access denied",
			description: "You do not have permission to this page.",
			backText: 'Back to ',
			backLinkText: 'previous page',
			homeText: 'Go to ',
			homeLinkText: 'main page',
		},
		error500: {
			title: "Error 500",
			description: "We are sorry, this page cannot be displayed at this time due to an internal server error.",
			reloadLinkText: 'Reload page',
		},
	},
	modals: {
		viewerResize: {
			title: "Transcribing file",
			body: "<p> Unfortunately, at this time your device does not meet the necessary requirements to use all transcription tools. </p> <p> All transcription functions are available at resolutions above 1200px. Below this resolution, only automatic transcription is available . </p> <p> To be able to edit lines, change or rotate the device to get the proper screen resolution. </p> ",
			button: "I understand",
		},
		pastedTooLongText: {
			title: "Pasted text is too long",
			body: "<p>The text you have pasted is too long. It has been trimmed to 4000 characters.</p>",
			button: "I understand",
		},
		autoTranscribeVerse: {
			title: "Transcribe the verse automatically",
			bodyHTML: "Automatic line transcription will clear the current text.<br>Are you sure you want to transcribe the line again?",
			select: {
				title: "Recognition profile",
				default: "Select language...",
			},
			button: "Start",
		},
		autoTranscribePage: {
			title: "Transcribe the page automatically",
			body: "This function creates an automatic transcription of the selected page",
			select: {
				titleProfile: "Recognition profile",
				titleRange: "Recognition range",
				default: "Select language...",
				ocrMode: {
					update: "Complete the existing transcription page",
					override: "Overwrite the existing transcription page",
				},
			},
			button: "Start",
		},
		autoTranscribePages: {
			title: "Transcribe pages automatically",
			body: "This function creates automatic transcription of pages within the given range",
			radio: {
				pagesAll: "All pages",
				pagesRange: "Pages in scope",
			},
			input: {
				placeholder: "1,2-6,8",
				label: "Separate page numbers and / or page ranges",
			},
			select: {
				titleProfile: "Recognition profile",
				titleRange: "Recognition range",
				default: "Choose language ...",
				ocrMode: {
					onlyempty: "Only files without transcription",
					update: "Complete existing transcription",
					override: "Overwrite existing transcription",
				},
			},
			button: "Start",
			notice: "The duration of the transcription depends on the number of pages to be transcribed. In the case of multi-page objects it may take up to several hours. If you cannot devote that amount of time, we recommend that you start transcribing from single pages.",
		},
		deleteTranscription: {
			title: "Delete transcription",
			bodyHTML: "This function deletes the entire transcription object, both the description and all files.<br>Are you sure you want to continue?",
			button: "Delete",
		},
		deleteTranscriptionPage: {
			title: "Delete transcription page",
			body: "Are you sure you want to delete the transcription page?",
			button: "Delete",
		},
		groupDeleteUser: {
			title: "Delete user",
			bodyHTML: "This function will delete user from the group.<br> Are you sure you want to continue?",
			button: "Delete user",
		},
		groupDeleteGroup: {
			title: "Delete group",
			bodyHTML: "This function will delete entire group and you won't be able to restore it in a future. <br> Are you sure you want to continue?",
			button: "Delete group",
		},
		permissionsDeleteGroup: {
			title: "Delete group.",
			bodyHTML: "By deleting the group, the object for this group will no longer be available.",
			button: "Delete",
		},
		permissionsDeleteUser: {
			title: "Delete user.",
			bodyHTML: "By deleting the user, the object for this user will no longer be available.",
			button: "Delete",
		},
		downloadResults: {
			title: "Download results",
			download: "Download",
			btOptions: {
				hocr: "Download hOCR",
				hocrZip: "Download hOCR (Zip)",
				tei: "Download TEI",
				teiZip: "Download TEI with coordinates (Zip)",
				text: "Download Text",
				textZip: "Download Text (Zip)",
			},
			getEbook: "Download eBook",
			options: {
				mobi: "Download MOBI",
				epub: "Download EPUB",
				pdf: "Download PDF",
			},
		},
		regulations: {
			title: "Regulations regarding the publication of transcripts in WLT",
			button: "I understand",
		},
		commentsVerse: {
			titleAdd: "Add comment",
			titleVerse: "Comments to the verse",
			button: "Add comment",
			clear: "Clear",
			commentsInAVerse: "Comments in a row",
			label: "Comment",
			limit: "Character limit",
			previousComments: "Earlier comments",
			failed: "Adding comment failed",
			delete: "Delete",
		},
		commentsDelete: {
			title: "Confirm delete comment",
			bodyHTML: "This function will delete the user's comment on the verse. <br> Are you sure you want to continue?",
			button: "Delete comment",
		},
		deleteVerse: {
			title: "Confirm delete verse",
			bodyHTML: "This function will delete the selected verse. <br> Are you sure you want to continue?",
			button: "Delete verse",
		},
		deleteVerses: {
			title: "Delete multiple verses",
			bodyHTML: "After deleting the verses, you will not be able to restore them. <br/> The verses will be deleted along with the comments assigned to them.",
			button: "Delete",
			input: {
				placeholder: "1,6-8,12",
				label: "Select the range of verses you want to delete:",
				error: "Invalid range of verses",
			},
		},
		importAvailableSources: {

			title: {
				mets: 'List of available import sources for METS',
				iiif: 'List of available import sources for IIIF',
				tei: 'List of available import sources for TEI',
			},
			desc: {
				mets: 'List of sources from which you can import objects into WLT by providing a link to METS metadata.',
				iiif: 'List of sources from which you can import objects into WLT by providing a link to the IIIF manifesto.',
				tei: 'List of sources from which you can import objects into WLT by providing a link to the TEI file.',
			},
			search: 'Search for source',
			close: 'Close',
		},
		contactAuthor: {
			title: "Contact the transcription creator",
			subject: "Subject",
			message: "Message",
			button: "Send",
			sendMsg: "Message has been sent",
			notSendMsg: "Message not sent",
		},
	},
	transcription: {
		details: {
			myTranscription: "My transcription",
			analyticLevel: "ANALYTICAL LEVEL",
			analyticLevelDescription: "- contains bibliographic elements describing the position (e.g. article or chapter) published in a monograph or journal, and not as an independent publication",
			monographicLevel: "MONOGRAPH LEVEL",
			monographicLevelDescription: "- contains bibliographic elements describing the item (e.g. a book, manuscript or magazine) published as an independent element (i.e. as a separate physical object)",
			textLevel: "TEXT PROFILE LEVEL",
			textLevelDescription: "- contains a description of the non-bibliographic aspects of the text",
			metadata: "Source object metadata",
			collapse: "Collapse metadata",
			expand: "Expand metadata",
			title: "Title:",
			author: "Author:",
			language: "Language:",
			type: "Type:",
			redactor: "Editor:",
			numberOfChapter: "Chapter number:",
			numberOfNotebook: "Issue number:",
			pageRange: "Page range:",
			publisher: "Publisher:",
			placeOfPublication: "Publication (creation) place:",
			dateOfPublication: "Publication (creation) date:",
			isbn: "ISBN:",
			keywords: "Keywords:",
			summary: "Summary:",
			links: "Links:",
			importAdress: "Import address ",
			externalId: "External Identifier ",
			details: "Details",
			permissions: "Permissions",
			remove: "Remove",
			start: "Start transcription",
			continue: "Continue transcription",
			transcriptionComplete: "Transcription completed",
			verificationComplete: "Transcription verified",
			transcriptionPages: "Pages of trancription ",
			contact: "Contact the Transcription Creator",
			edit: "Edit description",
			dateOfCreation: "Date of creation: ",
			sendEmail: "Send e-mail",
			addNewPages: "Add new pages",
			automaticallyTranscribe: "Transcribe pages automatically",
			loadText: "Load text to pages",
			verify: "Transcription verification",
			downloadResults: "Download results",
			returnToMyTranscripts1: "See all ",
			returnToMyTranscripts2: "My transcriptions",
			workWithPages: "Work with pages",
			identifierLevel: "IDENTIFIER LEVEL",
			EISBN: "e-ISBN:",
			EISSN: "e-ISSN:",
			ISSN: "ISSN:",
			pages: "Number of pages:",
			volumeNumber: "Volume number:",
			importAdressDesc: "This is the URL of the system from which the object was imported.",
			externalIdDesc: "This is the identifier of the object that was assigned to it in the external system, with the possibility of using it.",
		},
		addPages: {
			title: "Add new pages",
			description1: "Select and then upload object pages. You can also import pages from an existing publication. Supported file formats: JPG, TIFF, PDF or ZIP (created from JPG, TIFF or PDF files).",
			description2: "The numbering of the transcription pages will match the numbers in the file name. If the file name does not have an ordinal number, the files will be sorted alphabetically A-Z.",
			confirm: "Send file",
			messages: {
				success: "The file has been uploaded correctly.",
				failed: "File not uploaded. Try again.",
			},
		},
		addText: {
			titlePage: "Load page text",
			titlePages: "Load pages text",
			descriptionPage: "Select and then upload your transcription file in hOCR or TEI format with coordinates.",
			descriptionPages: "Select and then upload your transcription file in hOCR (Zip) or TEI format with coordinates (Zip).",
		},
		new: {
			title: 'New transcription',
			importedTitle: 'Imported transcription',
			import: 'Import the object from an external system',
			sourceObjectMetadata: 'Source object metadata',
			reference: 'Links',
			terms: 'To create an object you must read and accept the ',
			termsRegLink: 'Regulations',
			termsNew1: 'I got acquainted with ',
			termsNew2: ' and I accept its content.',
			termsLink: 'Regulations',
			importSucced: 'Transcription imported successfully.',
			alertSuccess: 'Transcription created successfully. Now you can add files to the new transcription. To do this, click "Add new files"',
			alertEdit: 'The changes you made in transcription description have been saved successfully.',
			alertError: 'There was an error creating a new transcription',
		},
		import: {
			title: 'Import transcription',
			subtitle: 'Import object from an external system.',
			description: "This function will load all object's metadata and files",
			selectLabel: 'Choose metadata format',
			urlLabel: 'Enter object URL',
			remember: 'Remember to read and accept the regulations after importing the metadata. Then create a new transcription.',
			startImport: 'Start import',
			checkList: 'Check the list of available sources',
			searchSource: 'Search for source',
			errors: {
				importserrorurlmalformed: "URL malformed",
				importserrorurlsecured: "URL secured",
				importserrorurlunresponsive: "The object at given address is not resposive",
				importserrorurlnotprocessable: "The object at given address cannot be processed",
				importserrorurlnotfound: "Object at given URL not found",

				importstextformatmalformed: "Object format malformed",
				importstextformatwrongtype: "Object has wrong type",
				importstextformatunsupportedtypes: "Unsuppored object",
				importstextformatlinkless: "Object at given address has no link",
				domainerrornotavailableiiif: "This address does not require our source database from which you can import IIIF Manifest files.",
				domainerrornotavailabletei: "This address does not require our source database from which you can import TEI files.",
				domainerrornotavailablemets: "This address does not require our source database from which you can import METS files.",
			},
		},
		edit: {
			title: "Edit transcription",
		},
		form: {
			title: 'Title:',
			select: 'Select',
			titlePlaceholder: 'If you do not know the original title, you can suggest your own or enter incipit',
			role: 'Role:',
			firstAndLastName: 'First and Last Name:',
			none: 'none',
			firstAndLastNamePlaceholder: 'Jan Kowalski (1934-1999)',
			language: 'Language:',
			languageSelect: 'Select language',
			type: 'Type:',
			typeSelect: 'Select type',
			chapterNumber: 'Chapter number:',
			volumeNumber: 'Volume number:',
			notebookNumber: 'Issue number:',
			pagesRange: 'Page range:',
			pagesRangePlaceholder: 'E.g.: 13-18:',
			pagesNumber: 'Number of pages:',
			publisher: 'Publisher:',
			publicationPlace: 'Publication (creation) place:',
			publicationDate: 'Publication (creation) date:',
			publicationDatePlaceholder: 'E.g .: 1789 or the second half of the 18th century',
			isbn: 'ISBN:',
			eisbn: 'e-ISBN:',
			issn: 'ISSN:',
			eissn: 'e-ISSN:',
			keywords: 'Keywords:',
			keywordsPlaceholder: 'Separate the keyword string with a semicolon',
			summary: 'Summary:',
			link: 'Link:',
			appendAnotherLink: 'Add another link',
			appendAnotherPerson: 'Add another person',
		},
		verification: {
			transcriptionVerification: 'Transcription verification',
			stats: 'Transcribers statistics',
			title: 'Title:',
			author: 'Author:',
			advancement: {
				main: 'Advanced verification:',
				toVerify: 'Pages to verify at this property:',
				verified: 'Verified rows:',
				correct: 'Correct:',
				incorrect: 'Incorrect:',
			},
			table: {
				number: 'No.',
				name: 'Page name',
				versesNumber: 'Number of rows',
				verified: 'Verified',
				correct: 'Correct',
				incorrect: 'Incorrect',
				lastEdition: 'Last edition',
				verification: 'Verification',
				verify: 'Verify',
			},
		},
		permissions: {
			title: "My transcription - Permissions",
			objectAuthority: "Object authority",
			publicAvailability: "Publicly available",
			privateAvailability: "Not publicly available",
			status: "Object status on the WLT website:",
			shareObject: "Share the object with selected WLT users and groups",
			users: "Users who have access to the object:",
			groups: "Groups that have access to the object:",
			addGroupName: "Enter group name",
			addUserTitle: "Add user",
			addGroupTitle: "Add group",
			addUserInfo: "Enter the email address of the user you want to give access to the object:",
			addUserPlaceholder: "kowalski@poczta.pl",
			addUserButton: "Add",
			email: 'E-mail',
			role: 'Role',
			deleteUser: 'Delete',
			groupPlaceholder: 'Institute of History',
		},
		stats: {
			transcribersStats: 'Transcribers statistics',
			title: 'Title:',
			author: 'Author:',
			userName: 'User name',
			charactersEntered: 'Number of characters entered',
			transcribedVerses: 'Number of transcribed verses',
			correctlyVerifiedVerses: 'Number of correctly verified verses',
			contact: 'Contact',
			noStats: 'This object does not have statistics yet, for a user to transcribe in the statistics, he must click the correct transcription of the selected page. This can be done by clicking the "Correct transcription" button in the transcription window or by marking the correctness of each line separately by clicking on the checkbox located just after the line. ',
			howTo: 'Learn more about how to transcribe',
		},
		alert: {
			processingOCR: 'Automatic transcription in progress...',
		},
	},
	main: {
		lastTranscribed: {
			title: 'Recently transcribed in WLT',
			seeAll: 'See all shared',
			transcriptions: 'Transcriptions',
			noTranscriptions: 'No transcriptions found',
		},
		myLastTranscription: {
			title: 'My last transcription',
			seeAll: 'See all',
			myTranscriptions: 'My transcriptions',
		},
	},
	title: "VTL - Virtual Transcription Laboratory",
};

export default en;
