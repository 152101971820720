import axios from 'axios';

import store from 'store/store';
import { history } from 'utils/history';

import { authActions } from "store/actions/auth.actions";
import { appActions } from "store/actions/app.actions";

import url from "router/urls";

const apiAddress = process.env.REACT_APP_API;

const api = axios.create({
	baseURL: `${apiAddress}/api`,
	withCredentials: true,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	crossdomain: true,
});

api.interceptors.request.use( config => {
	config.headers = { 
		...config.headers, 
		Authorization: store.getState().auth.credentials.token, 
		Language: store.getState().app.currentLang, 
		'X-CSRF-TOKEN': store.getState().auth.credentials.csrf, 
	};

	return config;
}, function (error) {
	return Promise.reject(error);
},
);

api.interceptors.response.use( response => {    
	return response;
}, error => {
	if (error.response) {
		switch (error.response.status) {
			case 403:
				store.dispatch(appActions.setLoading(false));
				if (!store.getState().auth.credentials.isLoggedIn) {
					store.dispatch(authActions.setLoginReturnUrl(history.location.pathname ));
					history.push(url.auth.login);
				}
				return Promise.reject(error.response);
			case 404:
				const transcriptionIdsRegex = /^\/transcription\/(\d+)\/page\/(\d+)/g; // eslint-disable-line
				if (transcriptionIdsRegex.test(history.location.pathname)) {
					store.dispatch(appActions.setNotFound(true));
					store.dispatch(appActions.setLoading(false));
				}
				break;
			case 500:
				store.dispatch(appActions.setError(true));
				store.dispatch(appActions.setLoading(false));
				return Promise.reject(error.response);
			case 502:
				store.dispatch(appActions.setError(true));
				store.dispatch(appActions.setLoading(false));
				return Promise.reject(error.response);
			default:
				return Promise.reject(error.response);
		}
	} else {
		store.dispatch(appActions.setError(true));
		store.dispatch(appActions.setLoading(false));
		return Promise.reject(error.response);
	}
	
});

export default api;

/* ---
*   How to use api service:
*   - import api service into a file -> import 'services/api.js';
*   - GET method -> api.get('url').then((response) => ...).catch((error) => ...)
*   - POST method -> api.post('url', { data }).then((response) => ...).catch((error) => ...)
*   - PUT method -> api.put('url', { data }).then((response) => ...).catch((error) => ...)
*
*   Remember: PUT for UPDATE operations, POST for CREATE operations.
* ---
*/
