import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router';
import { Container } from "react-bootstrap";
import { reverse } from 'named-urls';

import { appActions } from "store/actions/app.actions";

import api from "services/api";
import url from "router/urls";

import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";
import Pagination from 'views/components/Pagination/Pagination';
import SearchModule from 'views/elements/Search/SearchModule';
import { useShowModal } from 'views/elements/Modal/ModalHooks';

import AdminTranscriptionItem from './parts/AdminTranscriptionItem';

import styles from "./UserTranscriptions.module.sass";

const UserTranscriptions = () => {
	const dict = useSelector((state) => state.app.dict);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const { id: userId } = useParams();

	const [ apiData, setApiData ] = useState(null);
	const [ currentPageNum, setCurrentPageNum ] = useState(null);
	const [ searchString, setSearchString ] = useState(null);
	const [ initialLoaded, setInitialLoaded ] = useState(false);

	const modalConfirmInfo = useSelector((state) => state.app.modalConfirmInfo);

	const loadData = useCallback((endpoint, params) => {
		dispatch(appActions.setLoading(true));
		api.get(endpoint, { params })
			.then(({ data }) => {
				if ((parseInt(params.page) > parseInt(data.pages)) && (data.pages !== 0)) {
					history.replace(`?${params.q ? `&q=${params.q}&` : ''}page=${data.pages}`);
				}
				else {
					dispatch(appActions.setLoading(false));
					setInitialLoaded(true);
					setApiData(data);
				}
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	}, [ dispatch, history ]);

	useEffect(() => {
		const usp = new URLSearchParams(location.search);
		let pageNum = parseInt(usp.get("page"));
		const query = usp.get("q");
		if (!pageNum || pageNum < 1) {
			if (query && query.length > 1) {
				history.replace(`?q=${query}&page=1`);
			} else {
				history.replace(`?page=1`);
			}
		} else {
			setSearchString(query);
			setCurrentPageNum(pageNum);
			loadData('/user-transcriptions', {
				ownerId: userId,
				page: pageNum,
				q: ((query && query.length > 1) ? query : null),
				perpage: 8,
			});
		}
	}, [ location, history, loadData, userId ]);

	useEffect(() => {
		if (initialLoaded) {
			history.push(`?${searchString ? `&q=${searchString}&` : ''}page=${currentPageNum}`);
		}
	}, [ currentPageNum, searchString, history ]);

	const openTranscription = (transcriptionId) => {
		history.push(reverse(url.transcription.details, { transcriptionId: transcriptionId }));
	};

	const [ transcriptionToDeleteId, setTranscriptionToDeleteId ] = useState(null);
	const modalDeleteTranscription = useCallback(useShowModal({
		title: dict.modals.deleteTranscription.title,
		name: 'deleteGroup',
		componentPath: './parts/ModalDeleteTranscriptionCallback',
	}), [ useShowModal ]);

	useEffect(() => {
		switch (modalConfirmInfo) {
			case "TRANSCRIPTION_DELETE_CONFIRMED":
				api.delete(`/transcriptions/${transcriptionToDeleteId}`)
					.then(() => {
						loadData('/user-transcriptions', {
							ownerId: userId,
							page: currentPageNum,
							q: ((searchString && searchString.length > 1) ? searchString : null),
							perpage: 8,
						});
					});
				break;
			default:
				break;
		}
		dispatch(appActions.setModalConfirmInfo(null));
		setTranscriptionToDeleteId(null);
	}, [ modalConfirmInfo, currentPageNum, dispatch, loadData, searchString, userId ]);

	const deleteTranscription = (transcriptionId) => {
		setTranscriptionToDeleteId(transcriptionId);
	};

	useEffect(() => {
		if (transcriptionToDeleteId) {
			modalDeleteTranscription();
		}
	}, [ transcriptionToDeleteId, modalDeleteTranscription ]);

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: dict.breadcrumbs.account, link: url.auth.account },
		{ id: 3, label: dict.breadcrumbs.adminConsole, link: url.auth.admin.dashboard },
		{ id: 4, label: dict.breadcrumbs.adminUserTranscriptions },
	];

	return (
		<main>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<div className='block__divider' />
				<div className='block__header'>
					<h1 className='text--title'>{dict.admin.dashboard.title}</h1>
				</div>
				<div className={ styles["transcriptions__header"] }>
					<p>{dict.admin.userTranscriptions.description}</p>
				</div>
				<div
					className={ styles["transcriptions__search"] }
				>
					<SearchModule
						setSearchString={ setSearchString }
						searchString={ searchString }
						placeholder={ dict.admin.dashboard.searchTranscriptionPlaceholder }
					/>
				</div>
				<div className='block__header'>
					{(apiData && apiData.transcriptions.length > 0) ? (
						<div>{dict.admin.userTranscriptions.listDescription}
							<strong>
								{(apiData && apiData.ownerEmail) ? apiData.ownerEmail : null}
							</strong>
						</div>
					) : (
						<p>
							{dict.admin.userTranscriptions.noTranscriptions}
						</p>
					)}
				</div>

				<section className={ (apiData && apiData.pages > 1) ? styles["transcription-list__pagination-wrapper"] : styles["transcription-list__no-pagination-wrapper"] }>
					{(apiData) && (
						<Pagination
							currentPage={ apiData.page }
							pagesNum={ apiData.pages }
							onGoToPage={ setCurrentPageNum }
						>
							<div className={ styles["transcription-list__wrapper"] }>
								{apiData.transcriptions &&
									apiData.transcriptions.map((item, index) => (
										<AdminTranscriptionItem
											key={ item.titleLabel + item.id }
											id={ item.id }
											orderNum={ index + ((apiData.page - 1) * apiData.perpage) }
											title={ item.titleLabel }
											authors={ item.authorsLabel }
											openTranscription={ openTranscription }
											deleteTranscription={ deleteTranscription }
										/>
									))}
							</div>
						</Pagination>
					)}
				</section>
			</Container>
		</main>
	);
};

export default UserTranscriptions;