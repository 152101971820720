import viewerInitialState from '../initialState/viewer.initialState';

export function viewer(state = viewerInitialState, action) {
	switch (action.type) {
		case 'VIEWER_ISLOADED':
			return {
				...state,
				isLoaded: action.value,
			};

		case 'VIEWER_ISMOBILE':
			return {
				...state,
				isMobile: action.value,
			};

		case 'VIEWER_ISOCRPROCESSING':
			return {
				...state,
				isOCRProcessing: action.value,
			};

		case 'VIEWER_NOACCESS':
			return {
				...state,
				noAccess: action.value,
			};

		case 'VIEWER_ISSAVING':
			return {
				...state,
				isSaving: action.value,
			};

		case 'VIEWER_RECEIVE_DATA':
			return {
				...state,
				data: {
					...state.data,
					...action.data,
				},
			};

		case 'VIEWER_RECEIVE_INFOJSON':
			return {
				...state,
				ocd: {
					...state.ocd,
					tileSources: {
						...state.ocd.tileSources,
						...action.data,
					},
				},
			};

		case 'VIEWER_RECEIVE_CHARMAP':
			return {
				...state,
				charMap: action.data,
			};

		case 'VIEWER_RECEIVE_VERSE_FORMATTING':
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, image: false, formatting: action.data } : verse,
					),
				},
			};

		case 'VIEWER_SET_VERSE_ACTIVE':
			return {
				...state,
				editor: {
					...state.editor,
					verseActive: action.data,
				},
			};

		case 'VIEWER_SET_VERSE_EDIT':
			return {
				...state,
				editor: {
					...state.editor,
					verseEdit: action.data,
				},
			};

		case 'VIEWER_SET_SELECTION_EDIT':
			return {
				...state,
				ocd: {
					...state.ocd,
					selection: action.data,
				},
			};

		case 'VIEWER_SET_STYLE_ACTIVE':
			return {
				...state,
				editor: {
					...state.editor,
					styleActive: action.data,
				},
			};

		case 'VIEWER_SET_STYLE_CURRENT':
			return {
				...state,
				editor: {
					...state.editor,
					styleCurrent: action.data,
				},
			};

		case 'VIEWER_SET_INSERT_CHARACTER':
			return {
				...state,
				editor: {
					...state.editor,
					addChar: action.data,
				},
			};

		case 'VIEWER_SET_VERSE_CONTENT':
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, content: action.verseContent } : verse,
					),
				},
			};

		case 'VIEWER_SET_VERSE_STUDENT_CONTENT':
			return {
				...state,
				data: {
					...state.data,
					studentVerses: state.data.studentVerses.map(verse =>
						verse.id === action.verseId ? { ...verse, content: action.verseContent } : verse,
					),
				},
			};

		case 'VIEWER_SET_VERSE_STUDENT_STATUS':
			return {
				...state,
				data: {
					...state.data,
					studentVerses: state.data.studentVerses.map(verse =>
						verse.id === action.verseId ? { ...verse, learningStatus: action.verseStudentStatus } : verse,
					),
				},
			};

		case 'VIEWER_UPDATE_VERSE_COMMENTS_COUNT':
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, comments: action.verseCommentsCount } : verse,
					),
				},
			};

		case 'VIEWER_SET_VERSE_IMAGE':
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, image: true } : verse,
					),
				},
			};

		case 'VIEWER_RELOAD_VERSES':
			return {
				...state,
				data: {
					...state.data,
					verses: action.verses,
				},
			};

		case 'VIEWER_SET_VERSE_STATUS':
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						verse.id === action.verseId ? { ...verse, status: action.verseStatus } : verse,
					),
				},
			};

		case 'VIEWER_SET_CORRECT_TRANSCRIPTION':
			return {
				...state,
				data: {
					...state.data,
					verses: state.data.verses.map(verse =>
						state.data.viewMode === 'admin' || state.data.viewMode === 'verify' ? { ...verse, status: 'verified' } : { ...verse, status: verse.status !== 'verified' ? 'transcribed' : verse.status },
					),
				},
			};

		case 'VIEWER_SET_EMPTY_TRANSCRIPTION':
			return {
				...state,
				data: {
					...state.data,
					empty: true,
				},
			};

		case 'CLEAR_TRANSCRIPTION_DATA':
			return {
				...state, data: {
					id: null,
					path: "",
					pageNo: null,
					boundingBox: {},
					verses: [],
					studentVerses: null,
					titleLabel: "",
					prevPageId: null,
					nextPageId: null,
					viewMode: "",
				},
			};

		default:
			return state;
	}
}
