import api from "services/api";

const saveCookie = (appData) => ({
	type: "SAVE_COOKIE",
	appData,
});

const setLang = (lang) => ({
	type: "SET_CURRENT_LANGUAGE",
	lang,
});

const setContrast = (isContrast) => ({
	type: "SET_CONTRAST",
	isContrast,
});

const setFontSize = (fontSize) => ({
	type: "SET_FONT_SIZE",
	fontSize,
});

const setCookiePermission = (cookiePermission) => ({
	type: "SET_COOKIE_PERMISSION",
	cookiePermission,
});

const setLoading = (isLoading) => ({
	type: "SET_LOADING",
	isLoading,
});

const setError = (isError) => ({
	type: "SET_ERROR",
	isError,
});

const setNotFound = (isNotFound) => ({
	type: "SET_NOTFOUND",
	isNotFound,
});

const setSwapPanels = (value) => ({
	type: "SET_SWAP_PANELS",
	value,
});

const setTogglePanelsOSD = (value) => ({
	type: "SET_TOGGLE_PANELS_OSD",
	value,
});

const setTogglePanelsEditor = (value) => ({
	type: "SET_TOGGLE_PANELS_EDITOR",
	value,
});

const setMobilePanels = (value) => ({
	type: "SET_MOBILE_PANELS",
	value,
});

const getOCRProfiles = () => {
	return dispatch => {
		api.get('/languages').then((response) => {
			dispatch(receiveOCRProfiles(response.data));
		});
	};
};

const receiveOCRProfiles = (data) => ({
	type: 'RECEIVE_OCR_PROFILES',
	data,
});

const setImportedTranscription = (value) => ({
	type: "SET_IMPORTED_TRANSCRIPTION",
	value,
});

const setModalConfirmInfo = (value) => ({
	type: "SET_MODAL_CONFIRM_INFO",
	value,
});

const setModalDeleteTranscriptionPage = (value) => ({
	type: "SET_MODAL_DELETE_TRANSCRIPTION_PAGE",
	value,
});

const setModalCommentsDeleteCommentId = (value) => ({
	type: "SET_MODAL_COMMENTS_DELETE_COMMENTID",
	value,
});

const receiveQuestionnaireLink = (value) => ({
	type: "RECEIVE_QUESTIONNAIRE_LINK",
	value,
});

const getQuestionnaireLink = () => {
	return dispatch => {
		api.get('/info/survey-url')
			.then(response => {
				dispatch(receiveQuestionnaireLink(response.data));
			});
	};
};

const setImportsDomains = (value) => ({
	type: "SET_IMPORTS_DOMAINS",
	value,
});

export const appActions = {
	saveCookie,
	setLang,
	setContrast,
	setLoading,
	setError,
	setNotFound,
	setFontSize,
	setCookiePermission,
	setSwapPanels,
	setTogglePanelsOSD,
	setTogglePanelsEditor,
	setMobilePanels,
	getOCRProfiles,
	setModalConfirmInfo,
	setModalDeleteTranscriptionPage,
	setModalCommentsDeleteCommentId,
	setImportedTranscription,
	getQuestionnaireLink,
	setImportsDomains,
};