import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { Dropdown } from 'react-bootstrap';
import PropTypes from "prop-types";

import { useShowModal } from 'views/elements/Modal/ModalHooks';
import { viewerActions } from 'store/actions/viewer.actions';

const FormattingDropdownButton = ({ icon, disabled, func, label }) => {
	const dispatch = useDispatch();
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);
	const dict = useSelector((state) => state.app.dict);
	const { transcriptionId, pageId } = useParams();

	const modalDeleteVerse = useShowModal({
		title: dict.modals.deleteVerse.title,
		type: 'viewer',
		name: 'deleteVerse',
		componentPath: './parts/ModalDeleteVerse',
		componentProps: { transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId) },
	});

	const modalDeleteVerses = useShowModal({
		title: dict.modals.deleteVerses.title,
		type: 'viewer',
		name: 'deleteVerses',
		componentPath: './parts/ModalDeleteVerses',
		componentProps: { transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId) },
	});

	return (
		<Dropdown.Item
			onClick={ () => {
				switch (func) {
					case 'verse-delete':
						modalDeleteVerse();
						break;
					case 'verses-delete':
						modalDeleteVerses();
						break;
					default:
						dispatch(viewerActions.submitVerseFormatting(verseActive, func));
				}
			} }
			disabled={ disabled }
		>
			<span className='d-none'>{icon}</span>
			<span><i className={ `icon-${icon}` } /></span> {label}
		</Dropdown.Item>
	);
};

FormattingDropdownButton.propTypes = {
	label: PropTypes.string,
	func: PropTypes.string,
	icon: PropTypes.string,
};

export default memo(FormattingDropdownButton);