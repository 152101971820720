import React from "react";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Form from "react-bootstrap/Form";

import styles from './Form.module.sass';
import Button from "views/elements/Button/Button";

const FormInputWithDelete = ({ name, label, type, placeholder, register, validateData, errorMessage, correctMessage, inputContainerClassName, onDeleteFunction, onChange, isDisabled }) => {
	const dict = useSelector((state) => state.app.dict);
	
	return (
		<div className={ `
			${styles["form__input-container"]}
			${inputContainerClassName ? inputContainerClassName : null}
			` }
		>
			{(onDeleteFunction) ?
				(
					<div className={ styles["form__input--delete-container"] }>
						<Form.Label 
                        // htmlFor={ name }
                        >{label}</Form.Label>
						{(!isDisabled) && (
							<Button
								variant='link'
								onClick={ onDeleteFunction }
								className={ styles["form__input--delete"] }
							>
								{dict.form.delete}
							</Button>
						)}
					</div>
				)
				:
				(<Form.Label 
                // htmlFor={ name }
                >{label}</Form.Label>)}
			<Form.Row className={ styles["form__input-row"] }>
				<input
					className={ `form-control 
						${errorMessage ? styles["form__input--incorrect"] : null}
						${correctMessage ? styles["form__input--correct"] : null}
					` }
					type={ type ? type : 'text' }
					name={ name }
					id={ name }
					autoComplete='off'
					placeholder={ placeholder }
					onChange={ onChange }
					ref={ register(validateData) }
					disabled={ isDisabled }
				/>
				{errorMessage ? (
					<div className={ styles["form__message--invalid"] }>
						<i className='icon-close' />{errorMessage}
					</div>)
					: null}
			</Form.Row>
		</div>
	);
};
FormInputWithDelete.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	register: PropTypes.func,
	validateData: PropTypes.object,
	errorMessage: PropTypes.string,
	correctMessage: PropTypes.string,
	inputContainerClassName: PropTypes.string,
	onDeleteFunction: PropTypes.func,
	onChange: PropTypes.func,
	isDisabled: PropTypes.bool,
};

export default FormInputWithDelete;
