import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CommonDropdown from 'views/elements/Dropdown/CommonDropdown';

const TranscriptionsSortSelect = ({ order, setOrder }) => {
	const dict = useSelector((state) => state.app.dict);

	const [ dropdownOption, setDropdownOption ] = useState(0);
	
	const orderTypes = [
		{
			value: "last-modification-date",
			label: dict.transcriptions.sort.editionDate,
		},
		{
			value: "date-created",
			label: dict.transcriptions.sort.publicationDate,
		},
		{
			value: "title",
			label: dict.transcriptions.sort.sortTitles,
		},
	];
	const onSelectOption = (index) => {
		setOrder(orderTypes[index].value);
	};

	useEffect( () => {
		if (order){
			setDropdownOption(orderTypes.findIndex(el => el.value === order));
		}		
	}, [ order ]);

	return (
		<CommonDropdown 
			options={
				orderTypes.map( it => it.label)
			} 
			selectedOption={ dropdownOption }
			setSelectedOption={ onSelectOption }
		/>
	);
};

TranscriptionsSortSelect.propTypes = {
	order: PropTypes.string,
	setOrder: PropTypes.func,
};

export default TranscriptionsSortSelect;