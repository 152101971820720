import api from "services/api";
import url from "router/urls";
import { cookieService } from "services/cookie";
import { history } from "utils/history";
import { alertActions } from "./alert.actions";
import { appActions } from "./app.actions";

const logoutADAddress = process.env.REACT_APP_LOGIN_SP_AD_URL_LOGOUT;

const setAuthToken = token => {
	return {
		type: "SET_AUTH_TOKEN",
		token,
	};
};

const setAuthCSRFToken = token => {
	return {
		type: "SET_AUTH_CSRFTOKEN",
		token,
	};
};

const setAuthIsLoggedIn = isLoggedIn => {
	return {
		type: "SET_AUTH_ISLOGGEDIN",
		isLoggedIn,
	};
};

const setSignOut = () => {
	return {
		type: "SET_AUTH_SIGNOUT",
	};
};

const setAuthIsLoggingEnd = isLoggingEnd => {
	return {
		type: "SET_AUTH_ISLOGGINGEND",
		isLoggingEnd,
	};
};

const setAuthPathIsProtected = isProtected => {
	return {
		type: "SET_AUTH_PATH_ISPROTECTED",
		isProtected,
	};
};

const checkAuth = () => {
	return dispatch => {
		if (cookieService.getToken()) {
			dispatch(setAuthToken(cookieService.getToken()));
		}

		api.put("/auth/token")
			.then(() => {
				dispatch(setAuthIsLoggedIn(true));
				dispatch(setAuthIsLoggingEnd(true));
			})
			.catch(() => {
				dispatch(setAuthIsLoggedIn(false));
				cookieService.removeToken();
				dispatch(setAuthIsLoggingEnd(true));
			});

		api.get("/auth/csrf/token")
			.then((response) => {
				dispatch(setAuthCSRFToken(response.headers['csrf-token']));
			});
	};
};

const signIn = (data, returnUrl) => {
	return dispatch => {
		dispatch(setLoginIsLoading(true));

		api.post(`/auth/sign-in?remember-me=${data.rememberMe}`, {
			email: data.email,
			password: data.password,
		})
			.then(response => {
				dispatch(setLoginIsLoading(false));

				dispatch(setAuthIsLoggedIn(true));
				cookieService.setToken(response.headers.authorization);
				dispatch(setAuthToken(response.headers.authorization));

				if (data.rememberMe) {
					api.get("/auth/csrf/token")
						.then((resp) => {
							dispatch(setAuthCSRFToken(resp.headers['csrf-token']));
						});
				}

				history.push(returnUrl ? returnUrl : url.home);

				dispatch(setLoginReturnUrl(null));

			})
			.catch(() => {
				dispatch(setLoginIsLoading(false));
				dispatch(
					alertActions.setAlert({
						type: "danger",
						icon: "icon-circle-warning-empty",
						text: "auth.login.isNotValid",
						close: true,
					}),
				);
			});
	};
};

const fedSignIn = (email, returnUrl) => {
	return dispatch => {
		dispatch(setLoginIsLoading(true));
		dispatch(appActions.setLoading(true));

		api.post(`/auth/fed-sign-in?email=${email}`)
			.then(response => {
				dispatch(setLoginIsLoading(false));
				dispatch(appActions.setLoading(false));

				dispatch(setAuthIsLoggedIn(true));

				dispatch(setAuthToken(response.headers.authorization));
				cookieService.setToken(response.headers.authorization);
				
				history.push(returnUrl ? returnUrl : url.home);
				dispatch(setLoginReturnUrl(null));
			});
	};
};

const signOut = (isProtected) => {
	return dispatch => {
		dispatch(appActions.setLoading(true));

		var popup = window.open(logoutADAddress, "Logout", "left=10000,top=10000,width=0,height=0");	
		setTimeout(function(){ popup.close(); }, 1000);

		api.get('/auth/sign-out')
			.then(() => {
				dispatch(appActions.setLoading(false));
				
				cookieService.removeToken();
				dispatch(setSignOut());
				
				if (isProtected) {
					history.push(url.home);
				}

			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	};
};

const setLoginIsLoading = value => {
	return {
		type: "SET_LOGIN_ISLOADING",
		isLoading: value,
		value,
	};
};

const setLoginReturnUrl = value => {
	return {
		type: "SET_LOGIN_RETURNURL",
		value,
	};
};

export const authActions = {
	checkAuth,
	signIn,
	fedSignIn,
	signOut,
	setLoginReturnUrl,
	setAuthPathIsProtected,
};
