import React, { useEffect }  from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";

import { hideModal } from 'store/actions/modal.actions';

import Button from "views/elements/Button/Button";

import styles from './Errors.module.sass';

const Error500 = () => {
	const dict = useSelector((state) => state.app.dict);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(hideModal());
	}, [ dispatch ]);

	return (
		<main>
			<Container>
				<div className='block'>
					<div className={ `block__header ${styles['nopage--warning']}` }>
						<h1 className='text--title'>{dict.error.error500.title}</h1>
					</div>
					<div className={ styles["nopage--content"] }>
						<p className='text--heading'>{dict.error.error500.description}</p>
					</div>
					<div className={ styles['nopage--links'] }>
						<Button
							variant='link'
							type='button'
							onClick={ () => window.location.reload() }
						>
							{dict.error.error500.reloadLinkText}
						</Button>
					</div>
				</div>
			</Container>
		</main>
	);
};

export default Error500;
