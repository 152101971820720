import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

import styles from './Pagination.module.sass';

/* Instrukcja użycia:
Wiemy, że dany blok będzie wymagał modułu paginacji, otaczamy go tym komponentem:
<Pagination>
	<ExampleList />
</Pagination>
w ten sposób przekazany zostaje pierwszy parametr - children
W nadrzędnym komponencie musimy tylko zdefiniować stan dla obecnego numeru strony:
const [currentPage, setCurrentPage] = setState(1);
Api zawssze podaje nam też ilość stron, a zatem to przypisujemy jako trzeci parametr

onGoToPage to referencja do funkcji, która jest odpowiedzialna za zmianę strony
(na przykład użytkownik w module paginacja kliknął na 3 stronę, czyli w komponencie powyżej musimy zrobić przekierowanie na odpowiednią stronę, coś w stylu:
history.push('examplelist/3')
	)
*/

const Pagination = ({ children, currentPage, pagesNum, onGoToPage }) => {
	const dict = useSelector((state) => state.app.dict);

	const [ pagesArr, setPagesArr ] = useState([]);
	const [ inputPageNum, setInputPageNum ] = useState(0);
	const [ debouncedPageNum ] = useDebounce(inputPageNum, 500);
	const paginationTop = useRef();

	useEffect(() => {
		const db = parseInt(debouncedPageNum);
		if (db !== currentPage && db > 0 && db <= pagesNum) {
			gotoPage(db);
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ debouncedPageNum ]);

	const gotoPage = (pageNum) => {
		paginationTop.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
		onGoToPage(pageNum);
	};

	const prevButton =  (
		<button
			className={ `${(currentPage > 1) ? styles["pagination__btn"] : styles["pagination__btn--disabled"]}
					${styles["pagination__btn--prev"]}` }
			onClick={ () => gotoPage(currentPage - 1) }
		>{dict.pagination.previous}
		</button>
	);
	const nextButton = (
		<button
			className={ `${(currentPage < pagesNum) ? styles["pagination__btn"] : styles["pagination__btn--disabled"]}
					${styles["pagination__btn--next"]}` }
			onClick={ () => gotoPage(currentPage + 1) }
		>{dict.pagination.next}
		</button>
	);

	const fPage = (pNum) => (
		<li
			className={ styles["pagination-bottom__item"] }
			onClick={ () => {
				gotoPage(pNum);
			} }
		>{pNum}
		</li>
	);

	useEffect(() => {
		setPagesArr([ ...Array(pagesNum).keys() ].filter((item, index) => {
			let surroundingPageNum = 2;
			if ((currentPage === 1) || (pagesNum >= 5 && currentPage === pagesNum)) {
				surroundingPageNum = 4;
			} else if ((currentPage === 2) || (pagesNum === 4 && currentPage === 4) || (pagesNum > 4 && currentPage === pagesNum - 1)) {
				surroundingPageNum = 3;
			}
			if (index >= (currentPage - surroundingPageNum - 1) && index <= (currentPage + surroundingPageNum - 1)) {
				return item + 1;
			}
			return null;
		}));
		if (currentPage) {
			setInputPageNum(currentPage);
		}
	}, [ currentPage, pagesNum ]);

	return (
		<>
			<div className={ styles["pagination-top"] }>
				<div
					ref={ paginationTop }
					className={ styles["pagination-top__anchor"] }
				/>
				{(currentPage > 1) && prevButton}
				{(pagesNum > 1) ? (
					<label>
						<span className='d-none'>{inputPageNum}</span>
						<input
							className={ styles["pagination__input"] }
							type='number'
							min='1'
							value={ inputPageNum }
							onChange={ ev => setInputPageNum(ev.target.value) }
							max={ pagesNum }
						/>
					</label>
				) : currentPage}
				<div>{dict.pagination.of}</div>
				{fPage(pagesNum)}
				{(currentPage < pagesNum) && nextButton}
			</div>
			
			{children}
			
			<div className={ styles["pagination-bottom"] }>
				{prevButton}
				{(pagesNum > 5 && currentPage >= 4) && (fPage(1))}
				{(pagesNum > 5 && currentPage >= 5) && (
					<li className={ styles["pagination-bottom__item--disabled"] }>...</li>
				)}

				<ul className={ `${styles["pagination-bottom__list"]}` }>
					{pagesArr.map((page, index) => (
						<li
							className={ (page + 1 === currentPage) ? styles["pagination-bottom__item--disabled"] : styles["pagination-bottom__item"] }
							key={ "page" + index.toString() }
							onClick={ () => gotoPage(page + 1) }
						> {page + 1}
						</li>),
					)}
					<li className={ styles["pagination-bottom__item--disabled"] }> {dict.pagination.of} </li>
					{fPage(pagesNum)}
				</ul>
				{nextButton}
			</div>
				
		</>
	);
};

Pagination.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
	currentPage: PropTypes.number,
	pagesNum: PropTypes.number,
	onGoToPage: PropTypes.func,
};

export default Pagination;
