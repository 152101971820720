import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Container } from "react-bootstrap";

import Button from "views/elements/Button/Button";

import url from "router/urls";

import { isDariahLab } from "utils/helpers";

import styles from "./MainDescription.module.sass";

const MainDescription = () => {

	const dict = useSelector((state) => state.app.dict);

	return (
		<section className={` ${styles["main"]} ${isDariahLab() && styles["main--dariahlab"]} `}>
			<Container className={ styles["main__container"] }>
				<Row>
					<div className={ styles["main__description"] }>
						<h1 className={ styles["main__description--title"] }>{dict.headers.title}</h1>
						<p className={ styles["main__description--text"] }>{dict.headers.description}</p>
						<div className={ styles["main__description--buttons"] }>
							<Button
								variant='primary'
								className={ styles["main__description--button"] }
								href={ url.transcription.new }
							>
								{dict.headers.newTranscription}
							</Button>
							<Link
								className={ styles["main__description--link"] }
								to={ url.howToTranscribe }
							>
								{dict.headers.help}
							</Link>
						</div>
					</div>
				</Row>
			</Container>
		</section>
	);
};

export default MainDescription;
