import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { Container } from "react-bootstrap";

import { appActions } from "store/actions/app.actions";

import api from "services/api";
import url from "router/urls";

import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";
import SearchModule from 'views/elements/Search/SearchModule';
import Pagination from 'views/components/Pagination/Pagination';

import UserItem from './parts/UserItem';
import styles from "./Dashboard.module.sass";

const Dashboard = () => {
	const dict = useSelector((state) => state.app.dict);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const [ apiData, setApiData ] = useState({});
	const [ currentPageNum, setCurrentPageNum ] = useState(null);
	const [ searchString, setSearchString ] = useState(null);
	const [ initialLoaded, setInitialLoaded ] = useState(false);

	const loadData = useCallback((endpoint, params) => {
		dispatch(appActions.setLoading(true));
		api.get(endpoint, { params })
			.then(({ data }) => {
				if ((parseInt(params.page) > parseInt(data.pages)) && (data.pages !== 0)) {
					history.replace(`?${params.q ? `&q=${params.q}&` : ''}page=${data.pages}`);
				}
				else {
					dispatch(appActions.setLoading(false));
					setInitialLoaded(true);
					setApiData(data);
				}
			})
			.catch(() => {
				dispatch(appActions.setLoading(false));
			});
	}, [ dispatch, history ]);

	const onQuotaChange = (userId, quota) => {
		api.put(`/admin/users/${userId}/quota?quota=${quota}`);
	};

	useEffect(() => {
		const usp = new URLSearchParams(location.search);
		let pageNum = parseInt(usp.get("page"));
		const query = usp.get("q");
		if (!pageNum || pageNum < 1) {
			if (query && query.length > 1) {
				history.replace(`?q=${query}&page=1`);
			} else {
				history.replace(`?page=1`);
			}
		} else {
			setSearchString(query);
			setCurrentPageNum(pageNum);
			loadData("/admin/users", {
				page: pageNum,
				q: ((query && query.length > 1) ? query : null),
				perPage: 50,
			});
		}
	}, [ location, history, loadData ]);

	useEffect(() => {
		if (initialLoaded) {
			history.push(`?${searchString ? `&q=${searchString}&` : ''}page=${currentPageNum}`);
		}
	}, [ currentPageNum, searchString, history, initialLoaded ]);

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: dict.breadcrumbs.account, link: url.auth.account },
		{ id: 3, label: dict.breadcrumbs.adminConsole },
	];

	return (
		<main>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<div className='block__divider' />
				<div className='block__header'>
					<h1 className='text--title'>{dict.admin.dashboard.title}</h1>
				</div>
				<div
					className={ styles["userlist__block--top"] }
				>
					<p>{dict.admin.dashboard.description}</p>
				</div>
				<div
					className={ styles["dashboard__search"] }
				>
					<SearchModule
						setSearchString={ setSearchString }
						searchString={ searchString }
						placeholder={ dict.admin.dashboard.searchPlaceholder }
					/>
				</div>

				<div className={ styles["userlist__block"] }>
					<p><strong>{dict.admin.dashboard.listDescription}</strong></p>
				</div>
				<section className={ (apiData.pages > 1) ? styles["userlist__pagination-wrapper"] : styles["userlist__no-pagination-wrapper"] }>
					{(apiData.users && apiData.users.length < 1) ? (
						<p>{dict.admin.dashboard.noMatch}</p>
					) : (
						<Pagination
							currentPage={ apiData.page }
							pagesNum={ apiData.pages }
							onGoToPage={ setCurrentPageNum }
						>
							<div className={ styles["userlist__wrapper"] }>
								{apiData.users &&
                                    apiData.users.map((user, index) => (
	<UserItem
	key={ user.email }
	id={ user.id }
	orderNum={ index + ((apiData.page - 1) * apiData.perPage) }
	email={ user.email }
	enabled={ user.enabled }
	userRole={ user.securityGroup }
	trustedTranscriber={ user.trustedTranscriber }
	quota={ user.quota }
	usedSpaceQuota={ user.usedSpaceQuota }
	onQuotaChange={ onQuotaChange }
                                    	/>
                                    ),
                                    )}
							</div>
						</Pagination>
                        
					)}

				</section>
			</Container>
		</main>
	);
};

export default Dashboard;