import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';

import { staticPageActions } from "store/actions/staticPage.actions";
import Breadcrumbs from "views/elements/Breadcrumbs/Breadcrumbs";

import { Container, Row, Col } from "react-bootstrap";

import "styles/_static-page.sass";

const PrivacyPolicy = () => {

	const currentLang = useSelector((state) => state.app.currentLang);
	const dict = useSelector((state) => state.app.dict);

	const privacyPolicyPage = useSelector((state) => state.staticPage.privacyPolicyPage);
    
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(staticPageActions.getPrivacyPolicyPage());
	}, [ dispatch ]);

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label: dict.staticPage.privacyPolicy , id: 2 },
	];

	return (
		<main className='static-page'>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<section className='static-page__content'>
					<Row>
						<Col>
							{ privacyPolicyPage !== '' &&
								<ReactMarkdown source={ privacyPolicyPage[currentLang] } />}
						</Col>
					</Row>
				</section>
			</Container>
		</main>
	);
};

export default PrivacyPolicy;