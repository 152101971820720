import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from 'react-redux';

import url from "router/urls";

import AppHeaderNav from "./AppHeaderNav";
import HeaderTopBar from "./HeaderTopBar";
import Spinner from "views/elements/Spinner/Spinner";

import { isDariahLab } from "utils/helpers";

import DariahLabUE from '@/assets/images/dariahlab/ue-header.svg';

import styles from "./AppHeader.module.sass";

const AppHeader = () => {
	const dict = useSelector((state) => state.app.dict);
	const isError = useSelector((state) => state.app.isError);
	const location = useLocation();
	const [ scrollPoss, setScrollPoss ] = useState(0);
	const [ sticky, setSticky ] = useState(false);

	const [ collapsed, setCollapsed ] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			setSticky(currentScrollPos >= 40);
			if (currentScrollPos !== scrollPoss) {
				setScrollPoss(currentScrollPos);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleNavbar = () => {
		setCollapsed(!collapsed);
	};

	const pathName = location.pathname;

	return (
		<>
			<Spinner stateFromStore />
			<div 
				className={ `
					${pathName !== "/" || isError  ? `${styles["header__subpage"]} ${ isDariahLab() && styles["header__subpage--dariah"]}` : styles[""]}
				` }
			>
				<div
					className={ `
						${collapsed ? styles["header__collapsed"] : styles[""]}
						${styles["header"]}
					` }
				>
					<HeaderTopBar
						collapsed={ collapsed }
						toggleNavbar={ toggleNavbar }
					/>
					<div 
						className={ `
							${sticky ? styles["header__wrapper--sticky"] : `${styles["header__wrapper"]} ${ isDariahLab() && styles["header__wrapper--dariah"]}`}
						` }
					>
						<Container
							className={
								sticky ? styles["header__container--sticky"] : styles["header__container"],
								collapsed ? styles["header__collapsed"] : styles[""]
							}
						>
							<Row
								className={ `
									${sticky ? styles["header__row--sticky"] : styles["header__row"]}
								` }
							>
								<Col className={ styles["header__column"] }>
									<Link to={ url.home }>
										<div
											className={ (sticky && (window.innerWidth >= 991.98)) ? styles["header__logo"] : `${styles["header__logo--big"]} ${ isDariahLab() && styles["header__logo--dariahlab"]}` }
										>
											{ dict.headers.logoAlt }
										</div>
										
									</Link>
									{
										isDariahLab() && 
										<a
											className={ styles["top-bar__item"] }
											href='https://lab.dariah.pl'
											target='_blank'
											rel="noopener noreferrer"
										>
											<div
												className={styles["header__dariahlab"]}
											>
												{ dict.headers.logoDariahAlt }
											</div>
											
										</a>
									}
									

									<AppHeaderNav
										toggleNavbar={ toggleNavbar }
										sticky={ sticky }
									/>
									{
										isDariahLab() && 
										<div className={ styles["header__dariahlab--ue"] }>
											<img 
												src={DariahLabUE} 
												className={ styles["header__dariahlab--imgue"] }
												alt={ dict.headers.logoDariahAlt }
											/>
										</div>
									}
								</Col>

							</Row>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
};

export default AppHeader;
