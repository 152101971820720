import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";

import { Editor, EditorState, ContentState, SelectionState, convertToRaw, RichUtils, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { viewerActions } from 'store/actions/viewer.actions';

import Button from "views/elements/Button/Button";

import { useShowModal } from 'views/elements/Modal/ModalHooks';

const VerseActive = ({ verse, toggleVerseEdit }) => {
	const dict = useSelector((state) => state.app.dict);
	const mobilePanels = useSelector((state) => state.app.viewer.mobilePanels);

	const dispatch = useDispatch();

	const modalPaste = useShowModal({
		title: dict.modals.pastedTooLongText.title,
		bodyHTML: dict.modals.pastedTooLongText.body,
		type: 'viewer',
		name: 'pastedTooLongText',
		withFooter: true,
		actionButton: dict.modals.pastedTooLongText.button,
	});

	const keyEscape = useCallback((event) => {
		if(event.keyCode === 27) {
			toggleVerseEdit();
		}
	}, []);

	const defaultValues = {
		verseContent: (verse.content ? verse.content : ""),
	};

	const { register, handleSubmit, setValue, getValues } = useForm({ defaultValues: defaultValues });

	const [ editorState, setEditorState ] = useState(
		EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(verse.content ? verse.content : ""))),
	);

	const toggleStyle = useSelector((state) => state.viewer.editor.styleActive);
	const insertCharacter = useSelector((state) => state.viewer.editor.addChar);

	useEffect(() => {
		setEditorState(moveSelectionToEnd(editorState));
		document.addEventListener("keydown", keyEscape);	
		return () => {
			document.removeEventListener("keydown", keyEscape);
		};
	}, []);

	useEffect(() => {
		const contentConvertToRaw = convertToRaw(editorState.getCurrentContent());
		const contentDraftToHtml = draftToHtml(contentConvertToRaw);
		setValue('verseContent', contentDraftToHtml);
		dispatch(viewerActions.setStyleCurrent(editorState.getCurrentInlineStyle()));
	}, [ editorState ]);

	useEffect(() => {
		if (toggleStyle !== '') {
			setEditorState(RichUtils.toggleInlineStyle(editorState, toggleStyle));
			dispatch(viewerActions.setStyleActive(''));
		}
	}, [ toggleStyle ]);

	useEffect(() => {
		if (insertCharacter !== '') {
			setEditorState(EditorState.push(editorState, Modifier.replaceText(
				editorState.getCurrentContent(),
				editorState.getSelection(),
				insertCharacter,
			)));
			dispatch(viewerActions.setInsertCharacter(''));
		}
	}, [ insertCharacter ]);

	const moveSelectionToEnd = useCallback((_editorState) => {
		const content = _editorState.getCurrentContent();
		const blockMap = content.getBlockMap();

		const key = blockMap.last().getKey();
		const length = blockMap.last().getLength();

		const selection = new SelectionState({
			anchorKey: key,
			anchorOffset: length,
			focusKey: key,
			focusOffset: length,
		});
		return EditorState.forceSelection(_editorState, selection);
	}, []);

	const onPastedText = text => {
		if (text.length > 4000) {
			modalPaste();
		}
		let tx = text.replace(/(-\n | - \n)/g, '').replace(/\n/g, ' ').substring(0, 4000);
		setEditorState(EditorState.push(editorState, Modifier.replaceText(
			editorState.getCurrentContent(),
			editorState.getSelection(),
			tx,
		)));
		return 'handled';
	};
	const onEnterKey = () => {
		onFieldAccept(getValues().verseContent);
		return 'handled';
	};
	const onSubmit = data => {
		onFieldAccept(data.verseContent);
	};

	const onFieldAccept = text => {
		const noParagraphText = text.replace('<p>', '').replace('</p>', '');
		if (mobilePanels === 'student') {
			dispatch(viewerActions.submitVerseStudentContent(verse.id, noParagraphText));
		} else {
			dispatch(viewerActions.submitVerseContent(verse.id, noParagraphText));
		}
	};

	return (
		<div className='verse__block verse__block--active'>
			<div className='verse__num'>
				{verse.verseNo}
			</div>

			<form
				className='verse__editor'
				onSubmit={ handleSubmit(onSubmit) }
			>
				<div className='verse__editor--container'>
					<input
						type='hidden'
						name='verseContent'
						ref={ register() }
					/>
					<div
						id='edit'
						className='d-none'
					/>
					<Editor
						editorState={ editorState }
						onChange={ setEditorState }
						handlePastedText={ onPastedText }
						handleReturn={ onEnterKey }
						ariaDescribedBy='edit'
					/>
				</div>
				<div className='verse__editor--buttons'>
					<Button
						variant='third verse'
						onClick={ () => toggleVerseEdit() }
					>
						<span className='d-none'>Close</span>
						<i className='icon-close' />
					</Button>
					<Button
						variant='primary verse'
						type='submit'
					>
						<span className='d-none'>Accept</span>
						<i className='icon-accept' />
					</Button>
				</div>
			</form>
		</div>
	);
};

VerseActive.propTypes = {
	verse: PropTypes.object,
	toggleVerseEdit: PropTypes.func,
};

export default VerseActive;