import React, {useEffect, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from 'views/elements/Button/Button';

const SearchModule = ({ searchString, setSearchString, placeholder }) => {
	const dict = useSelector((state) => state.app.dict);
	const { register, handleSubmit, setValue, formState } = useForm({ mode: 'onChange' });
	useEffect(() => {
		setValue("searchText", searchString);
	}, [ searchString, setValue ]);

	const clearField = useCallback(() => {
		setSearchString('');
	}, [ setSearchString ]);

	return (
		<form
			onSubmit={ handleSubmit((tx) => setSearchString(tx.searchText)) }
			noValidate
		>
			<label className='input-group'>			
				<input
					name='searchText'
					type='text'
					placeholder={ placeholder }
					className='form-control'
					ref={ register() }
				/>
				<div className='input-group-append'>
					{(searchString && searchString.length) > 0 && (
						<Button
							onClick={ clearField }
							className='btn-cancel'
						>
							<i className='icon-close' />
						</Button>
					)}
					<Button
						type='submit'
						disabled={ !formState.dirty }
					>
						{dict.transcriptions.search.searchBt}
					</Button>
				</div>
			</label>
			
		</form>
	);
};

SearchModule.propTypes = {
	setSearchString: PropTypes.func,
	searchString: PropTypes.string,
	placeholder: PropTypes.string,
};

export default SearchModule;