import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import CommonDropdown from 'views/elements/Dropdown/CommonDropdown';

const CommonDropdownForm = ({ options, fieldName, placeholder, isDisabled, label }) => {
	const { register, setValue, watch } = useFormContext();
	const [ selectedOption, setSelectedOption ] = useState(-1);
	const [ propPlaceholder, setPropPlaceholder ] = useState(null);

	const watchField = watch(fieldName);

	useEffect(() => {

		if(typeof watchField !== 'object'){
			const indx = options.findIndex( item => item.value === watchField);
			if (indx > -1){
				setSelectedOption(indx);
			}else{
				if (!placeholder){
					setSelectedOption(0);
				}else{
					setPropPlaceholder(placeholder);
				}
			}
		}else{
			if (watchField){
				if (Object.prototype.hasOwnProperty.call(watchField, 'value')) {
					setValue(fieldName, watchField.value);
				}else{
					setValue(fieldName, options[0].value);
				}
			}
		}
	}, [ watchField ]);

	useEffect(() => {
		if (selectedOption > -1){
			setValue(fieldName, options[selectedOption].value);
		}
	}, [ selectedOption ]);

	return (
		<>
			<CommonDropdown
				options={ options.map(item => item.label) }
				selectedOption={ selectedOption }
				setSelectedOption={ setSelectedOption }
				label={ label }
				placeholder={ propPlaceholder }
				isDisabled={ isDisabled }
			/>
			<label>
				<span className='d-none'>{label}</span>
				<input
					type='text'
					name={ fieldName }
					id={ fieldName }
					autoComplete='off'
					style={ {display: 'none'} }
					ref={ register }
				/>
			</label>
		</>
	);
};

CommonDropdownForm.propTypes = {
	options: PropTypes.array,
	fieldName: PropTypes.string,
	placeholder: PropTypes.string, 
	isDisabled: PropTypes.bool, 
	label: PropTypes.string,
};

export default CommonDropdownForm;