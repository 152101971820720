import FileSaver from "file-saver";
import api from "services/api";

import { appActions } from 'store/actions/app.actions';

import { hideModal } from 'store/actions/modal.actions';

const isLoaded = (value) => {
	return {
		type: 'VIEWER_ISLOADED',
		isLoading: !value,
		value,
	};
};

const setIsMobile = (value) => {
	return {
		type: 'VIEWER_ISMOBILE',
		value,
	};
};

const isOCRProcessing = (value) => {
	return {
		type: 'VIEWER_ISOCRPROCESSING',
		value,
	};
};

const setNoAccess = (value) => {
	return {
		type: 'VIEWER_NOACCESS',
		value,
	};
};

const receiveData = (data) => {
	return {
		type: 'VIEWER_RECEIVE_DATA',
		data,
	};
};

const receiveVerseFormatting = (data, verseId) => {
	return {
		type: 'VIEWER_RECEIVE_VERSE_FORMATTING',
		data,
		verseId,
	};
};

const receiveInfoJson = (data) => {
	return {
		type: 'VIEWER_RECEIVE_INFOJSON',
		data,
	};
};

const receiveCharMap = (data) => {
	return {
		type: 'VIEWER_RECEIVE_CHARMAP',
		data,
	};
};

const setVerseActive = (data) => {
	return {
		type: 'VIEWER_SET_VERSE_ACTIVE',
		data,
	};
};

const setVerseEdit = (data) => {
	return {
		type: 'VIEWER_SET_VERSE_EDIT',
		data,
	};
};

const setSelectionEdit = (data) => {
	return {
		type: 'VIEWER_SET_SELECTION_EDIT',
		data,
	};
};

const setStyleActive = (data) => {
	return {
		type: 'VIEWER_SET_STYLE_ACTIVE',
		data,
	};
};

const setStyleCurrent = (data) => {
	return {
		type: 'VIEWER_SET_STYLE_CURRENT',
		data,
	};
};

const setInsertCharacter = (data) => {
	return {
		type: 'VIEWER_SET_INSERT_CHARACTER',
		data,
	};
};

const setVerseContent = (verseId, verseContent) => {
	return {
		type: 'VIEWER_SET_VERSE_CONTENT',
		verseId,
		verseContent,
	};
};

const setVerseStudentContent = (verseId, verseContent) => {
	return {
		type: 'VIEWER_SET_VERSE_STUDENT_CONTENT',
		verseId,
		verseContent,
	};
};

const setVerseStudentStatus = (verseId, verseStudentStatus) => {
	return {
		type: 'VIEWER_SET_VERSE_STUDENT_STATUS',
		verseId,
		verseStudentStatus,
	};
};

const setVerseImage = (verseId) => {
	return {
		type: 'VIEWER_SET_VERSE_IMAGE',
		verseId,
	};
};

const setVerseStatus = (verseId, verseStatus) => {
	return {
		type: 'VIEWER_SET_VERSE_STATUS',
		verseId,
		verseStatus,
	};
};

const updateVerseCommentsCount = (verseId, verseCommentsCount) => {
	return {
		type: 'VIEWER_UPDATE_VERSE_COMMENTS_COUNT',
		verseId,
		verseCommentsCount,
	};
};

const setCorrectTranscription = () => {
	return {
		type: 'VIEWER_SET_CORRECT_TRANSCRIPTION',
	};
};

const setEmptyTranscription = () => {
	return {
		type: 'VIEWER_SET_EMPTY_TRANSCRIPTION',
	};
};

const clearTranscriptionData = () => {
	return {
		type: 'CLEAR_TRANSCRIPTION_DATA',
	};
};

const loadData = (transcriptionId, pageId) => {
	return dispatch => {
		dispatch(isLoaded(false));
		dispatch(setNoAccess(false));
		api.get(`/transcriptions/${transcriptionId}/pages/${pageId}`)
			.then((response) => {
				dispatch(receiveData(response.data));
				dispatch(loadInfoJson(pageId));
			}).catch((error) => {
				if (error.status === 403) {
					dispatch(setNoAccess(true));
				}
			});
	};
};

const loadInfoJson = pageId => {
	return dispatch => {
		api.get(`/iiif/pages/${pageId}/info.json`).then((response) => {
			dispatch(receiveInfoJson(response.data));
			dispatch(isLoaded(true));
		});
	};
};

const loadCharMap = () => {
	return dispatch => {
		api.get('/info/symbols').then((response) => {
			dispatch(receiveCharMap(response.data));
		});
	};
};

const removeVerseFormatting = verseId => {
	return dispatch => {
		api.put(`/verses/${verseId}/formatting/clear`).then((response) => {
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(receiveVerseFormatting(response.data, verseId));
		});
	};
};
const deleteVerse = (verseId, pageId, transcriptionId) => {
	return dispatch => {
		dispatch(appActions.setLoading(true));
		api.delete(`/verses/${verseId}`).then(() => {
			api.get(`/transcriptions/${transcriptionId}/pages/${pageId}`).then((response) => {
				dispatch(appActions.setLoading(false));
				dispatch(setVerseActive(null));
				dispatch(setVerseEdit(null));
				dispatch(reloadVerses(response.data.verses));
				dispatch(hideModal());
			});
		},
		);
	};
};

const deleteVerses = (versesIds, pageId, transcriptionId) => {
	return dispatch => {
		dispatch(appActions.setLoading(true));
		api.delete(`/verses?versesIds=${versesIds}`).then(() => {
			api.get(`/transcriptions/${transcriptionId}/pages/${pageId}`).then((response) => {
				dispatch(appActions.setLoading(false));
				dispatch(setVerseActive(null));
				dispatch(setVerseEdit(null));
				dispatch(reloadVerses(response.data.verses));
				dispatch(hideModal());
			});
		},
		);
	};
};

const submitVerseFormatting = (verseId, typeFormatting) => {
	return dispatch => {
		api.put(`/verses/${verseId}/${typeFormatting}`, '').then((response) => {
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			switch (typeFormatting) {
				case 'modify-content':
					dispatch(setVerseContent(verseId, ''));
					break;
				case 'image':
					dispatch(setVerseContent(verseId, ''));
					dispatch(setVerseImage(verseId));
					break;
				case 'move-up':
					dispatch(reloadVerses(response.data));
					break;
				case 'move-down':
					dispatch(reloadVerses(response.data));
					break;
				default:
					dispatch(receiveVerseFormatting(response.data, verseId));
			}
		});
	};
};

const submitVerseContent = (verseId, verseContent) => {
	return dispatch => {
		api.put(`/verses/${verseId}/modify-content`, verseContent.replace(/\n/g, '')).then(() => {
			// dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(setVerseContent(verseId, verseContent.replace(/\n/g, '')));
		});
	};
};

const submitVerseStudentContent = (verseId, verseContent) => {
	return dispatch => {
		dispatch(setVerseStudentStatus(verseId, 'none'));
		api.put(`/verses/${verseId}/student-content`, verseContent.replace(/\n/g, '')).then(({data}) => {
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(setVerseStudentContent(verseId, verseContent.replace(/\n/g, '')));
			dispatch(setVerseStudentStatus(verseId, data));
		});
	};
};

const submitCorrectTranscription = (pageId) => {
	return dispatch => {
		api.put(`/verses/${pageId}/change-all-status`).then(() => {
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(setCorrectTranscription());
		});
	};
};

const submitEmptyTranscription = (pageId) => {
	return dispatch => {
		api.put(`/pages/${pageId}/empty`).then(() => {
			dispatch(setEmptyTranscription());
		});
	};
};

const submitAutoTranscribeVerse = (verseId, lang) => {
	return dispatch => {
		dispatch(appActions.setLoading(true));
		api.put(`/verses/${verseId}/content-recognition?lang=${lang}`).then((response) => {
			dispatch(appActions.setLoading(false));
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(setVerseContent(verseId, response.data));
			dispatch(hideModal());
		}).catch(() => {
			dispatch(hideModal());
		});
	};
};

const submitAutoTranscribePage = (transcriptionId, pageId, lang, ocrMode) => {
	return dispatch => {
		dispatch(appActions.setLoading(true));
		api.put(`/transcriptions/${transcriptionId}/pages/${pageId}/recognize?lang=${lang}&ocrMode=${ocrMode}`).then((response) => {
			dispatch(appActions.setLoading(false));
			dispatch(setVerseActive(null));
			dispatch(setVerseEdit(null));
			dispatch(reloadVerses(response.data));
			dispatch(hideModal());
		}).catch(() => {
			dispatch(hideModal());
		});
	};
};

const getFileByFormat = (pageId, format) => {
	return () => {
		api.get(`/pages/${pageId}/content?type=${format}`, { responseType: 'blob' })
			.then((response) => {
				let fileName = response.headers["content-disposition"].split("filename=")[1];
				FileSaver.saveAs(response.data, fileName);
			},
			);
	};
};

const reloadVerses = (verses) => {
	return {
		type: 'VIEWER_RELOAD_VERSES',
		verses,
	};
};

const submitVerseStatus = (verseId, verseStatus) => {
	return dispatch => {
		dispatch(setVerseStatus(verseId, verseStatus));
		api.put(`/verses/${verseId}/status`, verseStatus);
	};
};

export const viewerActions = {
	loadData,
	loadCharMap,
	removeVerseFormatting,
	submitVerseFormatting,
	deleteVerse,
	deleteVerses,
	submitVerseContent,
	submitVerseStudentContent,
	submitVerseStatus,
	submitCorrectTranscription,
	submitEmptyTranscription,
	submitAutoTranscribeVerse,
	submitAutoTranscribePage,
	setVerseActive,
	setVerseEdit,
	setSelectionEdit,
	setStyleActive,
	setStyleCurrent,
	setInsertCharacter,
	reloadVerses,
	isLoaded,
	setIsMobile,
	isOCRProcessing,
	getFileByFormat,
	clearTranscriptionData,	
	updateVerseCommentsCount,
};
