import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { appActions } from 'store/actions/app.actions';
import Button from "views/elements/Button/Button";

import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import url from "router/urls";

import styles from "./Cookies.module.sass";

const Cookies = () => {
    
	const dict = useSelector((state) => state.app.dict);

	const dispatch = useDispatch();

	const setCookiePermission = () => {
		dispatch(appActions.setCookiePermission());
	};
    
	return (
		<div className={ styles["cookies"] }>
			<Container>
				<Row>
					<div className={ styles["cookies__wrapper"] }>
						<div className={ styles["cookies__left"] }>
							<span className={ styles["cookies__left-text"] }>
								<i className='icon-cookies' />
								{dict.cookies.text}
							</span>
							<Link
								className={ styles["cookies__left-link"] }
								to={ url.privacyPolicy }
							>
								{dict.cookies.link}
							</Link>
						</div>
						<Button 
							className={ styles["cookies__btn"] }
							onClick={ () => setCookiePermission() }
						>
							{dict.cookies.agree}
						</Button>
					</div>
				</Row>
			</Container>
		</div>
	);
};

export default Cookies;