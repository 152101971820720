import React, { useEffect, memo, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from "prop-types";

import { viewerActions } from 'store/actions/viewer.actions';

import VerseInactive from './VerseInactive';
import VerseActive from './VerseActive';
import VerseInfo from './VerseInfo';
import VerseComment from './VerseComment';
import VerseStatus from './VerseStatus';

const Verse = ({ verse }) => {
	const dispatch = useDispatch();

	const verseEdit = useSelector((state) => state.viewer.editor.verseEdit);
	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const mobilePanels = useSelector((state) => state.app.viewer.mobilePanels);

	const removeVerseFormatting = useCallback((id) => {
		dispatch(viewerActions.removeVerseFormatting(id));
	}, [ dispatch ]);

	const toggleVerseActive = useCallback((value) => {
		dispatch(viewerActions.setVerseActive(value ? value : null));
	}, [ dispatch ]);

	const toggleVerseEdit = useCallback((value) => {
		dispatch(viewerActions.setVerseActive(value ? value : null));
		dispatch(viewerActions.setVerseEdit(value ? value : null));
	}, [ dispatch ]);

	useEffect( () => {
		if (verse.active){
			dispatch(viewerActions.setVerseActive(verse.id));
		}
	}, [ verse, dispatch ]);

	return (
		<li className={ `viewer__verses--item verse ${viewMode === 'student' ? `verse__student-status--${verse.learningStatus}` : ``}` }>
			{ (verseEdit === verse.id && viewMode !== 'read-only') ? (
				<VerseActive 
					verse={ verse }
					toggleVerseEdit={ toggleVerseEdit }
					toggleVerseActive={ toggleVerseActive }
				/>
			) : (
				<VerseInactive 
					verse={ verse } 
					toggleVerseEdit={ toggleVerseEdit }
					toggleVerseActive={ toggleVerseActive }
				/>
			)}

			<VerseInfo 
				verse={ verse } 
				removeVerseFormatting={ removeVerseFormatting }
			/>

			{ (viewMode !== 'student' && mobilePanels !== 'student') &&
			<>
				<VerseComment
					verse={ verse } 
				/>

				<VerseStatus
					verse={ verse } 
				/>
			</>}
		</li>
			
	);
};

Verse.propTypes = {
	verse: PropTypes.object,
};

export default memo(Verse);