import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import PropTypes from "prop-types";

import { useShowModal } from 'views/elements/Modal/ModalHooks';
import { viewerActions } from 'store/actions/viewer.actions';

import Button from "views/elements/Button/Button";

const FormattingButton = ({ icon, func, tooltip }) => {
	const dispatch = useDispatch();
	const verses = useSelector((state) => state.viewer.data.verses);
	const verseActive = useSelector((state) => state.viewer.editor.verseActive);
	const verseEdit = useSelector((state) => state.viewer.editor.verseEdit);
	const verse = verses.find(x => x.id === verseActive);
	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const dict = useSelector((state) => state.app.dict);
	const { transcriptionId, pageId } = useParams();

	const modalAutoTranscribeVerse = useShowModal({
		title: dict.modals.autoTranscribeVerse.title,
		type: 'viewer',
		name: 'autoTranscribeVerse',
		componentPath: './parts/ModalAutoTranscribeVerse',
		componentProps: { transcriptionId: parseInt(transcriptionId), pageId: parseInt(pageId) },
	});

	const modalCommentsVerse = useShowModal({
		title: dict.modals.commentsVerse.titleAdd,
		type: 'viewer modal-comments',
		name: 'commentsVerse',
		componentPath: './parts/ModalCommentsVerse',
		componentProps: { verseId: parseInt(verseActive), addBox: true },
		withClose: true,
	});
    
	return (
		<div
			data-tip={ tooltip }
			data-place='right'
		>
			<Button
				variant='viewer-button'
				className='viewer__button--menu'
				onClick={ () => {
					switch (func) {
						case 'verse-comment':
							modalCommentsVerse();
							break;
						case 'recognize-content':
							modalAutoTranscribeVerse();
							break;
						default:
							dispatch(viewerActions.submitVerseFormatting(verseActive, func));
					}
				} }
				disabled={ (!verseActive || verseEdit) || (viewMode === 'transcribe' && verse && verse.status === 'verified') }
			>
				<span className='d-none'>{icon}</span>
				<i className={ `icon-${icon}` } />
			</Button>
		</div>
	);
};

FormattingButton.propTypes = {
	tooltip: PropTypes.string,
	func: PropTypes.string,
	icon: PropTypes.string,
};

export default memo(FormattingButton);